import React, { useEffect, useState } from 'react';
import { UserNotificationWithEmail } from '../../api-client/autogenerated';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { formatEmailDate, notificationToUserFriendlySubjectString } from './utils';
import { EmailLogType } from './CorrespondenceLog';
import { Dictionary } from 'lodash';
import { getTemplateIds, getTemplates } from '../../models/api/templates';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      listStyle: 'none',
      position: 'relative',
      borderBottom: 'solid 2px lightgray',
      padding: '8px 16px 8px 20px',
      '&:hover': {
        background: 'lightgray',
      },
      '& *': {
        userSelect: 'none',
      },
      cursor: 'pointer',
      borderRadius: 4,
    },
    from: {
      fontSize: '18px',
      lineHeight: '21px',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '95%',
    },
    to: {
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    subject: {
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    date: {
      whiteSpace: 'nowrap',
    },
    unread: {
      position: 'absolute',
      left: 2,
      top: 3,
      height: '90%',
      width: 5,
      background: '#0947B9',
      borderRadius: 4,
    },
    attachment: {
      position: 'absolute',
      rotate: '90deg',
      right: 10,
    },
  }),
);

type Props = {
  notificationWithEmail: UserNotificationWithEmail;
  onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
  isSelected?: boolean;
};

export default function NotificationEmailItem(props: Props) {
  const classes = useStyles();
  const [templateIds, setTemplateIds] = useState<Dictionary<string>>({});
  const {
    notificationWithEmail: { email, ...userNotification },
    onClick,
    isSelected,
  } = props;

  useEffect(() => {
    getTemplateIds().then((result) => setTemplateIds(result));
  }, []);

  const date = userNotification.createdOn;
  const subject = email.Message.Subject.Data;
  const format = notificationToUserFriendlySubjectString(
    userNotification.notification,
    templateIds,
  );
  return (
    <li
      onClick={onClick}
      className={classes.root}
      style={isSelected ? { background: 'lightgray' } : {}}
    >
      {!userNotification.isRead ? <div className={classes.unread} /> : null}
      <Typography className={classes.subject}>{subject}</Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Typography>{format}</Typography>
        <Typography className={classes.date}>
          {formatEmailDate(EmailLogType.MY_NOTIFICATIONS, date)}
        </Typography>
      </div>
    </li>
  );
}
