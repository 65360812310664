import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import backdrop from '../../assets/images/action_center.jpg';
import { getUserState } from '../../features/user/selectors';
import {
  getDefaultArchitect,
  getDefaultContractor,
  getDisableProcoreIntegrationFeatures,
} from '../../features/project/selectors';
import { getDocumentState } from '../../features/document/selectors';
import StepStatus from '../submittal-action-center/StepStatus';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import {
  createMuiTheme,
  Dialog,
  Hidden,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TaskStep from '../submittal-action-center/TaskStep';
import {
  ActionTakenType,
  DocumentTemplateType,
  GetUserDocumentPermissionResponse,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import { formatDate, generateUniqueId } from '../../scripts/utils';
import Button from '@material-ui/core/Button';
import { RemoveCircleOutlined, RotateLeft } from '@material-ui/icons';
import { getDocPermission } from '../../scripts/store-utils';
import { undoCompleteReviewByDocumentId } from '../../models/api/documents';
import { updateDocument } from '../../features/document/actions';
import { getDocumentsType } from '../../features/documents/selectors';
import MobileTaskStep from '../submittal-action-center/MobileTaskStep';
import { TooltipIfDisabledComponent } from '../custom-components/CustomButtons';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      overflow: 'visible',
      backgroundImage: `url(${backdrop})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      zIndex: -100,
    },
    backgroundOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.7,
      background:
        'radial-gradient(494.01% 4071.48% at 28.5% -149.12%, #0947B9 0%, rgba(204, 222, 255, 0) 100%)',
      mixBlendMode: 'normal',
      filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1))',
      zIndex: -90,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      width: 'auto',
      paddingLeft: '7px',
      paddingBottom: '0px',
    },
    accordionContainer: {
      display: 'flex',
    },
    accordion: {
      display: 'flex',
      maxWidth: 80,
      margin: 0,
      transition: 'max-width 440ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    },
    accordionExpanded: {
      maxWidth: 600,
      margin: 0,
    },
    accordionSummary: {
      width: '80px',
      height: '100%',
      backgroundColor: '#2BB073',
      color: 'white',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    accordionSummaryContent: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    textDirection: {
      writingMode: 'vertical-rl',
      textOrientation: 'mixed',
      transform: 'rotate(-180deg)',
      fontWeight: 500,
      fontSize: '15px',
    },
  }),
);

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1500,
      xl: 1700,
    },
  },
});

type Props = {
  documentTitle?: string;
  docPermissions: GetUserDocumentPermissionResponse | undefined;
  isRfi?: boolean;
  isWcpr?: boolean;
};

const labels = ['Submission', 'Review', 'Complete'];

export default function SimpleActionCenter(props: Props) {
  const classes = useStyles();
  const { documentTitle, docPermissions, isRfi = false, isWcpr = false } = props;

  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const architect = useSelector(getDefaultArchitect);
  const generalContractor = useSelector(getDefaultContractor);
  const document = useSelector(getDocumentState)!;
  const docType = useSelector(getDocumentsType);
  const isProcoreEnabled = useSelector(getDisableProcoreIntegrationFeatures);

  const isOver760px = useMediaQuery('(min-width:760px)', { noSsr: true });
  const [dialogOpen, setDialogOpen] = useState(false);

  const [activeStep, setActiveStep] = useState(-1);
  const [stepStatuses, setStepStatuses] = useState<StepStatus[]>([
    StepStatus.COMPLETED,
    StepStatus.ACTIVE,
    StepStatus.DISABLED,
  ]);

  const isDisabled =
    docType === DocumentTemplateType.DesignPackages &&
    document.linkedChildDocuments?.some(
      (doc) => doc.workflowStatus !== WorkflowStatusType.Resolved,
    );

  const handleClose = () => {
    setActiveStep(-1);
  };

  function getMobileContent() {
    return stepStatuses.findIndex((item) => item !== StepStatus.COMPLETED);
  }

  const getOnClick = (index: number) => {
    if (isDisabled) return undefined;
    if (document.actionTaken === ActionTakenType.Withdrawn) {
      return () => {};
    }
    switch (index) {
      default:
      case 0:
        return undefined;

      case 1:
        if (
          (document.workflowStatus === WorkflowStatusType.UnderReview ||
            document.workflowStatus === WorkflowStatusType.ArchitectUploaded) &&
          (document.assignedToUserId === user.id || docPermissions?.isAssignableToDocument)
        )
          return () => setActiveStep(index);
        else return undefined;

      case 2:
        if (isProcoreEnabled && docType === DocumentTemplateType.RequestsForInformation) {
          return undefined;
        } else if (
          document.workflowStatus === WorkflowStatusType.ReviewComplete &&
          (document.assignedToUserId === user.id || docPermissions?.isAssignableToDocument)
        )
          return () => setActiveStep(index);
        else return undefined;
    }
  };

  const getDate = (index: number) => {
    switch (index) {
      case 0:
        return document.createdOn!;

      case 1:
        if (stepStatuses[1] !== StepStatus.COMPLETED) return document.dueDate;
        return document.dateReturnedToContractor ?? document.dateFinalExecuted;

      default:
      case 2:
        if (stepStatuses[2] !== StepStatus.COMPLETED) return document.dueDate;
        return document.dateReturnedToContractor || document.dateFinalExecuted;
    }
  };

  const getIsComplete = () => {
    return stepStatuses[0] === StepStatus.COMPLETED && stepStatuses[1] === StepStatus.COMPLETED;
  };

  const getIsResolved = () => {
    return (
      stepStatuses[0] === StepStatus.COMPLETED &&
      stepStatuses[1] === StepStatus.COMPLETED &&
      stepStatuses[2] === StepStatus.COMPLETED
    );
  };

  useEffect(() => {
    if (isDisabled) {
      setActiveStep(-1);
      return;
    }
    switch (document.workflowStatus) {
      case WorkflowStatusType.ArchitectUploaded:
      case WorkflowStatusType.UnderReview:
        if (document.assignedToUserId === user.id || docPermissions?.isAssignableToDocument) {
          setActiveStep(1);
          setStepStatuses([StepStatus.COMPLETED, StepStatus.ACTIVE, StepStatus.DISABLED]);
        } else setActiveStep(-1);
        break;

      case WorkflowStatusType.Completed:
      case WorkflowStatusType.ReviewComplete:
        if (isProcoreEnabled && docType === DocumentTemplateType.RequestsForInformation) {
          setStepStatuses([StepStatus.COMPLETED, StepStatus.COMPLETED, StepStatus.COMPLETED]);
          setActiveStep(-1);
        } else if (docType === DocumentTemplateType.PayApplications) {
          if (document.isFinalExecuted) {
            setStepStatuses([StepStatus.COMPLETED, StepStatus.COMPLETED, StepStatus.COMPLETED]);
          } else {
            setStepStatuses([StepStatus.COMPLETED, StepStatus.COMPLETED, StepStatus.ACTIVE]);
            if (document.assignedToUserId === user.id || docPermissions?.isAssignableToDocument)
              setActiveStep(2);
          }
        } else if (isRfi) {
          setStepStatuses([StepStatus.COMPLETED, StepStatus.COMPLETED, StepStatus.ACTIVE]);
          if (document.assignedToUserId === user.id || docPermissions?.isAssignableToDocument)
            setActiveStep(2);
        } else if (isWcpr) {
          setStepStatuses([StepStatus.COMPLETED, StepStatus.COMPLETED, StepStatus.COMPLETED]);
          setActiveStep(-1);
        }
        break;

      case WorkflowStatusType.Resolved:
      case WorkflowStatusType.Resubmitted:
        if (isRfi) {
          setStepStatuses([StepStatus.COMPLETED, StepStatus.COMPLETED, StepStatus.COMPLETED]);
          setActiveStep(-1);
        }
        break;

      default:
        break;
    }
  }, [document.workflowStatus, docPermissions]);

  const getTitle = () => {
    if (docType === DocumentTemplateType.PotentialChangeOrders) return 'Potential Change Order';
    if (docType === DocumentTemplateType.WorkChangeProposalRequests)
      return 'Work Change Proposal Request';
    if (docType === DocumentTemplateType.RequestsForChange) return 'Request for Change';
    if (docType === DocumentTemplateType.PayApplications) return 'Pay Application';
    if (docType === DocumentTemplateType.Task) return 'Task & Share Management';
    if (docType === DocumentTemplateType.DesignPackages) return 'Design Package Review';
    return 'Request for Information';
  };

  const getShortTitle = () => {
    if (docType === DocumentTemplateType.PotentialChangeOrders) return 'PCO';
    if (docType === DocumentTemplateType.WorkChangeProposalRequests) return 'WCPR';
    if (docType === DocumentTemplateType.RequestsForChange) return 'RFC';
    if (docType === DocumentTemplateType.PayApplications) return 'Pay App';
    if (docType === DocumentTemplateType.Task) return 'Task & Share';
    if (docType === DocumentTemplateType.DesignPackages) return 'Design Package';
    return 'RFI';
  };

  const hasRollbackPermission = () => {
    if (
      document.isFinalExecuted ||
      (document.workflowStatus &&
        ![
          WorkflowStatusType.Completed,
          WorkflowStatusType.ReviewComplete,
          WorkflowStatusType.Resolved,
        ].includes(document.workflowStatus))
    )
      return false;
    const permission = getDocPermission();
    return permission && permission >= 3;
  };

  const handleRollback = async () => {
    const proceed = window.confirm('Are you sure you want to revise this document?');
    if (proceed) {
      const doc = await undoCompleteReviewByDocumentId(document.id);
      dispatch(updateDocument(doc));
      window.location.reload();
    }
  };

  const getContractorAcceptance = () => {
    switch (document.workflowStatus) {
      case WorkflowStatusType.Accepted:
        return 'Contractor Acceptance - Accepted';
      case WorkflowStatusType.Resolved:
        return 'Contractor Acceptance - Resolved';
      case WorkflowStatusType.Resubmitted:
        return 'Contractor Acceptance - Resubmitted';
      default:
        return 'Awaiting Contractor Acceptance';
    }
  };

  const getIsOpen = (index: number) => {
    if (document.actionTaken === ActionTakenType.Withdrawn) {
      return false;
    }

    return index === activeStep;
  };

  const showContractorAcceptanceDate = () => {
    if (
      document.actionTaken === ActionTakenType.Withdrawn ||
      (docType === DocumentTemplateType.RequestsForInformation && isProcoreEnabled)
    ) {
      return false;
    }

    return isRfi;
  };

  const getCompletedText = () => {
    if (document.actionTaken === ActionTakenType.Withdrawn) {
      return 'WITHDRAWN';
    }

    return isRfi ? 'REVIEW COMPLETED' : 'COMPLETED';
  };

  return (
    <Card className={classes.root}>
      <div className={classes.backgroundOverlay} />
      {isOver760px ? (
        <Grid container alignItems="stretch" wrap="nowrap" style={{ minHeight: 284, padding: 16 }}>
          <ThemeProvider theme={theme}>
            <Hidden mdDown>
              <Grid
                item
                style={{
                  maxWidth: 590,
                  paddingRight: 48,
                  paddingTop: 16,
                  paddingLeft: 16,
                  paddingBottom: 16,
                }}
              >
                <Typography
                  variant="h2"
                  style={{
                    color: 'white',
                    fontSize: 40,
                    fontWeight: 900,
                    paddingBottom: 8,
                    paddingTop: 16,
                  }}
                >
                  {`${getTitle()} Action Center`}
                </Typography>
                {getIsComplete() ? (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 60,
                          height: 60,
                          backgroundColor: '#ff9c17',
                          borderRadius: '50%',
                          marginRight: 15,
                        }}
                      >
                        {document.actionTaken === ActionTakenType.Withdrawn ? (
                          <RemoveCircleOutlined style={{ color: 'white', fontSize: 60 }} />
                        ) : (
                          <CheckCircleOutlineIcon style={{ color: 'white', fontSize: 60 }} />
                        )}
                      </div>
                      <div>
                        <Typography
                          variant="body2"
                          style={{
                            color: 'white',
                            fontSize: 21,
                            fontWeight: 'bold',
                            textTransform: 'none',
                            lineHeight: '24px',
                          }}
                        >
                          {getCompletedText()}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{
                            color: 'white',
                            fontSize: 18,
                            textTransform: 'none',
                            lineHeight: '24px',
                          }}
                        >
                          {formatDate(
                            document.dateReturnedToContractor || document.dateFinalExecuted,
                            true,
                          )}
                        </Typography>
                      </div>
                    </div>
                    {showContractorAcceptanceDate() ? (
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                        <div style={{ marginLeft: 75 }}>
                          <Typography
                            variant="body2"
                            style={{
                              color: 'white',
                              fontSize: 21,
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              lineHeight: '24px',
                            }}
                          >
                            {getContractorAcceptance()}
                          </Typography>
                          {document.dateContractorCompleted ? (
                            <Typography
                              variant="body2"
                              style={{
                                color: 'white',
                                fontSize: 18,
                                textTransform: 'none',
                                lineHeight: '24px',
                              }}
                            >
                              {formatDate(document.dateContractorCompleted, true)}
                            </Typography>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <Typography
                    variant="body2"
                    style={{
                      color: 'white',
                      fontSize: 19,
                      textTransform: 'none',
                      lineHeight: '24px',
                      maxWidth: 410,
                    }}
                  >
                    Complete all your uploads and actions here. Track each step of the process and
                    keep an eye on progress.
                  </Typography>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent:
                      stepStatuses[1] === StepStatus.COMPLETED ? 'space-between' : 'flex-end',
                    paddingTop: 16,
                  }}
                >
                  {hasRollbackPermission() && (
                    <TooltipIfDisabledComponent
                      title="This is disabled for projects synced with Procore"
                      disabled={isProcoreEnabled}
                    >
                      <Button
                        variant="contained"
                        startIcon={<RotateLeft />}
                        disabled={isProcoreEnabled}
                        onClick={handleRollback}
                        style={{ backgroundColor: '#D02C14', color: 'white' }}
                      >
                        Revise Submission
                      </Button>
                    </TooltipIfDisabledComponent>
                  )}
                  <ArrowForwardIcon style={{ color: 'white', width: 48, height: 48 }} />
                </div>
              </Grid>
            </Hidden>
          </ThemeProvider>

          <Grid
            item
            className={classes.accordionContainer}
            style={{ minHeight: '100%', padding: 16 }}
          >
            {labels!.map((label, index) => {
              return (
                <TaskStep
                  key={generateUniqueId()}
                  label={label}
                  status={stepStatuses[index]}
                  onClick={getOnClick(index)}
                  isOpen={getIsOpen(index)}
                  index={index}
                  defaultContractor={generalContractor || undefined}
                  defaultArchitect={architect || undefined}
                  handleClose={handleClose}
                  date={getDate(index) || 'N/A'}
                  isRfi={isRfi}
                  isWcpr={isWcpr}
                  documentTitle={documentTitle}
                />
              );
            })}
          </Grid>
          <Grid item>
            <ThemeProvider theme={theme}>
              <Hidden lgUp>
                {hasRollbackPermission() && (
                  <TooltipIfDisabledComponent
                    title="This is disabled for projects synced with Procore"
                    disabled={isProcoreEnabled}
                  >
                    <Button
                      variant="contained"
                      startIcon={<RotateLeft />}
                      disabled={isProcoreEnabled}
                      onClick={handleRollback}
                      style={{ backgroundColor: '#D02C14', color: 'white', marginTop: '50%' }}
                    >
                      Revise Submission
                    </Button>
                  </TooltipIfDisabledComponent>
                )}
              </Hidden>
            </ThemeProvider>
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="center" justify="center">
          <Button
            variant="contained"
            color="primary"
            disabled={getIsResolved()}
            style={{ width: '85%', margin: '20px 0px' }}
            onClick={() => setDialogOpen(true)}
          >
            Open {getShortTitle()} Action Center
          </Button>
          {hasRollbackPermission() ? (
            <TooltipIfDisabledComponent
              title="This is disabled for projects synced with Procore"
              disabled={isProcoreEnabled}
            >
              <Button
                variant="contained"
                startIcon={<RotateLeft />}
                disabled={isProcoreEnabled}
                onClick={handleRollback}
                style={{
                  backgroundColor: '#D02C14',
                  color: 'white',
                  marginBottom: 20,
                  width: '85%',
                }}
              >
                Revise Submission
              </Button>
            </TooltipIfDisabledComponent>
          ) : null}
          <Dialog maxWidth="xs" fullWidth open={dialogOpen}>
            <MobileTaskStep
              key={generateUniqueId()}
              label={'Hello'}
              status={stepStatuses[getMobileContent()]}
              onClick={getOnClick(getMobileContent())}
              isOpen={getMobileContent() === activeStep}
              index={getMobileContent()}
              defaultContractor={generalContractor || undefined}
              defaultArchitect={architect || undefined}
              handleClose={() => setDialogOpen(false)}
              date={getDate(getMobileContent()) || 'N/A'}
              isRfi={isRfi}
              isWcpr={isWcpr}
              documentTitle={documentTitle}
              currentIndex={getMobileContent()}
            />
          </Dialog>
        </Grid>
      )}
    </Card>
  );
}
