import React, { useState } from 'react';
import {
  Card,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { getPageTitle, isPublicPage } from '../../scripts/utils';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Info, Remove } from '@material-ui/icons';
import CommentTextfield from './CommentTextfield';
import CommentSection from './CommentSection';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import { useSelector } from 'react-redux';
import { getDocumentState } from '../../features/document/selectors';
import { getDocumentsType } from '../../features/documents/selectors';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { CustomComment } from './Comment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      // zIndex: -1,
    },
    content: {
      display: 'flex',
      // flexDirection: 'column',
      flexBasis: 'auto',
      width: 'auto',
    },
    margin: {
      margin: '8px 8px 24px 8px',
      height: '36px',
    },
    infoIconVisibility: {
      [theme.breakpoints.down('lg')]: {
        visibility: 'hidden',
      },
      [theme.breakpoints.up('xl')]: {
        visibility: 'visible',
      },
    },
    leftMargin: {
      [theme.breakpoints.down('md')]: {
        marginLeft: 16,
        marginRight: 8,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 16,
      },
    },
  }),
);

type Props = {
  handleSubmit: (comment: string) => Promise<void>;
  comments?: CustomComment[];
  overrideTitle?: string;
  isPunchListPage?: boolean;
  disableAddComment?: boolean;
};

export default function CommentsCard(props: Props) {
  const classes = useStyles();
  const { handleSubmit, comments, overrideTitle, isPunchListPage, disableAddComment } = props;

  const document = useSelector(getDocumentState);
  const docType = useSelector(getDocumentsType);
  const title = overrideTitle || getPageTitle(docType!, document);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [collapseOpen, setCollapseOpen] = useState(true);
  const [showTooltip, setShowTooltip] = useState(!window.localStorage.commentTooltipIsNotActive);
  const [showMore, setShowMore] = useState(false);

  const getContent = () => (
    <Grid container spacing={4} style={{ flexWrap: 'wrap-reverse' }}>
      {!disableAddComment && !isPublicPage() && !document?.isHidden ? (
        <Grid item xs={12} md={6}>
          <CommentTextfield onSubmit={handleSubmit} />
        </Grid>
      ) : null}

      {/* we should show 2 comments at most, but when use presses "show more" we show all the comments */}
      <Grid
        item
        xs={12}
        md={!isPublicPage() ? 5 : 12}
        className={classes.leftMargin}
        style={{
          display: 'flex',
          alignItems: isPublicPage() && !isSmall ? 'flex-start' : 'flex-end',
          flexDirection: 'column',
        }}
      >
        {showMore ? (
          <CommentSection
            maxHeight={1500}
            comments={comments?.sort((a, b) => descendingComparator(a, b, 'createdOn'))}
          />
        ) : (
          <CommentSection
            maxHeight={1500}
            comments={comments?.sort((a, b) => descendingComparator(a, b, 'createdOn')).slice(0, 2)}
            commentsCountOverride={comments?.length}
          />
        )}
        {comments?.length && comments?.length > 2 ? (
          <Button
            color="primary"
            size="medium"
            disableElevation
            onClick={() => setShowMore(!showMore)}
            className={classes.margin}
          >
            {showMore ? 'Show less' : 'Show more'}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );

  if (isPunchListPage) return getContent();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <h2>
          {' '}
          {title
            ? `${title} Comments`
            : `${document?.referenceNumber}${
                document?.revisionNumber ? `-R${document?.revisionNumber}` : ''
              } Comments`}
        </h2>

        <Tooltip
          className={classes.infoIconVisibility}
          disableHoverListener={showTooltip}
          arrow
          placement="right"
          title="Visible to everyone on this project"
          PopperProps={{ style: { zIndex: 1 } }}
        >
          {showTooltip ? (
            <IconButton
              className={classes.infoIconVisibility}
              style={{
                padding: 0,
                margin: 0,
                height: '40px',
                width: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <VisibilityOffIcon
                className={classes.infoIconVisibility}
                onClick={() => {
                  setShowTooltip(false);
                  window.localStorage.commentTooltipIsNotActive = true;
                }}
              />
            </IconButton>
          ) : (
            <Info
              className={classes.infoIconVisibility}
              style={{ marginLeft: 8, marginTop: 9 }}
              onClick={() => {
                setShowTooltip(false);
                window.localStorage.commentTooltipIsNotActive = true;
              }}
            />
          )}
        </Tooltip>
        <div style={{ display: 'inline-flex', flexGrow: 100 }} />
        {/* collapse icon */}
        <IconButton style={{ padding: 0 }}>
          {collapseOpen ? (
            <Remove htmlColor="#0947B9" onClick={() => setCollapseOpen(false)} />
          ) : (
            <Add htmlColor="#0947B9" onClick={() => setCollapseOpen(true)} />
          )}
        </IconButton>
      </CardContent>
      <Collapse
        in={collapseOpen}
        style={{ paddingLeft: '0px', paddingRight: '8px', paddingBottom: '16px' }}
      >
        {getContent()}
      </Collapse>
    </Card>
  );
}
