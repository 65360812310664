import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Hidden, Toolbar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import BreadCrumbs from './Breadcrumbs';
import UserDropdown from './UserDropdown';
import NavIconbuttons from './NavIconbuttons';
import { isLoggedIn } from '../../scripts/store-utils';

interface NavAppbarProps {
  isBiddingPortal?: boolean;
  bidSetupName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        paddingTop: '25px', // make up for the height of mobile nav drawer
        paddingBottom: '8px',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'right',
        position: 'relative',
        zIndex: 100,
      },
    },
    rightMenu: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
);

export default function MenuAppBar(props: NavAppbarProps) {
  const classes = useStyles();
  const { isBiddingPortal = false, bidSetupName = '' } = props;

  return (
    <div className={classes.root}>
      {/*display on desktop resolutions between lg and xl*/}
      {/*
      !isBiddingPortal && (
        <Box
          display={{ xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'none' }}
          justifyContent="center"
        >
          <SnackbarMessage
            message="This website was designed for resolutions of 1920x1080 and higher."
            severity="warning"
          />
        </Box>
      )
      */}

      {isLoggedIn() && (
        <>
          <AppBar position="static" className={'header'}>
            <Toolbar className={classes.root}>
              <Hidden smDown>
                <div style={{ flexGrow: 1 }}>
                  <BreadCrumbs bidSetupName={bidSetupName} />
                </div>
              </Hidden>
              <Box className={classes.rightMenu}>
                <NavIconbuttons isBiddingPortal={isBiddingPortal} />
                <UserDropdown isBiddingPortal={isBiddingPortal} />
              </Box>
            </Toolbar>
          </AppBar>
        </>
      )}
    </div>
  );
}
