import { getTemplateId, getTemplates } from './templates';
import {
  AddUserParams,
  DocumentTemplateType,
  ICustomInsertionProject,
  INumberedDocumentView,
  IOptionalProject,
  IProjectUser,
  IProjectView,
  ProjectSummaryType,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import {
  ApiClient,
  getQueryFilterString,
  QueryFilter,
  QueryFilterRow,
} from '../../api-client/api-client';

const documentTypesShouldExcludeDrafts = [
  DocumentTemplateType.Submittals,
  DocumentTemplateType.AsBuilt,
  DocumentTemplateType.Drawings,
  DocumentTemplateType.Specifications,
];

// GET //

export const getProjects = async (limit = 1000) => {
  const response = await ApiClient.getAllProjects({
    pageSize: limit,
    filterByObject: getQueryFilterString([
      { whereColumn: 'isHidden', whereOperator: '=', whereValue: 'false' },
    ]),
  });
  return response.data;
};

export const getProjectsSimplified = async (limit = 1000) => {
  const response = await ApiClient.getAllProjectsSimplified({
    pageSize: limit,
    filterByObject: getQueryFilterString([
      { whereColumn: 'isHidden', whereOperator: '=', whereValue: 'false' },
    ]),
  });
  return response.data;
};

export const getProjectsSummary = async (limit = 50) => {
  const response = await ApiClient.getAllProjectSummary({ pageSize: limit });
  return response.data;
};

export const getMostRecentProjects = async (
  numMostRecentProjects = 4,
  numMostRecentComments = 2,
) => {
  const response = await ApiClient.getProjectsWithMostRecentComments({
    numMostRecentProjects,
    numMostRecentComments,
  });
  return response.data;
};

export const getFavoriteProjects = async () => {
  return ApiClient.getMyFavoriteProjects().then((res) => res.data);
};

export const getProjectById = async (projectId: string) => {
  const response = await ApiClient.getProjectById({ projectId });
  return response.data;
};

export const getProjectSummaryById = async (projectId: string): Promise<ProjectSummaryType> => {
  const response = await ApiClient.getProjectSummaryById({ projectId });
  return response.data;
};

export const getProjectBiddingSummaryById = async (projectId: string) => {
  return ApiClient.getProjectBiddingSummaryById({ projectId }).then((res) => res.data);
};

export const getBidsByProjectId = async (projectId: string, limit = 50) => {
  const response = await ApiClient.getBidsOnProjectById({ projectId, pageSize: limit });
  return response.data.results;
};

export const getDocumentsByProjectId = async (
  projectId: string,
  limit = 10000,
): Promise<INumberedDocumentView[]> => {
  const selectedColumns: (keyof INumberedDocumentView)[] = [
    'id',
    'documentTemplateId',
    'submittalSection',
    'submittalSectionDescription',
    'title',
    'sheetNumber',
    'referenceNumber',
    'documentNumber',
    'revisionNumber',
    'dateOfObservation',
    'description',
  ];
  const response = await ApiClient.getDocumentsByProjectId({
    projectId,
    selectedColumns: JSON.stringify(selectedColumns),
    pageSize: limit,
    filterByObject: getQueryFilterString([
      { whereColumn: 'isHidden', whereOperator: '=', whereValue: 'false' },
      { whereColumn: 'isDraft', whereOperator: '=', whereValue: 'false' },
    ]),
  });
  return response.data.results;
};

export const getDocumentsByProjectIdAndType = async (
  projectId: string,
  type: DocumentTemplateType,
  limit = 1000,
  filters?: QueryFilterRow[],
) => {
  const templates = await getTemplates();
  const excludeDraftsFilter: QueryFilterRow = {
    whereColumn: 'isDraft',
    whereOperator: '!=',
    whereValue: 'true',
  };
  const response = await ApiClient.getDocumentsByProjectIdAndDocumentTemplateId({
    projectId,
    documentTemplateId: templates[type],
    pageSize: limit,
    filterByObject: getQueryFilterString([
      { whereColumn: 'isHidden', whereOperator: '=', whereValue: 'false' },
      ...(documentTypesShouldExcludeDrafts.includes(type) ? [excludeDraftsFilter] : []),
      ...(filters ? filters : []),
    ]),
  });
  return response.data.results;
};

export const getDocumentsByProjectIdAndTypePaginated = async (
  projectId: string,
  type: DocumentTemplateType,
  pageSize: number,
  page: number,
  isSiteAdmin: boolean | null | undefined = false,
) => {
  const templates = await getTemplates();
  const filters: QueryFilter = [];
  if (!isSiteAdmin) {
    filters.push({ whereColumn: 'isHidden', whereOperator: '=', whereValue: 'false' });
  }
  if (documentTypesShouldExcludeDrafts.includes(type)) {
    filters.push({
      whereColumn: 'isDraft',
      whereOperator: '!=',
      whereValue: 'true',
    });
  }
  const response = await ApiClient.getDocumentsByProjectIdAndDocumentTemplateId({
    projectId,
    documentTemplateId: templates[type],
    pageSize,
    page,
    filterByObject: getQueryFilterString(filters),
  });
  return response.data;
};

export const getSubmittalsInPackage = async (
  projectId: string,
  packageId: string,
  docType: DocumentTemplateType,
  limit = 50,
) => {
  const filter: QueryFilter = [
    { whereColumn: 'submittalPackageDocumentId', whereOperator: '=', whereValue: packageId },
  ];
  const templateId = await getTemplateId(docType);
  const response = await ApiClient.getDocumentsByProjectIdAndDocumentTemplateId({
    projectId,
    documentTemplateId: templateId,
    pageSize: limit,
    filterByObject: getQueryFilterString(filter),
  });
  return response.data.results;
};

export const getDocumentsWithNoPackage = async (
  projectId: string,
  docType: DocumentTemplateType,
  limit = 500,
) => {
  const filter: QueryFilter = [
    { whereColumn: 'submittalPackageDocumentId', whereOperator: 'IS', whereValue: null },
    { whereColumn: 'workflowStatus', whereOperator: '=', whereValue: WorkflowStatusType.Initial },
  ];
  const templateId = await getTemplateId(docType);
  const response = await ApiClient.getDocumentsByProjectIdAndDocumentTemplateId({
    projectId,
    documentTemplateId: templateId,
    pageSize: limit,
    filterByObject: getQueryFilterString(filter),
  });
  return response.data.results;
};

export const getUsersByProjectId = async (
  projectId: string,
  limit = 50,
): Promise<IProjectUser[]> => {
  const response = await ApiClient.getProjectUsersByProjectId({ projectId, pageSize: limit });
  return response.data.results;
};

export const getProjectUserById = async (userId: string) => {
  const response = await ApiClient.getProjectUserById({ id: userId });
  return response.data;
};

export const addOrModifyUsersOfProject = async (
  projectId: string,
  newUserParams: AddUserParams[],
) => {
  const response = await ApiClient.addOrModifyUsersByProjectId({
    addUserParams: newUserParams,
    projectId,
  });
  return response.data;
};

export const removeUserFromProject = async (projectId: string, userId: string) => {
  const response = await ApiClient.removeUserFromProjectByProjectId({ projectId, userId });
  return response.data;
};

export const insertProject = async (project: ICustomInsertionProject): Promise<IProjectView> => {
  const response = await ApiClient.insertProject({ iCustomInsertionProject: project });
  return response.data;
};

export const patchProject = async (projectId: string, project: IOptionalProject) => {
  return ApiClient.editProjectById({ projectId, iOptionalProject: project }).then(
    (res) => res.data,
  );
};

export const getPrimeBiddersByProject = async (projectId: string, pageSize = 50) => {
  return ApiClient.getPrimeBiddersOnProjectById({ projectId, pageSize }).then((res) => res.data);
};

export const archiveProjectById = async (projectId: string) => {
  return ApiClient.editProjectById({ projectId, iOptionalProject: { isArchived: true } });
};

export const deleteProjectById = async (projectId: string) => {
  return ApiClient.editProjectById({ projectId, iOptionalProject: { isHidden: true } });
};

export const getLatestProjectArchiveZipFileLink = async (projectId: string) => {
  const response = await ApiClient.getLatestProjectArchiveZipFileLink({ projectId }).then(
    (res) => res.data,
  );
  return response;
};

export const sendPrivateProjectInviteEmails = async (projectId: string, emails: string[]) => {
  return ApiClient.sendPrivateProjectInviteEmails({
    projectId,
    sendPrivateInviteEmailRequestParams: { emails },
  }).then((res) => res.data);
};

export const getSuggestedProjectEmailSlug = async (projectName: string, emailSlug?: string) => {
  return ApiClient.getSuggestedProjectEmailSlug({
    getSuggestedProjectEmailSlugRequest: { projectName, emailSlug },
  }).then((res) => res.data);
};
