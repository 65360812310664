import React, { useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Menu, MenuItem, Theme, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import NotificationsForm from './NotificationsForm';
import EditDocumentsDialog from '../dialogs/EditDocumentsDialog';
import { getDocumentsType } from '../../features/documents/selectors';
import { getDocumentState } from '../../features/document/selectors';
import {
  deleteDocumentById,
  modifyDocumentById,
  restoreDocumentById,
  sendDocumentPublicLinkEmails,
  withdrawDocumentById,
} from '../../models/api/documents';
import { useHistory } from 'react-router-dom';
import {
  documentTypeToReadable,
  documentTypeToUrl,
  downloadFileById,
  getDocumentIdentifier,
  isPublicPage,
} from '../../scripts/utils';
import { getUserState } from '../../features/user/selectors';
import { getDocPermission } from '../../scripts/store-utils';
import { ActionTakenType, DocumentTemplateType } from '../../api-client/autogenerated';
import { getTemplateId } from '../../models/api/templates';
import { getDocumentPublicLink } from '../../models/api/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy';
import {
  Add,
  Backspace,
  Close,
  Delete,
  EditOutlined,
  Notifications,
  Restore,
  Share,
} from '@material-ui/icons';
import {
  getDisableProcoreIntegrationFeatures,
  getProjectState,
} from '../../features/project/selectors';
import { TooltipIfDisabledComponent } from '../custom-components/CustomButtons';
import EmailUsersDialog from '../design/EmailUsersDialog';
import DocumentPrintView from '../printing/DocumentPrintView';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { reloadDocument } from '../../features/document/actions';
import CircularLoader from '../loader/CircularLoader';
import { addSnackbar } from '../../features/snackbar/actions';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  }),
);

export default function OptionsButtons() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserState);
  const documentType = useSelector(getDocumentsType);
  const disableProcoreIntegrationFeatures = useSelector(getDisableProcoreIntegrationFeatures);
  const currentDocument = useSelector(getDocumentState);
  const project = useSelector(getProjectState);

  const [menuPosition, setMenuPosition] = useState<any>(null);
  const [getALinkDialogOpen, setGetALinkDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const printFileName = documentType
    ? `${project?.name} ${documentTypeToReadable[documentType]} ${getDocumentIdentifier(
        currentDocument,
        documentType,
      )}`.replace('—', '-')
    : null;

  const permission = getDocPermission();

  const columnButtons = useMediaQuery('(max-width:400px)');

  const isXs = useMediaQuery<Theme>((t) => t.breakpoints.only('xs'));

  const showCloseout =
    (documentType === DocumentTemplateType.Submittals ||
      documentType === DocumentTemplateType.SubmittalPackages ||
      documentType === DocumentTemplateType.CloseoutSubmittals ||
      documentType === DocumentTemplateType.CloseoutSubmittalPackages) &&
    !isPublicPage() &&
    permission &&
    permission >= 3;

  const isCloseout =
    documentType === DocumentTemplateType.CloseoutSubmittals ||
    documentType === DocumentTemplateType.CloseoutSubmittalPackages;

  const editAnchor = useRef<any>(null);

  const handleClick = (event: React.MouseEvent) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition(event.currentTarget);
  };

  const handleGetLink = async () => {
    if (!currentDocument) return '';
    setLinkLoading(true);
    try {
      const link = await getDocumentPublicLink(currentDocument.id);
      setLinkLoading(false);
      return link;
    } catch (e: any) {
      return '';
    } finally {
      setLinkLoading(false);
    }
  };

  const handleClose = () => {
    setGetALinkDialogOpen(false);
  };

  const submitEmails = async (emails: string[]) => {
    if (!currentDocument?.id) return;
    await sendDocumentPublicLinkEmails(currentDocument.id, emails);
    handleClose();
  };

  const handleItemClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const handleDelete = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!currentDocument) return;
    const shouldDelete = window.confirm('Are you sure you want to delete this document?');
    if (shouldDelete) {
      await deleteDocumentById(currentDocument.id);
      if (!user.isSiteAdmin) {
        history.push(
          `/main/projects/${currentDocument.projectId}/documents/${
            documentTypeToUrl[documentType!]
          }`,
        );
      } else {
        dispatch(reloadDocument());
      }
    }
  };

  const handleCloseout = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!currentDocument) return;
    const newDocumentType = isCloseout
      ? DocumentTemplateType.Submittals
      : DocumentTemplateType.CloseoutSubmittals;
    const documentTemplateId = await getTemplateId(newDocumentType);
    await modifyDocumentById(currentDocument.id, { patch: { documentTemplateId } });
    history.push(
      `/main/projects/${currentDocument.projectId}/documents/${documentTypeToUrl[newDocumentType]}/${currentDocument.id}`,
    );
  };

  const downloadAllFiles = async () => {
    if (!currentDocument?.files?.length) return;

    for (let i = 0; i < currentDocument.files.length; i += 1) {
      const file = currentDocument.files[i];
      await downloadFileById(file.id, `${printFileName} ${file.name}`);
    }
  };

  const handlePrint = () => {
    // @ts-ignore
    const content: Content[] = htmlToPdfmake(document.getElementById('print-this')!.innerHTML);
    // @ts-ignore
    const dd: TDocumentDefinitions = {
      info: {
        title: `${printFileName} Summary`,
      },
      // @ts-ignore
      content: content.filter((d) => !['IMG', 'BUTTON'].includes(d.nodeName)),
      footer: (page, totalPages) => page.toString() + ' of ' + totalPages,
    };

    return new Promise((resolve) =>
      // @ts-ignore
      pdfMake.createPdf(dd).download(`${printFileName} Summary`, resolve),
    );
  };

  const handleExport = async () => {
    if (!currentDocument) return;
    if (
      documentType === DocumentTemplateType.FieldReports ||
      documentType === DocumentTemplateType.WarrantyItems
    ) {
      history.push(`${currentDocument.id}/print`);
    } else {
      try {
        setIsDownloading(true);
        await handlePrint();
        await downloadAllFiles();
      } finally {
        setIsDownloading(false);
      }
    }
  };

  const canEdit = () => {
    return (
      permission &&
      permission >= 2 &&
      documentType &&
      ![DocumentTemplateType.Submittals, DocumentTemplateType.AsBuilt].includes(documentType) &&
      currentDocument?.actionTaken !== ActionTakenType.Withdrawn
    );
  };

  const canDelete = () => {
    return permission && permission >= 4;
  };

  const canWithdraw = () => {
    return (
      permission && permission >= 2 && currentDocument?.actionTaken !== ActionTakenType.Withdrawn
    );
  };

  const handleWithdraw = async () => {
    if (!currentDocument) return;

    try {
      setIsWithdrawing(true);
      await withdrawDocumentById(currentDocument.id);
      dispatch(reloadDocument());
    } finally {
      setIsWithdrawing(false);
    }
  };

  const handleRestore = async () => {
    if (!currentDocument) return;
    try {
      await restoreDocumentById(currentDocument.id);
      dispatch(reloadDocument());
    } catch {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Something went wrong while trying to restore this document',
          severity: 'error',
        }),
      );
    }
  };

  if (currentDocument?.isHidden) {
    return (
      <div style={{ position: 'relative' }} className={columnButtons ? classes.root : ''}>
        {user.isSiteAdmin ? (
          <Button onClick={handleRestore} startIcon={<Restore />}>
            Restore
          </Button>
        ) : null}
      </div>
    );
  }

  return (
    <>
      <div
        style={{ position: 'relative', right: 12 }}
        className={columnButtons ? classes.root : ''}
      >
        <Button onClick={handleClick}>
          <Notifications style={{ color: '#7A797A' }} />
          Notifications
        </Button>
        <Menu
          open={menuPosition}
          onClose={() => setMenuPosition(null)}
          anchorEl={menuPosition}
          style={{ top: 80 }}
        >
          <MenuItem onClick={handleItemClick}>
            <NotificationsForm />
          </MenuItem>
        </Menu>
        <Button onClick={() => setGetALinkDialogOpen(true)}>
          <FontAwesomeIcon
            width={32}
            height={32}
            icon={faCopy}
            style={{ width: 20, height: 20, color: '#7A797A' }}
          />
          <div style={{ width: 6 }} />
          Get A Link{' '}
          {linkLoading && (
            <>
              <div style={{ width: 8 }} />
              <CircularProgress size="20px" thickness={5} color="secondary" />
            </>
          )}
        </Button>
        {canEdit() ? (
          <Button
            ref={editAnchor}
            onClick={() =>
              documentType !== DocumentTemplateType.FieldReports ||
              !currentDocument?.dateOfObservation
                ? setEditDialogOpen(true)
                : history.push(`${currentDocument!.id}/edit`)
            }
          >
            <EditOutlined style={{ color: '#7A797A' }} />
            Edit
          </Button>
        ) : null}
        {showCloseout && (
          <TooltipIfDisabledComponent
            title={'This is disabled for projects synced with Procore'}
            disabled={disableProcoreIntegrationFeatures}
          >
            <Button onClick={handleCloseout} disabled={disableProcoreIntegrationFeatures}>
              {isCloseout ? (
                <Close style={{ color: '#7A797A' }} />
              ) : (
                <Add style={{ color: '#7A797A' }} />
              )}
              {isCloseout ? 'Remove from Closeout' : 'Add to Closeout'}
            </Button>
          </TooltipIfDisabledComponent>
        )}
        {!isXs ? (
          <Button onClick={handleExport}>
            {!isDownloading ? (
              <Share style={{ color: '#7A797A', marginRight: 2 }} />
            ) : (
              <CircularProgress size={20} style={{ marginRight: 6 }} />
            )}
            Export
          </Button>
        ) : null}
        {canWithdraw() ? (
          <Button
            startIcon={isWithdrawing ? <CircularLoader size={20} /> : <Backspace />}
            onClick={handleWithdraw}
            style={{ color: '#ED3F26' }}
          >
            Withdraw
          </Button>
        ) : null}
        {canDelete() ? (
          <Button onClick={handleDelete} style={{ color: '#ED3F26' }}>
            <Delete />
            Delete
          </Button>
        ) : null}
        <EditDocumentsDialog
          dialogOpen={editDialogOpen}
          handleClose={() => {
            setEditDialogOpen(false);
          }}
          anchor={editAnchor.current}
          selected={[currentDocument!.id]}
          selectedDocument={currentDocument || undefined}
          inOptions
        />
        <EmailUsersDialog
          dialogOpen={getALinkDialogOpen}
          closeDialog={handleClose}
          onSubmit={submitEmails}
          getLink={handleGetLink}
          defaultSubject={`New Link to Document in ${project?.name}`}
        />
      </div>
      {documentType !== DocumentTemplateType.FieldReports &&
      documentType !== DocumentTemplateType.WarrantyItems ? (
        <div style={{ position: 'absolute', left: -10000, top: -10000 }}>
          <DocumentPrintView />
        </div>
      ) : null}
    </>
  );
}
