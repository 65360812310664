import { Dispatch } from 'react';
import { action } from 'typesafe-actions';
import {
  SET_DIVISION,
  SET_DOCUMENTS,
  SET_DOCUMENTS_TYPE,
  SET_SECTION,
  SET_SUBMITTAL_SECTIONS,
} from './constants';
import {
  DocumentTemplateType,
  INumberedDocumentView,
  IUser,
  ReducedSubmittalCode,
} from '../../api-client/autogenerated';
import { getDocumentsByProjectIdAndTypePaginated } from '../../models/api/project';
import { Documents, Section } from './documents';
import { SET_DOCUMENTS_LOADING } from '../loading/constants';
import { getAllSubmittalCodesByProjectId } from '../../models/api/submittal-code';

const DOCUMENTS_LIMIT = 100;

export const setDocuments = (documents: Documents) => {
  return action(SET_DOCUMENTS, documents);
};

export const setSelectedSection = (section: Section | null) => {
  return action(SET_SECTION, section);
};

export const setSelectedDivision = (division: string | null) => {
  return action(SET_DIVISION, division);
};

export const setDocumentsType = (type: DocumentTemplateType | null) => {
  return action(SET_DOCUMENTS_TYPE, type);
};

export const setSubmittalSections = (codes: ReducedSubmittalCode[]) => {
  return action(SET_SUBMITTAL_SECTIONS, codes);
};

export const reloadDocuments = () => async (dispatch: Dispatch<any>, getState: () => any) => {
  const { type } = getState().documents;
  const { id } = getState().project.project;
  return dispatch(fetchDocumentsByType(id, type));
};

export const fetchDocumentsByType = (projectId: string, type: DocumentTemplateType) => async (
  dispatch: Dispatch<any>,
  getState: () => any,
) => {
  const user: IUser = getState()?.user?.user;
  dispatch({ type: 'SET_DOCUMENTS_LOADING', payload: true });
  dispatch({ type: 'SET_DOCUMENTS_TYPE', payload: type });
  const results: INumberedDocumentView[] = [];
  const promises: Promise<INumberedDocumentView[]>[] = [];
  try {
    const response = await getDocumentsByProjectIdAndTypePaginated(
      projectId,
      type,
      DOCUMENTS_LIMIT,
      0,
      user?.isSiteAdmin,
    );

    results.push(...response.results);
    const numRequests = Math.ceil(response.total / response.results.length) - 1;
    if (numRequests) {
      for (let i = 0; i < numRequests; i += 1) {
        promises.push(
          getDocumentsByProjectIdAndTypePaginated(
            projectId,
            type,
            DOCUMENTS_LIMIT,
            i + 1,
            user?.isSiteAdmin,
          ).then((res) => res.results),
        );
      }
      const newResults = (await Promise.all(promises)).flat();
      results.push(...newResults);
    }
    dispatch({ type: SET_DOCUMENTS, payload: results });
  } catch (e: any) {
    console.log(e);
  } finally {
    dispatch({ type: SET_DOCUMENTS_LOADING, payload: false });
  }
};

export const fetchSubmittalCodes = (projectId: string) => (dispatch: Dispatch<any>) => {
  return getAllSubmittalCodesByProjectId(projectId)
    .then((res) => {
      dispatch({
        type: SET_SUBMITTAL_SECTIONS,
        payload: res
          .filter((c) => c.code.length > 2)
          .sort((a, b) => a.code.localeCompare(b.code, undefined, { numeric: true })),
      });
    })
    .catch(() => {
      dispatch({
        type: SET_SUBMITTAL_SECTIONS,
        payload: [],
      });
    });
};

export const clearSubmittalCodes = () => (dispatch: Dispatch<any>) => {
  return dispatch({ type: SET_SUBMITTAL_SECTIONS, payload: [] });
};
