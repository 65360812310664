import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import { Box, Grid, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CircularLoader from '../loader/CircularLoader';
import DocumentIndexToolbar from './DocumentIndexToolbar';
import DocumentIndexHeader, { HeadCell } from './DocumentIndexHeader';
import {
  Assigned,
  BidTabulationData,
  getBidTabulationRows,
  getUserRows,
  PunchListData,
  Responsibility,
  TaskData,
} from './DocumentIndexData';
import {
  ascendingComparator,
  docTypeShouldOpenFile,
  getComparator,
  getDocumentRows,
  getShouldFilterByStatus,
  getSortOrderPropertyFromDocumentType,
  IPackage,
  Order,
  stableSort,
} from './DocumentIndexUtils';
import { getBidsByProjectId, getDocumentsByProjectIdAndType } from '../../models/api/project';
import {
  ActionTakenType,
  DocumentPriorityType,
  DocumentTemplateType,
  IFile,
  INumberedDocumentView,
  IPhysicalLocation,
  IProjectUser,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import UserIndexRow from './UserIndexRow';
import UserIndexToolbar from './UserIndexToolbar';
import { modifyDocumentsByBatch, modifyDocumentsIndividually } from '../../models/api/documents';
import { getTemplateId, getTemplateIds } from '../../models/api/templates';
import DocumentIndexRow from './DocumentIndexRow';
import {
  createQRCode,
  documentTypeToUrl,
  fileIsPdf,
  IndexMatchParams,
  openInNewTab,
  parseDate,
  urlToDocumentTypeReadable,
} from '../../scripts/utils';
import { getUserState } from '../../features/user/selectors';
import {
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getPhysicalLocationsState,
  getProjectState,
} from '../../features/project/selectors';
import {
  getDocumentsType,
  getSelectedDivision,
  getSelectedSection,
} from '../../features/documents/selectors';
import { reloadDocuments } from '../../features/documents/actions';
import { getDocumentsLoadingState } from '../../features/loading/selectors';
import { fetchDocument } from '../../features/document/actions';
import { UserActionType } from '../form-pages/AddOrManageProjectPage';
import PlanholderListDialog from '../dialogs/PlanholderListDialog';
import { getPlanholderData } from '../../models/api/notifications';
import UserIndexSearch from './UserIndexSearch';
import EmptyFolderIcon from '../../assets/icons/empty-folder.svg';
import { fetchBid } from '../../features/bid/actions';
import { IManageProjectUser } from '../project-users/CreateProjectUsers';
import { getSubscriberSecurityGroupState } from '../../features/security/selectors';
import _ from 'lodash';
import { DocumentLogFilters, FilterDraftOption } from '../dialogs/DocumentLogFilterDialog';
import { Location } from 'history';
import { useParams } from 'react-router';
import logoSvg from '../../images/wordmark-blue.svg';
import '../../css/log-table-print.css';
import DashboardChanges from '../dashboard/DashboardChanges';

type DocumentIndexProps = {
  documents?: INumberedDocumentView[];
  projectUsers?: IManageProjectUser[];
  isPlanHolder?: boolean;
  reloadUsers?: () => void;
  createProjectPage?: boolean;
  createProjectUser?: (projectUser: IProjectUser, action?: UserActionType) => void;
  createProjectUsers?: (projectUser: IProjectUser[], action?: UserActionType) => void;
  handleDeleteUser?: (id: string) => void;
  columnTitles: HeadCell<any>[];
  isLoading?: boolean;
  filterFromDate?: Dayjs | null;
  setFilterFromDate?: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  filterToDate?: Dayjs | null;
  setFilterToDate?: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  filterStatus?: string[];
  setFilterStatus?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  filterAssignTo?: string;
  setFilterAssignTo?: React.Dispatch<React.SetStateAction<string | undefined>>;
  filterDownload?: boolean;
  setFilterDownload?: React.Dispatch<React.SetStateAction<boolean>>;
  filterView?: boolean;
  setFilterView?: React.Dispatch<React.SetStateAction<boolean>>;
  filterProcore?: boolean;
  setFilterProcore?: React.Dispatch<React.SetStateAction<boolean>>;
  filterPunchListStatus?: string[];
  setFilterPunchListStatus?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  filterFromCost?: number;
  setFilterFromCost?: React.Dispatch<React.SetStateAction<number | undefined>>;
  filterToCost?: number;
  setFilterToCost?: React.Dispatch<React.SetStateAction<number | undefined>>;
  filterBuilding?: string;
  setFilterBuilding?: React.Dispatch<React.SetStateAction<string>>;
  filterFloor?: string[];
  setFilterFloor?: React.Dispatch<React.SetStateAction<string[]>>;
  filterSourceFile?: string;
  setFilterSourceFile?: React.Dispatch<React.SetStateAction<string>>;
  filterPackage?: string;
  setFilterPackage?: React.Dispatch<React.SetStateAction<string>>;
  filterDraft?: FilterDraftOption;
  setFilterDraft?: React.Dispatch<React.SetStateAction<FilterDraftOption>>;
  filterSubcontractor?: string;
  setFilterSubcontractor?: React.Dispatch<React.SetStateAction<string>>;
  searchInput?: string;
  setSearchInput?: React.Dispatch<React.SetStateAction<string>>;
  updateUser?: (
    id: string,
    name?: string,
    description?: string,
    email?: string,
    website?: string,
    securityGroupId?: string,
    securityGroupName?: string,
  ) => void;
  label?: string;
  isDirectory?: boolean;
  uploadWorkflowOpen?: boolean;
  setUploadWorkflowOpen?: React.Dispatch<SetStateAction<boolean>>;
  visible?: boolean;
  setVisible?: Function;
  setIndexDrawerOpen?: (input: boolean) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 5,
    },
    paper: {
      width: '100%',
      marginBottom: '16px',
    },
    card: {
      width: '100%',
      marginBottom: '16px',
      boxShadow: '0px 5px 15px rgb(0, 0, 0, .15)',
    },
    tablePagination: {
      display: 'flex',
      justifyContent: 'center',
      color: '#2C69D6',
    },
  }),
);

const documentPathRegex = /\/main\/projects\/[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}\/documents\/[a-zA-Z-]+\/[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}/;

interface IndexState extends DocumentLogFilters {
  page: number;
}

export type PunchListLocationRow = {
  location: PunchListData;
  children: PunchListData[];
  locationObject: IPhysicalLocation;
};

export type TaskAndShareLocationRow = {
  parent: TaskData;
  children: TaskData[];
};

function DocumentIndex(props: DocumentIndexProps) {
  const classes = useStyles();
  const {
    documents,
    projectUsers,
    isPlanHolder,
    reloadUsers = () => {},
    createProjectPage,
    createProjectUser = () => {},
    createProjectUsers,
    handleDeleteUser = () => {},
    columnTitles,
    isLoading,
    filterFromDate,
    setFilterFromDate,
    filterToDate,
    setFilterToDate,
    filterStatus,
    setFilterStatus,
    filterAssignTo,
    setFilterAssignTo,
    filterDownload,
    setFilterDownload,
    filterView,
    setFilterView,
    filterProcore,
    setFilterProcore,
    filterPunchListStatus,
    setFilterPunchListStatus,
    filterFromCost,
    setFilterFromCost,
    filterToCost,
    setFilterToCost,
    filterBuilding,
    setFilterBuilding,
    filterFloor,
    setFilterFloor,
    searchInput,
    setSearchInput,
    filterSourceFile,
    setFilterSourceFile,
    filterPackage,
    setFilterPackage,
    filterDraft,
    setFilterDraft,
    filterSubcontractor,
    setFilterSubcontractor,
    updateUser,
    label,
    isDirectory = false,
    uploadWorkflowOpen,
    setUploadWorkflowOpen,
    visible,
    setVisible,
    setIndexDrawerOpen,
  } = props;

  const history = useHistory();
  const params = useParams<IndexMatchParams>();
  const selectedProject = useSelector(getProjectState);
  const selectedSection = useSelector(getSelectedSection);
  const selectedDivision = useSelector(getSelectedDivision);
  const documentsLoading = useSelector(getDocumentsLoadingState);
  const docType = useSelector(getDocumentsType);
  //TODO: Add bidding stuff to redux
  const user = useSelector(getUserState);
  const securityGroups = useSelector(getSubscriberSecurityGroupState);
  /*If a single document is selected, keep track of it so it can be passed to EditDocumentDialog
    to autofill its fields */
  const [selectedDocument, setSelectedDocument] = useState<INumberedDocumentView | undefined>(
    undefined,
  );
  const [canChangeAnticipatedSubmission, setCanChangeAnticipatedSubmission] = useState(true);
  const physicalLocations = useSelector(getPhysicalLocationsState);
  const physicalFloors = useSelector(getPhysicalFloorsState);
  const physicalBuildings = useSelector(getPhysicalBuildingsState);

  const dispatch = useDispatch();

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(!createProjectPage ? 100 : 10);
  const [disableCreatePackage, setDisableCreatePackage] = React.useState(true);
  const [sortedRows, setSortedRows] = React.useState<JSX.Element[]>([]);
  const [filteredRows, setFilteredRows] = React.useState<any[]>([]);
  const [unfilteredRows, setUnfilteredRows] = React.useState<any>();
  const [packageDocuments, setPackageDocuments] = React.useState<INumberedDocumentView[]>([]);

  const [packages, setPackages] = useState<IPackage[]>([]);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);

  const [planholderDialogOpen, setPlanholderDialogOpen] = useState(false);
  const [planholderData, setPlanholderData] = useState<{ date: string; type: string }[]>([]);
  const [planholderCompany, setPlanholderCompany] = useState('');
  const [planholderName, setPlanholderName] = useState('');

  const [isEditingCost, setIsEditingCost] = useState(false);
  const [newCosts, setNewCosts] = useState<{ id: string; cost: number }[]>([]);

  const didMount = useRef<DocumentTemplateType | null>(null);

  const stateRef = useRef<IndexState>({
    filterAssignTo,
    filterStatus,
    filterFromDate: filterFromDate || null,
    filterToDate: filterToDate || null,
    filterFromCost,
    filterToCost,
    filterDownload,
    filterProcore,
    filterView,
    filterBuilding,
    filterFloor,
    filterPunchListStatus,
    filterSourceFile,
    filterPackage,
    filterDraft,
    filterSubcontractor,
    page,
  });

  useEffect(() => {
    stateRef.current = {
      filterAssignTo,
      filterStatus,
      filterFromDate: filterFromDate || null,
      filterToDate: filterToDate || null,
      filterFromCost,
      filterToCost,
      filterDownload,
      filterProcore,
      filterView,
      filterBuilding,
      filterFloor,
      filterPunchListStatus,
      filterSourceFile,
      filterPackage,
      filterDraft,
      filterSubcontractor,
      page,
    };
  }, [
    filterAssignTo,
    filterStatus,
    filterFromDate,
    filterToDate,
    filterFromCost,
    filterToCost,
    filterDownload,
    filterProcore,
    filterView,
    filterBuilding,
    filterFloor,
    filterPunchListStatus,
    filterSourceFile,
    filterPackage,
    filterDraft,
    filterSubcontractor,
    page,
  ]);

  useEffect(() => {
    // @ts-ignore
    return history.listen((props: Location, action) => {
      if (props.pathname.match(documentPathRegex)) {
        sessionStorage.setItem(params.type, JSON.stringify(stateRef.current));
      }
    });
  }, [params.type]);

  useEffect(() => {
    if (docType) {
      const state = sessionStorage.getItem(documentTypeToUrl[docType]);
      if (state) {
        const { page, ...filters } = JSON.parse(state) as IndexState;
        setPage(page);
        setFilters(
          filters.filterFromDate,
          filters.filterToDate,
          filters.filterStatus,
          filters.filterAssignTo,
          filters.filterDownload,
          filters.filterView,
          filters.filterProcore,
          filters.filterFromCost,
          filters.filterToCost,
          filters.filterPunchListStatus,
          filters.filterBuilding,
          filters.filterFloor,
          filters.filterSourceFile,
          filters.filterPackage,
          filters.filterDraft,
          filters.filterSubcontractor,
        );
        sessionStorage.removeItem(documentTypeToUrl[docType]);
      } else {
        setPage(0);
      }
    }
  }, [docType]);

  const getPackages = async (rows: any[]) => {
    const newPackages: IPackage[] = [];
    const response = await getTemplateIds()
      .then((templateIds) => {
        const newRows = rows.filter((row: any) => {
          if (row.packageDocumentId) {
            const index = newPackages.findIndex((pac) => pac.id === row.packageDocumentId);
            if (index !== -1) {
              newPackages[index].children.push(row);

              return false;
            }
            newPackages.push({ id: row.packageDocumentId, children: [row] });
            return false;
          }
          if (
            templateIds[row.typeId] === DocumentTemplateType.SubmittalPackages ||
            templateIds[row.typeId] === DocumentTemplateType.CloseoutSubmittalPackages ||
            templateIds[row.typeId] === DocumentTemplateType.AsBuiltPackages
          ) {
            newPackages.push({ id: row.id, children: [] });
            return true;
          }
          return true;
        });

        setPackages(newPackages);
        return newRows;
      })
      .catch(() => {
        return [];
      });
    return response;
  };

  const getTotalCost = (rows: PunchListData[]) => {
    return rows.reduce((acc, obj) => {
      return acc + obj.cost;
    }, 0);
  };

  const getCumulativeStatus = (rows: PunchListData[]) => {
    const haveSameStatus = rows.every((row) => row.status === rows[0].status);

    if (haveSameStatus) return rows[0].status;
    return 'N/A';
  };

  const getOldestCreatedOn = (rows: PunchListData[]) => {
    return rows.reduce((acc, item) => (acc.postedDate > item.postedDate ? item : acc)).postedDate;
  };

  const getMostRecentModifiedDate = (rows: PunchListData[]) => {
    return rows.reduce((acc, item) => (acc.lastModified > item.lastModified ? acc : item))
      .lastModified;
  };

  const getLocationRowData = (row: {
    locationObject: IPhysicalLocation;
    children: PunchListData[];
  }): PunchListData => {
    const { locationObject: location, children } = row;
    const building =
      physicalBuildings.find(
        (b) => b.id === physicalFloors.find((f) => f.id === location.floorId)?.buildingId,
      )?.name || '';
    return {
      id: location.id,
      building,
      locationId: location.name,
      title: 'All Items In This Location',
      // responsibleParty: children.every((c) =>
      //   children.some((c2) => c.responsibleParty === c2.responsibleParty),
      // )
      //   ? children[0].responsibleParty
      //   : 'N/A',
      responsibleParty: 'N/A',
      status: getCumulativeStatus(children),
      cost: getTotalCost(children),
      postedDate: getOldestCreatedOn(children),
      lastModified: getMostRecentModifiedDate(children),
      isDraft: false,
      isHidden: false,
    };
  };

  const getLocations = async (rows: any[]): Promise<PunchListLocationRow[]> => {
    const locationsWithChildren: {
      locationObject: IPhysicalLocation;
      children: PunchListData[];
    }[] = [];
    rows.forEach((row) => {
      if (row.locationId) {
        const locationIndex = locationsWithChildren.findIndex(
          ({ locationObject: location }) => location.id === row.locationId,
        );
        if (locationIndex !== -1) locationsWithChildren[locationIndex].children.push(row);
        else {
          const location = physicalLocations.find((loc) => loc.id === row.locationId);
          if (location) locationsWithChildren.push({ locationObject: location, children: [row] });
        }
      }
    });
    return locationsWithChildren
      .map((locationWithChildren) => ({
        location: getLocationRowData(locationWithChildren),
        children: locationWithChildren.children,
        locationObject: locationWithChildren.locationObject,
      }))
      .sort((a, b) => ascendingComparator(a.location, b.location, 'building'));
  };

  const getDesignPackages = async (rows: any[]): Promise<TaskAndShareLocationRow[]> => {
    const tasksWithChildren: {
      parent: TaskData;
      children: TaskData[];
    }[] = [];

    rows.forEach((row) => {
      if (row.linkedParentDocumentId) {
        const taskIndex = tasksWithChildren.findIndex(
          ({ parent }) => parent.id === row.linkedParentDocumentId,
        );
        if (taskIndex !== -1) {
          tasksWithChildren[taskIndex].children.push(row);
        } else {
          const parentDocument = rows.find((r) => r.id === row.linkedParentDocumentId);
          if (parentDocument) tasksWithChildren.push({ parent: parentDocument, children: [row] });
        }
      }
    });

    return tasksWithChildren.map(({ parent, children }) => ({
      parent,
      children: children.sort((a, b) => ascendingComparator(a, b, 'number')),
    }));
  };

  const handleSelectDocument = async (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    id: string,
    file?: IFile,
    company?: string,
    name?: string,
  ) => {
    if (docType && docTypeShouldOpenFile.includes(docType) && file && fileIsPdf(file)) {
      openInNewTab(file.id);
    } else if (docType === DocumentTemplateType.BidTabulation) {
      dispatch(fetchBid(id));
      history.push(`${documentTypeToUrl[docType!]}/${id}`);
    } else if (docType === DocumentTemplateType.PlanholderList) {
      getPlanholderData(selectedProject!.id, id).then((result) => {
        setPlanholderData(result);
        setPlanholderCompany(company || 'Company Not Found');
        setPlanholderName(name || 'Centerline User');
        setPlanholderDialogOpen(true);
      });
    } else if (docType === DocumentTemplateType.PunchList) {
      history.push(`punch-list/${id}/edit`);
    } else {
      dispatch(fetchDocument(id));
      history.push(`${documentTypeToUrl[docType!]}/${id}`);
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (
        (isPlanHolder || createProjectPage) &&
        projectUsers &&
        selected.length === filteredRows.length - 1
      ) {
        setSelected([]);
        return;
      }
      let newSelected: string[] = [];
      if (documents) {
        if (docType === DocumentTemplateType.PunchList) {
          const rows = filteredRows as PunchListLocationRow[];
          newSelected = rows.map((row) => row.children.map((item) => item.id)).flat();
        } else {
          newSelected = filteredRows.map((n: any) => n.id);
        }
        setSelected(newSelected);
        return;
      } else if (projectUsers)
        newSelected = filteredRows
          .filter((n: any) => n.id !== 'me' && n.id !== user.id)
          .map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    const rows = filteredRows as PunchListLocationRow[];
    const location = rows.find((row) => row?.location?.id === id);

    if (docType === DocumentTemplateType.PunchList && location) {
      if (location.children.every((item) => selected.includes(item.id))) {
        newSelected = selected.filter(
          (id) => !location.children.map((item) => item.id).includes(id),
        );
      } else {
        newSelected = _.uniq([...selected, ...location.children.map((item) => item.id)]);
      }
    } else if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const updateSearch = (input: string) => {
    if (setSearchInput) setSearchInput(input);
  };

  const isSelected = (id: string) => {
    if (docType === DocumentTemplateType.PunchList) {
      const rows = filteredRows as PunchListLocationRow[];
      const location = rows.find((row) => row.location?.id === id);
      if (location)
        return (
          location.children.length > 0 &&
          location.children.every((item) => selected.includes(item.id))
        );
    }
    return selected.indexOf(id) !== -1;
  };

  const type = documents ? 'document' : 'user';

  const setFilters = (
    fFromDate?: Dayjs | null,
    fToDate?: Dayjs | null,
    fStatus?: string[],
    fAssign?: string,
    fDownload?: boolean,
    fView?: boolean,
    fProcore?: boolean,
    fFromCost?: number,
    fToCost?: number,
    fPLStatus?: string[],
    fBuilding?: string,
    fFloor?: string[],
    fSourceFile?: string,
    fPackage?: string,
    fDraft?: FilterDraftOption,
    fSubcontractor?: string,
  ) => {
    if (setFilterFromDate && fFromDate !== undefined) setFilterFromDate(fFromDate);
    if (setFilterToDate && fToDate !== undefined) setFilterToDate(fToDate);
    if (setFilterStatus) setFilterStatus(fStatus);
    if (setFilterAssignTo) setFilterAssignTo(fAssign);
    if (setFilterDownload) setFilterDownload(fDownload || false);
    if (setFilterView) setFilterView(fView || false);
    if (setFilterProcore) setFilterProcore(fProcore || false);
    if (setFilterFromCost) setFilterFromCost(fFromCost);
    if (setFilterToCost) setFilterToCost(fToCost);
    if (setFilterPunchListStatus) setFilterPunchListStatus(fPLStatus);
    if (setFilterBuilding) setFilterBuilding(fBuilding || '');
    if (setFilterFloor) setFilterFloor(fFloor || []);
    if (setFilterSourceFile) setFilterSourceFile(fSourceFile || '');
    if (setFilterPackage) setFilterPackage(fPackage || '');
    if (setFilterDraft) setFilterDraft(fDraft || FilterDraftOption.ALL);
    if (setFilterSubcontractor) setFilterSubcontractor(fSubcontractor || '');
  };

  const filterRows = (rows: any[]) => {
    //filtering for users
    if (!rows) return [];
    if (createProjectPage) {
      if (searchInput) {
        return rows.filter((row: any) => {
          let valid = false;
          Object.values(row).forEach((field: any) => {
            if (typeof field === 'string') {
              if (field.toLowerCase().includes(searchInput.toLowerCase())) {
                valid = true;
              }
            }
          });
          return valid;
        });
      }
      return rows;
    }

    let filteredRowsTemp = [...rows];

    if (searchInput) {
      filteredRowsTemp = filteredRowsTemp.filter((row: any) => {
        let valid = false;
        if (
          docType === DocumentTemplateType.Submittals ||
          (docType === DocumentTemplateType.CloseoutSubmittals &&
            row.hasOwnProperty('submittalDescription'))
        ) {
          valid =
            row.submittalDescription.sectionTitle
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            row.submittalDescription.title.toLowerCase().includes(searchInput.toLowerCase());
        } else {
          Object.values(row).forEach((field: any) => {
            if (typeof field === 'string') {
              if (field.toLowerCase().includes(searchInput.toLowerCase())) {
                valid = true;
              }
            }
          });
        }
        return valid;
      });
    }

    if (filterFromDate && filterToDate) {
      filteredRowsTemp = filteredRowsTemp.filter((row: INumberedDocumentView) => {
        let valid: boolean;
        if (row.dueDate) {
          const dueDate = parseDate(row.dueDate);
          valid = dueDate.isSameOrAfter(filterFromDate) && dueDate.isSameOrBefore(filterToDate);
        } else valid = false;
        return valid;
      });
    }

    if (filterStatus?.length && getShouldFilterByStatus(docType)) {
      filteredRowsTemp = filteredRowsTemp.filter((row: any) => {
        if (filterStatus.find((item) => item === 'initial')) {
          if (
            [
              WorkflowStatusType.Initial,
              WorkflowStatusType.SubcontractorUploaded,
              WorkflowStatusType.ReadyForSubmissionToArchitect,
              WorkflowStatusType.GeneralContractorUploaded,
            ].includes(row.status)
          ) {
            if (
              docType === DocumentTemplateType.Task &&
              row.priority === DocumentPriorityType.ForInformationOnly
            )
              return false;
            return true;
          }
        }
        if (filterStatus.find((item) => item === 'under_review')) {
          if (
            row.status === WorkflowStatusType.UnderReview ||
            row.status === WorkflowStatusType.ArchitectUploaded
          ) {
            if (
              docType === DocumentTemplateType.Task &&
              row.priority === DocumentPriorityType.ForInformationOnly
            )
              return false;
            return true;
          }
        }
        if (filterStatus.find((item) => item === ActionTakenType.ReviseAndResubmit)) {
          if (row.action === ActionTakenType.ReviseAndResubmit) return true;
        }
        if (filterStatus.find((item) => item === 'overdue')) {
          if ('overdue' in row && row.overdue) return true;
        }
        if (filterStatus.find((item) => item === DocumentPriorityType.ForInformationOnly)) {
          if (row.priority === DocumentPriorityType.ForInformationOnly) return true;
        }

        if (
          docType === DocumentTemplateType.Task &&
          row.priority === DocumentPriorityType.ForInformationOnly
        )
          return false;
        return filterStatus.includes(row.status);
      });
    }

    if (filterAssignTo) {
      filteredRowsTemp = filteredRowsTemp.filter((row: any) => {
        return (
          (row.hasOwnProperty('responsibility') &&
            (row.responsibility as Responsibility).ids.includes(filterAssignTo)) ||
          (row.hasOwnProperty('assignedTo') && (row.assignedTo as Assigned).id === filterAssignTo)
        );
      });
    }

    if (filterDownload) {
      filteredRowsTemp = filteredRowsTemp.filter((row: any) => {
        return Number(row.download) > 0;
      });
    }

    if (filterView) {
      filteredRowsTemp = filteredRowsTemp.filter((row: any) => {
        return Number(row.view) > 0;
      });
    }

    if (filterProcore) {
      filteredRowsTemp = filteredRowsTemp.filter((row: any) => {
        return !!row.sync;
      });
    }

    //PunchListStatusFilter
    if (filterPunchListStatus?.length && docType === DocumentTemplateType.PunchList) {
      filteredRowsTemp = filteredRowsTemp.filter((row: any) => {
        return filterPunchListStatus?.includes(row.status);
      });
    }

    if (filterFromCost && filterToCost) {
      filteredRowsTemp = filteredRowsTemp.filter((row: any) => {
        const rowCost = parseInt(row!.cost);
        return rowCost >= filterFromCost && filterToCost >= rowCost;
      });
    }

    if (filterFloor?.length) {
      filteredRowsTemp = filteredRowsTemp.filter((row) => {
        const location = physicalLocations?.find((location) => location.id === row.locationId);
        if (location) return filterFloor.includes(location.floorId);
        return false;
      });
    }

    if (filterSourceFile) {
      filteredRowsTemp = filteredRowsTemp.filter(
        (row) => row.parsedFromFileId === filterSourceFile,
      );
    }

    if (filterPackage) {
      if (filterPackage === 'NO_PACKAGE') {
        filteredRowsTemp = filteredRowsTemp.filter((row) => row.simplePackage === 'N/A');
      } else {
        filteredRowsTemp = filteredRowsTemp.filter((row) => row.simplePackage === filterPackage);
      }
    }

    switch (filterDraft) {
      case undefined:
      case FilterDraftOption.ALL: {
        break;
      }

      case FilterDraftOption.DRAFT_ONLY: {
        filteredRowsTemp = filteredRowsTemp.filter((row) => !!row.isDraft);
        break;
      }

      case FilterDraftOption.PUBLISHED_ONLY: {
        filteredRowsTemp = filteredRowsTemp.filter((row) => !row.isDraft);
        break;
      }
    }

    if (filterSubcontractor) {
      filteredRowsTemp = filteredRowsTemp.filter(
        (row) => row.responsibleParty === filterSubcontractor,
      );
    }

    return filteredRowsTemp;
  };

  const getPackageDocumentType = () => {
    if (docType === DocumentTemplateType.AsBuilt) return DocumentTemplateType.AsBuiltPackages;
    if (docType === DocumentTemplateType.CloseoutSubmittals)
      return DocumentTemplateType.CloseoutSubmittalPackages;
    return DocumentTemplateType.SubmittalPackages;
  };

  // ensure minimum height of empty rows
  let emptyRows = Math.max(5 - sortedRows.length, 0);

  const getEmptyRows = () => {
    const tableRows = [];
    if (emptyRows > 0 && searchInput && filteredRows.length === 0) {
      if (searchInput.length > 0) {
        emptyRows -= 1;
        tableRows.push(
          <TableRow style={{ height: 56 }}>
            <TableCell
              colSpan={7}
              style={{ border: 'none', paddingTop: '48px', paddingBottom: '48px' }}
            >
              {' '}
              <Typography variant="body2">
                <i>No results match &quot;{searchInput}.&quot; Try adjusting your filters.</i>
              </Typography>
            </TableCell>
          </TableRow>,
        );
      }
    }
    // if (emptyRows > 0) {
    //   tableRows.push(
    //     <div style={{ height: 56 * emptyRows, backgroundColor: 'white !important' }}>
    //       <div style={{ backgroundColor: 'white !important', border: 'none' }} />
    //     </div>,
    //   );
    // }

    return tableRows;
  };

  let loadingElement = <CircularLoader />;

  if (type === 'user') {
    if (!selectedProject) loadingElement = <div />;
  }

  const componentRef = useRef<any>(null);

  const updateUserRow = (
    id: string,
    name: string | undefined,
    description: string | undefined,
    email: string | undefined,
    website: string | undefined,
    securityGroupId: string | undefined,
  ) => {
    const unfilteredRowsTemp = [...unfilteredRows];
    const r = unfilteredRowsTemp.find((x) => x.id === id);
    if (r) {
      r.name = name;
      r.description = description;
      r.email = email;
      r.website = website;
      r.securityGroup = securityGroups.find((x) => x.id === securityGroupId) || undefined;
    }

    if (updateUser) {
      updateUser(
        id,
        name,
        description,
        email,
        website,
        securityGroupId,
        securityGroups.find((x) => x.id === securityGroupId)?.name,
      );
    }
    setUnfilteredRows(unfilteredRowsTemp);
  };

  const getPackageItems = (row: any) => {
    if (!docType) return undefined;
    if (
      docType === DocumentTemplateType.CloseoutSubmittals ||
      docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.AsBuilt
    ) {
      const pacIndex = packages.findIndex((pac) => pac.id === row.id);
      return pacIndex === -1 ? undefined : packages[pacIndex].children;
    } else if (
      [
        DocumentTemplateType.PunchList,
        DocumentTemplateType.Addenda,
        DocumentTemplateType.InformationalItems,
      ].includes(docType)
    ) {
      return row.children;
    }
  };

  const isIndeterminate = (row: PunchListLocationRow) => {
    if (docType !== DocumentTemplateType.PunchList) return false;
    const childRowIds = row.children?.map((c) => c.id);
    if (!childRowIds) return false;
    return (
      childRowIds.some((id) => selected.includes(id)) &&
      !childRowIds.every((id) => selected.includes(id))
    );
  };

  const getDocumentIndexRow = (row: any, index: number) => {
    const getRowByDocumentType = () => {
      switch (docType) {
        case DocumentTemplateType.PunchList:
          return row.location;

        default:
          return row;
      }
    };

    // @ts-ignore
    return (
      <DocumentIndexRow
        key={(row.id || row.location?.id) + index}
        row={getRowByDocumentType()}
        index={index}
        packageItems={getPackageItems(row)}
        handleClick={handleClick}
        isSelected={isSelected}
        isIndeterminate={isIndeterminate(row)}
        onSelect={handleSelectDocument}
        isPlanholderList={docType === DocumentTemplateType.PlanholderList}
        isEditingCost={isEditingCost}
        updateRowCost={updateRowCost}
      />
    );
  };

  const addToCloseout = () => {
    if (docType === DocumentTemplateType.Submittals && selected.length > 0) {
      getTemplateId(DocumentTemplateType.CloseoutSubmittals).then((closeoutSubmittalTemplateId) => {
        getTemplateId(DocumentTemplateType.CloseoutSubmittalPackages).then(() => {
          const submittalIds = selected.filter(
            (docId) => packages.findIndex((x) => x.id === docId) === -1,
          );
          const submittalPackageIds = selected.filter(
            (docId) => packages.findIndex((x) => x.id === docId) !== -1,
          );

          //Running two editDocumentsByIds calls concurrently breaks the API for some reason,
          //so have to wait until one API call is finished before executing the next.
          if (submittalIds.length > 0)
            modifyDocumentsByBatch(submittalIds, {
              patch: {
                documentTemplateId: closeoutSubmittalTemplateId,
              },
            }).then(() => {
              if (submittalPackageIds.length > 0)
                modifyDocumentsByBatch(submittalPackageIds, {
                  patch: {
                    documentTemplateId: closeoutSubmittalTemplateId,
                  },
                }).then(() => {
                  dispatch(reloadDocuments());
                  setSelected([]);
                });
              else {
                dispatch(reloadDocuments());
                setSelected([]);
              }
            });
          else {
            if (submittalPackageIds.length > 0)
              modifyDocumentsByBatch(submittalPackageIds, {
                patch: {
                  documentTemplateId: closeoutSubmittalTemplateId,
                },
              }).then(() => {
                dispatch(reloadDocuments());
                setSelected([]);
              });
            else {
              dispatch(reloadDocuments());
              setSelected([]);
            }
          }
        });
      });
    }
  };

  const removeFromCloseout = () => {
    if (docType === DocumentTemplateType.CloseoutSubmittals) {
      getTemplateId(DocumentTemplateType.Submittals).then((submittalsTemplateId) => {
        const closeoutIds = selected.filter(
          (docId) => packages.findIndex((x) => x.id === docId) === -1,
        );
        const closeoutPackageIds = selected.filter(
          (docId) => packages.findIndex((x) => x.id === docId) !== -1,
        );

        if (closeoutIds.length > 0)
          modifyDocumentsByBatch(closeoutIds, {
            patch: {
              documentTemplateId: submittalsTemplateId,
            },
          }).then(() => {
            if (closeoutPackageIds.length > 0)
              modifyDocumentsByBatch(closeoutPackageIds, {
                patch: {
                  documentTemplateId: submittalsTemplateId,
                },
              }).then(() => {
                dispatch(reloadDocuments());
                setSelected([]);
              });
            else {
              dispatch(reloadDocuments());
              setSelected([]);
            }
          });
        else {
          if (closeoutPackageIds.length > 0)
            modifyDocumentsByBatch(closeoutPackageIds, {
              patch: {
                documentTemplateId: submittalsTemplateId,
              },
            }).then(() => {
              dispatch(reloadDocuments());
              setSelected([]);
            });
          else {
            dispatch(reloadDocuments());
            setSelected([]);
          }
        }
      });
    }
  };

  const setDefaultOrderBy = () => {
    setOrderBy(getSortOrderPropertyFromDocumentType(docType));
  };

  useEffect(() => {
    if (didMount.current && didMount.current !== docType) {
      setFilters(null, null);
    }
    didMount.current = docType;
  }, [docType, history]);

  const fetchRows = async () => {
    if (!selectedProject) return;
    setDefaultOrderBy();
    if (documents) {
      setUnfilteredRows(undefined);
      setFilteredRows([]);
      setSortedRows([]);

      //Case for submittals and as-builts
      if (
        docType === DocumentTemplateType.Submittals ||
        docType === DocumentTemplateType.CloseoutSubmittals ||
        docType === DocumentTemplateType.AsBuilt
      ) {
        getDocumentsByProjectIdAndType(selectedProject.id, getPackageDocumentType())
          .then(async (packageDocuments) => {
            setPackageDocuments(packageDocuments);
            getPackages(
              await getDocumentRows(
                documents,
                docType,
                packageDocuments,
                [],
                selectedSection,
                selectedDivision,
              ),
            ).then((rows) => {
              setUnfilteredRows(rows);
            });
          })
          .catch(async () => {
            const rows = getPackages(
              await getDocumentRows(documents, docType, [], [], selectedSection, selectedDivision),
            );
            setUnfilteredRows(rows);
          });
      }

      // case for punch list
      else if (docType === DocumentTemplateType.PunchList) {
        setUnfilteredRows(await getLocations(await getDocumentRows(documents, docType)));
      } else {
        //Case for bid tabulation
        if (docType === DocumentTemplateType.BidTabulation) {
          let bidData: BidTabulationData[] = [];
          getBidsByProjectId(selectedProject!.id).then((data) => {
            bidData = getBidTabulationRows(data);
            setUnfilteredRows(bidData);
          });
        } else {
          //Case for all other document rows
          const rows = await getDocumentRows(
            documents,
            docType,
            [],
            projectUsers,
            selectedSection,
            selectedDivision,
            selectedProject?.bidSetupId,
          );
          setUnfilteredRows(rows);
        }
      }
    } else if (projectUsers) {
      if (!unfilteredRows) {
      }
      const rows = getUserRows(projectUsers);
      setUnfilteredRows(rows);
    }
  };

  const filterLocations = (rows: PunchListLocationRow[]) => {
    if (filterBuilding)
      return rows.filter((row) => {
        const floor = physicalFloors.find((f) => f.id === row.locationObject.floorId);
        if (floor) return floor.buildingId === filterBuilding;
        return false;
      });
    return rows;
  };

  const updateRowCost = (id: string, cost: number) => {
    setNewCosts((prev) => {
      const index = prev.findIndex((row) => row.id === id);
      if (index !== -1) {
        return [...prev.slice(0, index), { id, cost }, ...prev.slice(index + 1)];
      } else {
        return [...prev, { id, cost }];
      }
    });
  };

  const handleEditCosts = async () => {
    try {
      if (newCosts.length > 0) {
        await modifyDocumentsIndividually(
          newCosts.map(({ id, cost }) => ({
            documentId: id,
            modification: {
              patch: { cost: cost !== null && cost !== undefined ? Math.round(cost) : undefined },
            },
          })),
          false,
        );
        dispatch(reloadDocuments());
      }
    } finally {
      setIsEditingCost(false);
    }
  };

  useEffect(() => {
    if (!isEditingCost) setNewCosts([]);
  }, [isEditingCost]);

  useEffect(() => {
    if (
      (documents && docType) ||
      (projectUsers && (isPlanHolder || createProjectPage || isDirectory))
    ) {
      fetchRows();
    }
  }, [
    selectedDivision,
    projectUsers,
    createProjectPage,
    isPlanHolder,
    isDirectory,
    selectedSection,
    selectedProject,
    documents,
    docType,
  ]);

  useEffect(() => {
    setPage(0);
    if (createProjectPage && searchInput !== undefined) {
      setFilteredRows(filterRows(unfilteredRows));
    } else {
      if (docType === DocumentTemplateType.PunchList) {
        if (unfilteredRows) {
          const filteredLocations = filterLocations(unfilteredRows);
          const newRows = filteredLocations
            .map((row: PunchListLocationRow) => ({
              location: row.location,
              children: filterRows(row.children),
            }))
            .filter((l) => l.children.length > 0);
          setFilteredRows(newRows);
        }
      } else setFilteredRows(filterRows(unfilteredRows));
    }
  }, [
    unfilteredRows,
    searchInput,
    filterAssignTo,
    filterFromDate,
    filterStatus,
    filterToDate,
    filterDownload,
    filterView,
    filterProcore,
    filterPunchListStatus,
    filterFromCost,
    filterToCost,
    filterBuilding,
    filterFloor,
    filterSourceFile,
    filterPackage,
    filterDraft,
    filterSubcontractor,
  ]);

  const sortPunchListRow = (row1: PunchListData, row2: PunchListData) => {
    let result = 0;

    if (orderBy === 'locationId') {
      result = row1.title.localeCompare(row2.title);
    } else if (orderBy === 'cost') {
      result = (row1.cost || 0) - (row2.cost || 0);
    } else if (orderBy === 'postedDate') {
      result = row1.postedDate.localeCompare(row2.postedDate);
    } else if (orderBy === 'lastModified') {
      result = row1.lastModified.localeCompare(row2.lastModified);
    }

    return order === 'asc' ? result : -result;
  };

  const getFloorNameByLocationId = (locationId: string) =>
    physicalFloors.find((f) => f.id === physicalLocations.find((l) => l.id === locationId)?.floorId)
      ?.name || '';

  useEffect(() => {
    let sortedRows: any[];
    if (docType === DocumentTemplateType.PunchList) {
      sortedRows = filteredRows.map((r) => ({ ...r, children: r.children.sort(sortPunchListRow) }));
      if (orderBy === 'locationId') {
        sortedRows = sortedRows.sort((a, b) => {
          let result = a.location?.building.localeCompare(b.location?.building);
          if (result === 0) {
            result = getFloorNameByLocationId(a.location?.id).localeCompare(
              getFloorNameByLocationId(b.location?.id),
            );

            if (result === 0) {
              result = a.location?.locationId.localeCompare(b.location?.locationId, 'en', {
                numeric: true,
              });
            }
          }

          return order === 'asc' ? result : -result;
        });
      } else {
        sortedRows = sortedRows.sort((a, b) => sortPunchListRow(a.location, b.location));
      }
    } else {
      sortedRows = stableSort(filteredRows, getComparator(order, orderBy));
    }
    const newRows = sortedRows
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        return type === 'document' ? (
          getDocumentIndexRow(row, index)
        ) : (
          <UserIndexRow
            key={row.id as string}
            row={row}
            index={index}
            handleClick={handleClick}
            isSelected={isSelected}
            createProjectPage={createProjectPage}
            handleDelete={handleDeleteUser}
            securityGroups={securityGroups}
            updateUserRow={updateUserRow}
            isDirectory={isDirectory}
          />
        );
      });
    if (
      docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.CloseoutSubmittals ||
      docType === DocumentTemplateType.AsBuilt
    ) {
      setSortedRows(
        newRows.sort((a, b) => {
          if (a.props.packageItems) return -1;
          if (b.props.packageItems) return 1;
          return 0;
        }),
      );
    } else setSortedRows(newRows);
  }, [filteredRows, orderBy, order, rowsPerPage, page, selected, securityGroups, isEditingCost]);

  useEffect(() => {
    setSelected([]);
  }, [selectedProject, unfilteredRows]);

  useEffect(() => {
    //Determine whether a package can be created or not
    if (
      docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.CloseoutSubmittals ||
      docType === DocumentTemplateType.AsBuilt
    ) {
      if (selected.length > 0) {
        let valid = true;
        const packageIds: string[] = [];
        packages.forEach((pac) => {
          packageIds.push(pac.id);
        });
        selected.forEach((select) => {
          if (packageIds.indexOf(select) >= 0) {
            valid = false;
            return;
          }
        });
        const selectedDocuments = selected
          .map((id) => documents?.find((doc) => doc.id === id))
          .filter((d) => !!d) as INumberedDocumentView[];
        selectedDocuments.forEach((doc) => {
          // TODO: probably revert to only Initial once subcontractor support is added
          if (
            doc &&
            doc.workflowStatus &&
            (![
              WorkflowStatusType.Initial,
              WorkflowStatusType.ReadyForSubmissionToArchitect,
            ].includes(doc.workflowStatus) ||
              !!doc.submittalPackageDocumentId)
          ) {
            valid = false;
            return;
          }
        });
        // all documents must have same division
        if (
          selectedDocuments.some(
            (doc) =>
              !selectedDocuments.every((doc2) => doc.submittalDivision === doc2.submittalDivision),
          )
        ) {
          valid = false;
        }
        setDisableCreatePackage(!valid);
      } else {
        setDisableCreatePackage(true);
      }
    }

    //Set single selected document (used to autofill fields in EditDocumentsDialog)
    if (selected.length === 1) {
      const d = [...(documents || []), ...packageDocuments].find((x) => x.id === selected[0]);
      setSelectedDocument(d);
    } else {
      setSelectedDocument(undefined);
    }

    //If a selected document is past the general_contractor_uploaded stage, need to disable
    //setting anticipated initial submission date in EditDocumentsDialog
    if (selected.length > 0) {
      let valid = true;
      for (let i = 0; i < selected.length && valid; i++) {
        const d = documents?.find((x) => x.id === selected[i]);
        if (
          d?.workflowStatus &&
          d?.workflowStatus! !== WorkflowStatusType.Initial &&
          d?.workflowStatus! !== WorkflowStatusType.SubcontractorUploaded &&
          d?.workflowStatus! !== WorkflowStatusType.ReadyForSubmissionToArchitect &&
          d?.workflowStatus! !== WorkflowStatusType.GeneralContractorUploaded
        )
          valid = false;
      }
      setCanChangeAnticipatedSubmission(valid);
    }
  }, [selected]);

  const handlePaginationChange = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getToolbar = () => {
    if (type === 'document')
      return (
        <DocumentIndexToolbar
          selectedProject={selectedProject!}
          selected={selected}
          updateSearch={updateSearch}
          filters={{
            filterToDate: filterToDate || null,
            filterFromDate: filterFromDate || null,
            filterStatus,
            filterAssignTo,
            filterDownload,
            filterView,
            filterProcore,
            filterToCost,
            filterFromCost,
            filterPunchListStatus,
            filterBuilding,
            filterFloor,
            filterSourceFile,
            filterPackage,
            filterDraft,
            filterSubcontractor,
          }}
          setFilters={setFilters}
          filteredRows={filteredRows}
          packages={packages}
          updateRows={() => setShouldUpdate(!shouldUpdate)}
          componentReference={componentRef}
          addToCloseout={addToCloseout}
          removeFromCloseout={removeFromCloseout}
          selectedDocument={selectedDocument}
          canChangeAnticipatedSubmission={canChangeAnticipatedSubmission}
          uploadWorkflowOpen={uploadWorkflowOpen}
          setUploadWorkflowOpen={setUploadWorkflowOpen}
          disableCreatePackage={disableCreatePackage}
          isEditingCost={isEditingCost}
          setIsEditingCost={setIsEditingCost}
          submitCosts={handleEditCosts}
          visible={visible}
          setVisible={setVisible}
          setIndexDrawerOpen={setIndexDrawerOpen}
        />
      );
    if (!isDirectory)
      return (
        <UserIndexToolbar
          users={projectUsers!}
          selectedProject={selectedProject}
          reloadUsers={reloadUsers || (() => true)}
          createProjectPage={createProjectPage}
          createProjectUser={createProjectUser}
          createProjectUsers={createProjectUsers!}
          securityGroups={securityGroups}
          label={label!}
        />
      );
    return null;
  };

  const getRowCount = () => {
    if (docType === DocumentTemplateType.PunchList)
      return (filteredRows as PunchListLocationRow[]).map((row) => row.children).flat().length;
    return filteredRows.length;
  };

  const qrcodeRef = useRef(null);
  const [qrcodeCreated, setQrcodeCreated] = useState(false);

  useEffect(() => {
    if (qrcodeRef.current && !qrcodeCreated) {
      createQRCode({
        qrcodeRef: qrcodeRef.current!,
        link: window.location.href,
        width: 133,
        height: 133,
        drawer: 'svg',
      });
      setQrcodeCreated(true);
    }
  }, []);

  return (
    <div className={classes.root}>
      <style type="text/css" media="print">
        {' @page { size: landscape; } '}
      </style>
      {getToolbar()}
      <Paper className={createProjectPage ? classes.paper : classes.card}>
        <TableContainer ref={componentRef}>
          {((documentsLoading && !createProjectPage) || isLoading || !unfilteredRows) && (
            <Grid container alignContent="center" justify="center" style={{ height: 100 }}>
              <Grid item>{loadingElement}</Grid>
            </Grid>
          )}
          <div className="print-only" style={{ marginLeft: 15 }}>
            <img
              src={logoSvg}
              id="centerline-logo"
              alt="centerline-logo"
              className="log-table-logo"
            />

            <div className="qrcode" ref={qrcodeRef} />

            <Typography>Printed on {new Date().toLocaleString()}</Typography>
            <dl className="list">
              <Typography
                component="dt"
                className="property"
                style={{ fontWeight: 700, fontSize: 18 }}
              >
                Created by:
              </Typography>
              <Typography
                component="dd"
                className="value"
                style={{ fontWeight: 700, fontSize: 18 }}
              >
                {user.name}
              </Typography>
              <Typography
                component="dt"
                className="property"
                style={{ fontWeight: 700, fontSize: 18 }}
              >
                Project:
              </Typography>
              <Typography
                component="dd"
                className="value"
                style={{ fontWeight: 700, fontSize: 18 }}
              >
                {selectedProject?.name}
              </Typography>
              <Typography
                component="dt"
                className="property"
                style={{ fontWeight: 700, fontSize: 18 }}
              >
                Document Type:
              </Typography>
              <Typography
                component="dd"
                className="value"
                style={{ fontWeight: 700, fontSize: 18 }}
              >
                {urlToDocumentTypeReadable(params.type)}
              </Typography>
            </dl>
            {docType === DocumentTemplateType.ChangeOrders ? (
              <div style={{ marginLeft: -28 }}>
                <DashboardChanges isDocumentLogTablePage />
              </div>
            ) : null}
          </div>
          {(!documentsLoading || createProjectPage) && !isLoading && unfilteredRows && (
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              style={{ emptyCells: 'show' }}
            >
              <DocumentIndexHeader
                columnTitles={columnTitles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={getRowCount()}
                filteredRows={filteredRows}
                isPlanholderList={docType === DocumentTemplateType.PlanholderList}
                isUserTable={projectUsers !== undefined && documents === undefined}
                hideCheckboxes={isDirectory}
              />

              <TableBody>
                {sortedRows}
                {getEmptyRows()}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {!isPlanHolder &&
          !createProjectPage &&
          unfilteredRows &&
          unfilteredRows.length === 0 &&
          sortedRows.length === 0 &&
          selectedProject &&
          !isLoading &&
          !documentsLoading && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '48px 16px 96px',
              }}
            >
              <img
                src={EmptyFolderIcon}
                style={{ width: '80%', maxWidth: '200px' }}
                alt="empty-documents"
              />
              <Typography align="center">
                No{' '}
                <strong>
                  {docType === DocumentTemplateType.BidTabulation ? 'Bid Submissions' : docType}
                </strong>{' '}
                currently exist in this project.
              </Typography>
            </Box>
          )}
        {!isPlanHolder &&
          !createProjectPage &&
          !!unfilteredRows &&
          unfilteredRows.length > 0 &&
          filteredRows.length === 0 &&
          !!selectedProject &&
          !isLoading &&
          !documentsLoading && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '48px 16px 96px',
              }}
            >
              <img
                src={EmptyFolderIcon}
                style={{ width: '80%', maxWidth: '200px' }}
                alt="empty-documents"
              />
              <Typography align="center">
                No <strong>{docType}</strong> documents were found given the applied filters.
              </Typography>
            </Box>
          )}
        {isPlanHolder &&
          !createProjectPage &&
          (!projectUsers || projectUsers.length === 0) &&
          !!selectedProject &&
          !isLoading &&
          !documentsLoading && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '48px 16px 96px',
              }}
            >
              <img
                src={EmptyFolderIcon}
                style={{ width: '80%', maxWidth: '200px' }}
                alt="empty-documents"
              />
              <Typography align="center">
                No <strong>Planholders</strong> currently exist in this project.
              </Typography>
            </Box>
          )}
      </Paper>
      <div style={{ marginTop: '10px' }}>
        {Math.ceil(filteredRows ? filteredRows.length / rowsPerPage : 1) > 1 && (
          <Pagination
            count={Math.ceil(filteredRows.length / rowsPerPage)}
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
            page={page + 1}
            onChange={handleChangePage}
            className={classes.tablePagination}
          />
        )}
        <div
          style={{
            marginBottom: '32px',
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {createProjectPage && <UserIndexSearch updateSearch={updateSearch} />}
          <p
            style={{
              lineHeight: '0',
              marginTop: '18px',
            }}
          >
            Rows per page:
          </p>
          <Select
            labelId="rowLength-select-label"
            id="rowLength-select"
            value={rowsPerPage}
            onChange={handlePaginationChange}
            style={{
              margin: '0px 10px',
              background: 'transparent',
              float: 'right',
            }}
            SelectDisplayProps={{ style: { background: 'transparent' } }}
          >
            {/* createProjectPage && <MenuItem value={5}>5</MenuItem> */}
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </div>
      </div>
      <PlanholderListDialog
        open={planholderDialogOpen}
        handleClose={() => setPlanholderDialogOpen(false)}
        data={planholderData}
        company={planholderCompany}
        name={planholderName}
      />
    </div>
  );
}

export default DocumentIndex;
