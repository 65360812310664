import React, { useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Close, Edit, HighlightOffRounded, Publish } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  Box,
  Collapse,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import { Autocomplete } from '@material-ui/lab';
import DayjsUtils from '@date-io/dayjs';
import { Dayjs } from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  docTypesEnableStudioLink,
  downloadFileById,
  fileIsPdf,
  getSubmittalTitle,
  getTomorrowDate,
  isPublicPage,
  openInBluebeam,
  openInNewTab,
  parseDate,
  removeNewlines,
  sortLastNamesDesc,
  waitForFileToBeVerified,
} from '../../scripts/utils';
import {
  ActionTakenType,
  CompleteReviewResponse,
  DocumentTemplateType,
  FileCategoryType,
  IComment,
  IFile,
  INumberedDocumentView,
  IProjectUser,
  IUser,
  RespondToReviewPropsResponseEnum,
  SecurityPermissionLevel,
  SubmittalReviewRowProps,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import {
  completeReviewByDocumentId,
  completeReviewForPackageByDocumentId,
  insertSubmissionByDocumentId,
  modifyDocumentByIdWithResponse,
  respondToReview,
  undoCompleteReviewByDocumentId,
  updateReviewByDocumentId,
} from '../../models/api/documents';
import PDFIcon from '../icons/PDF-icon';
import {
  BluebeamButton,
  BootstrapInput,
  CancelButton,
  ProcoreButton,
  SubmitButton,
  TooltipIfDisabledComponent,
} from '../custom-components/CustomButtons';
import { insertConsultantByDocumentId } from '../../models/api/users';
import DownloadIcon from '../icons/Download-icon';
import BluebeamIcon from '../icons/Bluebeam-icon';
import CircularLoader from '../loader/CircularLoader';
import BluebeamFlattenDialog from '../dialogs/BluebeamFlattenDialog';
import { getLatestSubmissionFileByDocumentId } from '../../models/api/files';
import { fetchDocument, reloadDocument, updateDocument } from '../../features/document/actions';
import { getDocumentState } from '../../features/document/selectors';
import { getProjectUsersState } from '../../features/project/selectors';
import DocumentCommentDialog from '../dialogs/DocumentCommentDialog';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import { DatePickerToolbar } from '@material-ui/pickers/DatePicker/DatePickerToolbar';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import {
  getDocPermission,
  getProjectUserPermissionsForAdditionalReview,
} from '../../scripts/store-utils';
import { addSnackbar } from '../../features/snackbar/actions';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import List from '@material-ui/core/List';
import Comment from '../comment/Comment';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import AssignedUserText from '../custom-components/AssignedUserText';
import RefusePackageDialog, { SubmittalInfo } from './packages/RefusePackageDialog';
import RefuseReturnIcon from '../icons/RefuseReturn-icon';
import EyeIcon from '@material-ui/icons/Visibility';
import { getDocumentsType } from '../../features/documents/selectors';
import ManagePermissionsDialog, {
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import { StudioLinkTextMask } from '../custom-components/TextMaskCustom';
import { deleteFile } from '../../models/api/filesystem';
import CompileConsultantReviewDialog from '../dialogs/CompileConsultantReviewDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#FFFFFF',
    borderRadius: '4px',
    padding: '16px',
    width: '400px',
    zIndex: 1,
    minWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '100%',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '8px',
    marginBottom: '16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'left',
    color: '#0947B9',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    // padding: '8px 16px 8px 16px',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  textfield: {
    height: '32px',
    width: '100%',

    /* Gray / Gray 50 */
    background: '#F9F9F9',

    /* Gray / Gray 400 Brand dark */
    border: '1px solid #949494',
    borderRadius: '5px',
  },
  textfieldLarge: {
    height: '96px',
    width: '100%',

    /* Gray / Gray 50 */
    background: '#F9F9F9',

    /* Gray / Gray 400 Brand dark */
    border: '1px solid #949494',
    borderRadius: '5px',
  },
  formcontrol: {
    padding: '8px 0px 8px 0px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
  },
  cancelButton: {
    height: '32px',
    border: '2px solid #949494',
    borderRadius: '4px',
    color: '#7A797A',
  },
  refuseButton: {
    height: '32px',
    color: '#ED3F26',
    border: '2px solid #ED3F26',
    borderRadius: '4px',
    '&:hover': {
      border: '2px solid #FF5D45',
      background: 'transparent',
    },
  },
  submitButton: {
    height: '32px',
    background: '#0947B9',
    color: '#FFF',
    borderRadius: '4px',
    '&:hover': {
      background: '#4380ED',
    },
  },
  submitButtonSmall: {
    height: '32px',
    background: '#0947B9',
    color: '#FFF',
    borderRadius: '4px',
    '&:hover': {
      background: '#4380ED',
    },
    paddingLeft: 14,
    paddingRight: 14,
  },
  acceptButton: {
    height: '32px',
    background: '#128750',
    color: '#FFF',
    borderRadius: '4px',
    '&:hover': {
      background: '#2BB073',
    },
  },
  viewButton: {
    width: '70%',
    height: '32px',
    color: '#0947B9',
    border: '2px solid #0947B9',
    borderRadius: '4px',
    alignSelf: 'center',
    '&:hover': {
      border: '2px solid #4380ED',
      color: '#4380ED',
      background: 'transparent',
    },
  },
  icon: {
    fill: '#B2B1B2',
    marginLeft: '4px',
  },
  fileBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    padding: '12px',
    marginBottom: '16px',
    border: '1px solid #EDECEC',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  fileName: {
    paddingLeft: '8px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '29px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#464546',
  },
  body: {
    marginBottom: '24px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#464546',
  },
  formControl: {
    width: '80%',
    marginRight: '8px',
    marginBottom: '12px',
  },
  datepickerLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    color: '#616061',
  },
  datepicker: {
    background: '#F9F9F9',
    width: '80%',
    height: '35px',
  },
  inputLabel: {
    marginTop: 8,
    marginBottom: 16,
  },
  selectList: {
    maxHeight: 400,
  },
  greenAndBlueButtons: {
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
  mobileFont: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
}));

type Mode = 'CONSULTANT' | 'REVIEW' | 'STUDIO';

type Props = {
  handleClose: () => void;
  setMaxHeight: React.Dispatch<React.SetStateAction<number>>;
  isRfi?: boolean;
  isWcpr?: boolean;
  documentTitle?: string;
};

export default function ReviewAndReturn(props: Props) {
  const classes = useStyles();
  const { handleClose, setMaxHeight, isRfi = false, isWcpr = false, documentTitle } = props;
  const dispatch = useDispatch();
  const document = useSelector(getDocumentState, shallowEqual)!;
  const docType = useSelector(getDocumentsType);
  const projectUsers = useSelector(getProjectUsersState);
  const permission = getDocPermission();

  const isTask = docType === DocumentTemplateType.Task;

  const acceptedConsultantReviews =
    document.additionalReviewDocuments?.filter(
      (doc) => doc.workflowStatus === WorkflowStatusType.Accepted,
    ) || [];
  const acceptedConsultantReviewFiles =
    acceptedConsultantReviews.map((r) => r?.files || []).flat() || [];

  const getCompletedReviewDocument = () => {
    if (
      document.additionalReviewDocuments?.some(
        (doc) => doc.workflowStatus === WorkflowStatusType.Resolved,
      )
    )
      return document.additionalReviewDocuments
        ?.slice()
        .filter((doc) => doc.workflowStatus === WorkflowStatusType.Resolved)
        .sort((a, b) => descendingComparator(a, b, 'updatedOn'))[0];
    if (!document.bluebeamStudioSessionId && acceptedConsultantReviews?.length === 1) {
      return document.additionalReviewDocuments
        ?.slice()
        .filter((doc) => doc.workflowStatus === WorkflowStatusType.Accepted)
        .sort((a, b) => descendingComparator(a, b, 'updatedOn'))[0];
    }
    return null;
  };

  const completedReviewDocument = getCompletedReviewDocument();

  const consultantComments = document.additionalReviewDocuments
    ?.slice()
    .filter(
      ({ workflowStatus }) =>
        workflowStatus === WorkflowStatusType.Accepted ||
        workflowStatus === WorkflowStatusType.Resolved,
    )
    .map(({ comments }) => comments)
    .flat()
    .filter((c) => c) as IComment[];

  const isAnyOpenConsultantReview = Boolean(
    document.additionalReviewDocuments?.some(
      (doc) =>
        doc.workflowStatus === WorkflowStatusType.SubmittedForReview ||
        doc.workflowStatus === WorkflowStatusType.UnderReview ||
        doc.workflowStatus === WorkflowStatusType.ReviewComplete,
    ),
  );

  const isComplete =
    document.workflowStatus &&
    [
      WorkflowStatusType.Completed,
      WorkflowStatusType.ReviewComplete,
      WorkflowStatusType.Accepted,
      WorkflowStatusType.Resolved,
      WorkflowStatusType.Resubmitted,
    ].includes(document.workflowStatus);

  const hasReviewFile =
    document.additionalReviewDocuments?.some(
      (r) => r.workflowStatus === WorkflowStatusType.Resolved && !!r.files?.length,
    ) || document?.files?.some((f) => f.category === FileCategoryType.DocumentArchitectApproved);

  const canAssignConsultant = () => {
    return !isWcpr && !isTask;
  };

  const previousConsultant = (): IUser | null | undefined => {
    if (docType === DocumentTemplateType.DesignPackages) return null;
    const mostRecentReview = document.additionalReviewDocuments
      ?.slice()
      .sort((a, b) => descendingComparator(a, b, 'createdOn'))[0];
    return mostRecentReview?.documentUserList?.find(
      (docUser) => docUser.userId === mostRecentReview?.additionalReviewRecommendedById,
    )?.user;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [title, setTitle] = useState<string>();

  const [mode, setMode] = useState<Mode | null>(null);

  const [currentFile, setCurrentFile] = useState<{ id: string; name: string }>();

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [actionTaken, setActionTaken] = useState<ActionTakenType>();
  const [comment, setComment] = useState('');

  const [bluebeamOpen, setBluebeamOpen] = useState(false);
  const [bluebeamDone, setBluebeamDone] = useState(false);
  const [flattenedFile, setFlattenedFile] = useState<IFile>();

  const [studioLink, setStudioLink] = useState<string | undefined>(
    document.bluebeamStudioSessionId || undefined,
  );
  const [editStudioLink, setEditStudioLink] = useState(false);

  const [commentDialogOpen, setCommentDialogOpen] = useState(false);

  const [consultants, setConsultants] = useState<IProjectUser[]>([]);
  const [inputConsultant, setInputConsultant] = useState<IProjectUser | null>();
  const [commentForConsultant, setCommentForConsultant] = useState<string>();

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  const [commentsOpen, setCommentsOpen] = useState(true);
  const [excludedComments, setExcludedComments] = useState<string[]>([]);

  const [packageDialogOpen, setPackageDialogOpen] = useState(false);
  const [packageDocuments, setPackageDocuments] = useState<INumberedDocumentView[]>([]);
  const [packageReview, setPackageReview] = useState<SubmittalReviewRowProps[]>([]);
  const [latestFile, setLatestFile] = useState<IFile | null | undefined>(undefined);

  const [associationsDialogOpen, setAssociationsDialogOpen] = useState(false);
  const [compileDialogOpen, setCompileDialogOpen] = useState(false);

  const [readyForReview, setReadyForReview] = useState(false);

  const associatedUsers =
    (document?.documentUserList?.map((x) => x.user?.id).filter((e) => e) as string[]) || [];
  const associatedGroups = document?.documentUserGroupList?.map((x) => x.userGroupId) || [];

  useEffect(() => {
    if (!isPublicPage())
      getLatestSubmissionFileByDocumentId(document.id).then((file) => setLatestFile(file));
  }, [document.id]);

  let isPackage =
    document.documentTemplate?.name === DocumentTemplateType.SubmittalPackages ||
    document.documentTemplate?.name === DocumentTemplateType.CloseoutSubmittalPackages;

  useEffect(() => {
    setPackageDocuments(document.submittalChildDocuments || []);
  }, [document]);

  useEffect(() => {
    getPackageReview(getInitialState());
  }, [packageDocuments, isPackage, completedReviewDocument]);

  const everyChildHasReview = () =>
    packageDocuments.every((doc) =>
      packageReview.find((r) => r.documentId === doc.id && !!r.actionTaken),
    );

  const getInitialState = (): SubmittalInfo[] => {
    if (isPackage) {
      if (completedReviewDocument)
        return (
          packageDocuments.map((doc) => {
            const review = doc.additionalReviewDocuments
              ? doc.additionalReviewDocuments[0]
              : undefined;
            const actionTaken = review?.recommendedAction || undefined;
            return {
              document: doc,
              actionTaken,
              comment: '',
              existingComments: review?.comments,
            };
          }) || []
        );
      else
        return packageDocuments.map((doc) => {
          return { document: doc, comment: '' };
        });
    }
    return [];
  };

  const getPackageReview = (info: SubmittalInfo[]) => {
    setPackageReview(
      info.map(({ document, actionTaken, comment, existingComments }) => {
        return {
          documentId: document.id,
          actionTaken,
          comment,
          copiedCommentIds: existingComments?.map((c) => c.id),
        };
      }),
    );
  };

  const addFile = (file: File) => {
    setUploadedFile(file);
  };

  const architectUpload = () => {
    return insertSubmissionByDocumentId(
      document.id,
      (uploadedFile as File)!.name,
      (uploadedFile as File)!,
      undefined,
      handleUploadProgress,
    );
  };

  const handleBluebeamFinish = (flattenedFile: IFile) => {
    dispatch(
      addSnackbar({
        id: Date.now(),
        message: 'Your Bluebeam file is being processed',
        severity: 'success',
      }),
    );
    setBluebeamDone(true);
    setFlattenedFile(flattenedFile);
  };

  const completeReview = async () => {
    const commentsToCopy = consultantComments
      ?.filter(({ id }) => !excludedComments.includes(id))
      ?.map(({ id }) => id);
    let olderDocument: INumberedDocumentView | null = null;
    let verified = isWcpr || isRfi || actionTaken === ActionTakenType.ForInformationOnly;

    if (isAnyOpenConsultantReview && readyForReview) {
      const shouldCancelConsultantReviews = window.confirm(
        'Do you want to proceed? If so, all remaining consultant reviews will be canceled. If not, you will lose any markups added in this step of the action center.',
      );
      if (!shouldCancelConsultantReviews) return;
    }

    try {
      dispatch(blockNavigation());
      setIsLoading(true);

      if (bluebeamDone && flattenedFile) {
        setLoadingMessage('Verifying upload...');
        verified = await waitForFileToBeVerified(flattenedFile, true);
      } else if (uploadedFile) {
        setLoadingMessage(`Uploading ${uploadedFile.name}...`);
        const response = await architectUpload();
        olderDocument = response.document;
        setLoadingMessage('Verifying upload...');
        if (!response.file) verified = false;
        else verified = await waitForFileToBeVerified(response.file);
      } else if (completedReviewDocument && !comment) {
        if (readyForReview) {
          const shouldSubmitConsultantReview = window.confirm(
            "Are you sure you want to submit your accepted consultant's review without additional stamp or markup?",
          );
          if (!shouldSubmitConsultantReview) return;
          else verified = true;
        } else {
          verified = true;
        }
      } else if (latestFile?.category === FileCategoryType.DocumentArchitectApproved) {
        verified = await waitForFileToBeVerified(latestFile);
      }

      if (!verified) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `File ${
              flattenedFile || uploadedFile ? (flattenedFile || uploadedFile)!.name : ''
            } has not finished uploading. You must cancel the upload and try again`,
            severity: 'error',
          }),
        );
        return;
      }

      if (!readyForReview) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Review updated!',
            severity: 'success',
          }),
        );
        dispatch(reloadDocument());
        handleClose();
        return;
      }

      setLoadingMessage('Updating document...');

      let response: CompleteReviewResponse;

      if (isPackage)
        response = await completeReviewForPackageByDocumentId(document.id, packageReview);
      else
        response = await completeReviewByDocumentId(document.id, {
          actionTaken,
          comment,
          copiedCommentIds: commentsToCopy,
        });
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Review complete!',
          severity: 'success',
        }),
      );
      dispatch(updateDocument({ ...(olderDocument && olderDocument), ...response.document }));
      handleClose();
    } catch (e: any) {
      console.error(e);
    } finally {
      dispatch(allowNavigation());
      setIsLoading(false);
    }
  };

  const updateReview = async () => {
    let olderDocument: INumberedDocumentView | null = null;
    let verified: boolean;

    try {
      dispatch(blockNavigation());
      setIsLoading(true);

      if (uploadedFile) {
        setLoadingMessage(`Uploading ${uploadedFile.name}...`);
        const response = await architectUpload();
        olderDocument = response.document;
        setLoadingMessage('Verifying upload...');
        if (!response.file) verified = false;
        else verified = await waitForFileToBeVerified(response.file);
      } else {
        verified = true;
      }

      if (!verified) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `File ${uploadedFile?.name} has not finished uploading. You must cancel the upload and try again`,
            severity: 'error',
          }),
        );
        return;
      }
      setLoadingMessage('Updating document...');

      const response = await updateReviewByDocumentId(document.id, { comment });
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Item updated!',
          severity: 'success',
        }),
      );
      dispatch(updateDocument({ ...(olderDocument && olderDocument), ...response }));
      handleClose();
    } catch (e: any) {
      console.error(e);
    } finally {
      dispatch(allowNavigation());
      setIsLoading(false);
    }
  };

  const handleSubmitConsultant = () => {
    setIsLoading(true);
    insertConsultantByDocumentId(document.id, {
      consultantUserId: inputConsultant!.user!.id,
      dueDate: selectedDate!.toISOString(),
      comment: commentForConsultant,
    })
      .then(({ document }) => {
        dispatch(updateDocument(document));
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Consultant assigned!',
            severity: 'success',
          }),
        );
        setMode(null);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBluebeam = async () => {
    const file = await getLatestSubmissionFileByDocumentId(document.id);
    if (file?.id) {
      if (!fileIsPdf(file)) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Cannot open file in Bluebeam because it is not a PDF',
            severity: 'warning',
          }),
        );
      }
      return openInBluebeam(file.id, file.name);
    }
  };

  const addNewUsersToNotificationList = async (
    userIds: string[],
    associatedGroupIds: string[],
    associatedDocumentIds: string[],
  ) => {
    try {
      const addGroupIds = associatedGroupIds.filter(
        (id) => !document!.documentUserGroupList!.some(({ userGroupId }) => userGroupId === id),
      );
      const removeGroupIds = document!
        .documentUserGroupList!.filter(
          ({ userGroupId }) => !associatedGroupIds.some((id) => id === userGroupId),
        )
        .map(({ userGroupId }) => userGroupId);

      const removeUserIds = document!
        .documentUserList!.filter(({ userId }) => !userIds.some((id) => id === userId))
        .map(({ userId }) => userId);

      const { updatedDocument } = await modifyDocumentByIdWithResponse(document!.id, {
        followers: {
          addUserIds: userIds,
          removeUserIds: removeUserIds,
          removeUserGroupIds: removeGroupIds,
          addUserGroupIds: addGroupIds,
        },
        associatedDocuments: { addIds: associatedDocumentIds },
      });

      dispatch(updateDocument(updatedDocument));
    } catch (e: any) {
      console.log(e);
    } finally {
      setAssociationsDialogOpen(false);
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (ref.current) setMaxHeight(ref.current.clientHeight);
  });

  const canBeConsultant = (permission?: SecurityPermissionLevel | null) => {
    return permission && permission >= 3;
  };

  const canEditStudioId = () => {
    return permission && permission >= 3;
  };

  const fetchConsultants = async () => {
    setConsultants(
      (await getProjectUserPermissionsForAdditionalReview())
        .filter(({ permission }) => canBeConsultant(permission))
        .map(({ projectUser }) => projectUser),
    );
  };

  useEffect(() => {
    if (projectUsers.length > 0 && consultants.length === 0) {
      fetchConsultants();
    }
    //Just in case this component renders before projectUsers have been loaded in
  }, [projectUsers]);

  const getCompleteButtonText = () => {
    if (!readyForReview) return 'Save Changes';
    if (docType === DocumentTemplateType.DesignPackages) return 'DISTRIBUTE COMPLETED REVIEW';
    if (isWcpr) return 'RETURN TO ARCHITECT';
    if (isRfi) return 'RETURN TO CONTRACTOR';
    return 'COMPLETE REVIEW';
  };

  const shouldShowFinalizeConsultantReview = () => {
    if (isComplete || hasReviewFile) return false;

    if (docType === DocumentTemplateType.DesignPackages) {
      if (document.bluebeamStudioSessionId) {
        return true;
      } else {
        return acceptedConsultantReviewFiles.length >= 2;
      }
    } else if (document.bluebeamStudioSessionId) {
      return acceptedConsultantReviews.length !== 0;
    } else {
      return acceptedConsultantReviewFiles.length >= 2;
    }
  };

  const handleDownload = async (fileId: string, filename: string) => {
    try {
      setIsDownloading(true);
      await downloadFileById(fileId, filename);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const getCompleteReviewDisabled = () => {
    if (docType === DocumentTemplateType.DesignPackages) {
      if (document?.bluebeamStudioSessionId) {
        if (!completedReviewDocument && !uploadedFile && !flattenedFile && !latestFile) {
          return true;
        } else {
          return false;
        }
      } else {
        return acceptedConsultantReviewFiles.length >= 2 && !completedReviewDocument;
      }
    }

    if (isRfi || isWcpr) return false;
    if (isPackage && !everyChildHasReview()) return true;
    if (!isPackage && !actionTaken && readyForReview) return true;
    if (actionTaken === ActionTakenType.ForInformationOnly) return false;
    return (
      !uploadedFile &&
      !bluebeamDone &&
      !flattenedFile &&
      !completedReviewDocument &&
      latestFile?.category !== FileCategoryType.DocumentArchitectApproved
    );
  };

  const handleEditStudioId = async () => {
    if (!studioLink) return;

    const { updatedDocument } = await modifyDocumentByIdWithResponse(document.id, {
      patch: {
        bluebeamStudioSessionId: studioLink,
      },
    });

    dispatch(updateDocument(updatedDocument));
  };

  const getLatestFileLabel = () => {
    if (latestFile?.category === FileCategoryType.DocumentArchitectApproved) {
      return 'Final reviewed file:';
    } else if (!!completedReviewDocument) {
      return 'Consultant uploaded review:';
    }

    return 'File submitted for review:';
  };

  const shouldHideActions = () => {
    return (
      docType === DocumentTemplateType.DesignPackages &&
      (latestFile?.category === FileCategoryType.DocumentArchitectApproved ||
        !!completedReviewDocument)
    );
  };

  const handleDeleteLatestFile = async () => {
    if (!latestFile) return;

    if (latestFile.category === FileCategoryType.DocumentArchitectApproved) {
      await deleteFile(document.id, latestFile.id);
      dispatch(fetchDocument(document.id));
    } else if (completedReviewDocument?.workflowStatus === WorkflowStatusType.Resolved) {
      await respondToReview(completedReviewDocument.id, {
        response: RespondToReviewPropsResponseEnum.Accepted,
      });
      dispatch(fetchDocument(document.id));
    } else if (completedReviewDocument?.workflowStatus === WorkflowStatusType.Accepted) {
      await undoCompleteReviewByDocumentId(completedReviewDocument.id);
      dispatch(fetchDocument(document.id));
    }
  };

  const getTaskContent = () => (
    <div>
      <Typography className={classes.datepickerLabel}>Description</Typography>
      <TextField
        variant="outlined"
        multiline
        fullWidth
        value={document.description}
        InputProps={{ style: { padding: 6 } }}
        style={{ marginBottom: 12 }}
      />

      {latestFile && (
        <>
          <Typography className={classes.datepickerLabel}>Current Supporting File</Typography>
          <Box className={classes.fileBox} style={{ width: '100%', borderColor: '#949494' }}>
            <PDFIcon />
            <Typography className={classes.fileName}>{latestFile.name}</Typography>
            <Tooltip
              arrow
              placement="top"
              title={latestFile.isVerified ? '' : 'Upload in progress...'}
            >
              <Button
                color="primary"
                disabled={!latestFile.isVerified || !fileIsPdf(latestFile)}
                onClick={() => openInNewTab(latestFile.id)}
                style={{ height: '24px', marginLeft: 'auto', alignSelf: 'center' }}
              >
                View
              </Button>
            </Tooltip>
          </Box>
        </>
      )}
      <TextField
        multiline
        rows={2}
        placeholder="Response..."
        value={comment}
        onChange={(event) => setComment(event.target.value)}
        style={{ marginBottom: 12 }}
        InputProps={{ disableUnderline: true, style: { padding: '6px' } }}
        className={classes.textfieldLarge}
      />
      {uploadedFile ? (
        <>
          <Typography className={classes.datepickerLabel}>Pending file</Typography>
          <Box
            className={classes.fileBox}
            style={{ width: '100%', alignItems: 'center', borderColor: '#949494' }}
          >
            <IconButton
              onClick={() => setUploadedFile(undefined)}
              style={{ padding: 0, marginRight: 10 }}
            >
              <HighlightOffRounded htmlColor="#616061" />
            </IconButton>
            <PDFIcon />
            <Typography className={classes.fileName}>{uploadedFile.name}</Typography>
          </Box>
        </>
      ) : null}
      <Button
        variant="outlined"
        color="primary"
        style={{ height: '32px', width: '100%', marginBottom: 16 }}
        disabled={!!uploadedFile}
        onClick={() => setUploadDialogOpen(true)}
        startIcon={<Publish fill="#2C69D6" />}
      >
        UPLOAD
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => setAssociationsDialogOpen(true)}
        style={{ marginBottom: 16 }}
      >
        Define Notification List
      </Button>
      {!isLoading ? (
        <>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!uploadedFile && !comment}
            onClick={updateReview}
            style={{ marginBottom: 8 }}
          >
            Send Update and Keep Item Open
          </Button>
          <Typography align="center" style={{ fontWeight: 600, width: '100%' }}>
            OR
          </Typography>
          <Button
            variant="contained"
            fullWidth
            disabled={!uploadedFile && !comment}
            onClick={completeReview}
            className={classes.acceptButton}
            style={{ marginTop: 8 }}
          >
            Complete Item and Return to Assigner
          </Button>
        </>
      ) : (
        <CircularLoader style={{ marginTop: 8 }} />
      )}
    </div>
  );

  const getContent = () => {
    if (isTask) return getTaskContent();

    switch (document.workflowStatus) {
      case WorkflowStatusType.Initial:
        return (
          <>
            <Typography style={{ paddingTop: 8, paddingBottom: 24 }}>
              Awaiting contractor submission.
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ProcoreButton />
            </div>
          </>
        );
      case WorkflowStatusType.ArchitectUploaded:
      case WorkflowStatusType.UnderReview:
        if (!mode) {
          return <></>;
        }
        if (mode === 'CONSULTANT') {
          return (
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <Box className={classes.content}>
                {latestFile && (
                  <Box className={classes.fileBox}>
                    <PDFIcon />
                    <Typography className={classes.fileName}>{latestFile.name}</Typography>
                    <Tooltip
                      arrow
                      placement="top"
                      title={latestFile.isVerified ? '' : 'Upload in progress...'}
                    >
                      <Button
                        color="primary"
                        disabled={!latestFile.isVerified || !fileIsPdf(latestFile)}
                        onClick={() => openInNewTab(latestFile.id)}
                        style={{ height: '24px', marginLeft: 'auto', alignSelf: 'center' }}
                      >
                        View
                      </Button>
                    </Tooltip>
                  </Box>
                )}
                <Typography className={classes.body}>
                  The person you select as a confidential consultant will receive a notification to
                  review the submittal. They will be directed to leave their response and comments
                  in the “Confidential Consultant Review” section.
                </Typography>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginBottom: 16 }}
                >
                  <Autocomplete
                    placeholder="Search"
                    options={consultants.sort((a, b) =>
                      sortLastNamesDesc(a.user?.name, b.user?.name),
                    )}
                    renderOption={(option) => (
                      <AssignedUserText
                        name={option.user!.name!}
                        company={option.user?.company?.name}
                      />
                    )}
                    getOptionLabel={(x) => {
                      if (x.user) {
                        if (x.user.name) return x.user.name;
                      }
                      return '';
                    }}
                    onChange={(e, item) => {
                      setInputConsultant(item);
                    }}
                    value={inputConsultant}
                    // renderInput={(params) => (
                    //   <TextField {...params} label="Consultant" variant="outlined" />
                    // )}
                    renderInput={(params) => (
                      <TextField {...params} label="Consultant" variant="outlined" />
                    )}
                  />
                </FormControl>
                <Typography className={classes.datepickerLabel}>due date for consultant</Typography>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  InputProps={{ style: { padding: 0 } }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{ style: { padding: '8px' } }}
                  disablePast
                  maxDate={parseDate(document.dueDate!).subtract(1, 'days')}
                  minDate={getTomorrowDate()}
                  value={selectedDate}
                  ToolbarComponent={(props) => (
                    <div>
                      <DatePickerToolbar {...props} />
                      {document.dueDate && (
                        <Typography
                          style={{
                            position: 'absolute',
                            top: '34px',
                            right: '28px',
                            color: '#FFF',
                          }}
                        >
                          Due date:
                          <br />
                          {parseDate(document.dueDate).format('MM/DD')}
                        </Typography>
                      )}
                    </div>
                  )}
                  placeholder="mm/dd/yyyy"
                  format="MM/DD/YYYY"
                  onChange={(date) => setSelectedDate(date)}
                  className={classes.datepicker}
                />
                <Typography className={classes.datepickerLabel} style={{ marginTop: 16 }}>
                  comments for consultant
                </Typography>
                <TextField
                  multiline
                  rows={2}
                  placeholder="Comments..."
                  value={commentForConsultant}
                  onChange={(event) => setCommentForConsultant(event.target.value)}
                  InputProps={{ disableUnderline: true, style: { padding: '6px' } }}
                  className={classes.textfieldLarge}
                />
              </Box>
            </MuiPickersUtilsProvider>
          );
        }
        if (mode === 'REVIEW') {
          return (
            <FormControl>
              {isWcpr && document.files && document.files.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    marginBottom: 12,
                    width: '100%',
                  }}
                >
                  <Select
                    input={<BootstrapInput />}
                    value={currentFile}
                    fullWidth
                    displayEmpty
                    onChange={(event) =>
                      setCurrentFile({
                        id: event.target.value as string,
                        name:
                          document.files?.find((f) => f.id === (event.target.value as string))
                            ?.name || '',
                      })
                    }
                    MenuProps={{
                      // @ts-ignore
                      getContentAnchorEl: () => null,
                      classes: { paper: classes.selectList },
                    }}
                    style={{ width: '81%' }}
                  >
                    <MenuItem disabled value={undefined}>
                      Select a file...
                    </MenuItem>
                    {document.files.map((file) => (
                      <MenuItem key={file.id} value={file.id}>
                        {file.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Tooltip arrow title="Open in Bluebeam" placement="top">
                      <IconButton
                        disabled={!currentFile || !currentFile.name.toLowerCase().endsWith('.pdf')}
                        onClick={() => {
                          openInBluebeam(currentFile!.id, currentFile!.name);
                          setBluebeamOpen(true);
                        }}
                        style={{ padding: 0, marginLeft: 12 }}
                      >
                        <BluebeamIcon fill={currentFile ? '#0947B9' : '#C9C8C9'} />
                      </IconButton>
                    </Tooltip>
                    {!isDownloading ? (
                      <Tooltip arrow title="Download" placement="top">
                        <IconButton
                          disabled={!currentFile}
                          onClick={() =>
                            handleDownload(currentFile!.id, currentFile!.name || 'file.pdf')
                          }
                          style={{ padding: 0, marginLeft: 12 }}
                        >
                          <DownloadIcon
                            fill={currentFile ? '#0947B9' : '#C9C8C9'}
                            width={24}
                            height={21}
                            marginBottom={0}
                            marginRight={0}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <CircularLoader size={20} />
                    )}
                  </div>
                </div>
              )}
              {(isRfi || isWcpr) && (
                <>
                  <TextField
                    multiline
                    rows={2}
                    placeholder="Response..."
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                    style={{ marginBottom: 12 }}
                    InputProps={{ disableUnderline: true, style: { padding: '6px' } }}
                    className={classes.textfieldLarge}
                  />
                  {!latestFile && (
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ height: '32px', width: '100%', marginBottom: 8 }}
                      disabled={bluebeamDone || !!uploadedFile}
                      onClick={() => setUploadDialogOpen(true)}
                      startIcon={<Publish fill="#2C69D6" />}
                    >
                      UPLOAD
                    </Button>
                  )}
                </>
              )}
              {!isWcpr && latestFile && (
                <>
                  <Typography style={{ marginBottom: '4px' }}>{getLatestFileLabel()}</Typography>
                  <Box className={classes.fileBox} style={{ width: '100%', marginBottom: 8 }}>
                    <PDFIcon />
                    <Typography className={classes.fileName}>{latestFile.name}</Typography>
                    <div style={{ display: 'inline-flex', flexGrow: 100 }} />
                    <Button
                      color="primary"
                      onClick={() => openInNewTab(latestFile!.id)}
                      style={{ padding: 0, marginTop: 2 }}
                    >
                      View
                    </Button>
                    {latestFile.category !== FileCategoryType.DocumentGeneralContractorApproved ? (
                      <Tooltip arrow placement="top" title="Delete File">
                        <IconButton
                          onClick={handleDeleteLatestFile}
                          style={{ padding: '0 0 0 4px' }}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Box>
                </>
              )}
              {!isWcpr && !shouldShowFinalizeConsultantReview() && latestFile && (
                <div style={{ display: 'flex', paddingTop: 8, paddingBottom: 8 }}>
                  {latestFile.isVerified ? (
                    <>
                      {!isDownloading ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ height: '32px', width: '48%' }}
                          onClick={() => {
                            const file = latestFile;
                            handleDownload(file.id, file.name);
                          }}
                          startIcon={<DownloadIcon fill="#2C69D6" />}
                        >
                          Download
                        </Button>
                      ) : (
                        <CircularLoader size={30} style={{ width: 170 }} />
                      )}
                      <div style={{ display: 'inline-flex', flexGrow: 100 }} />

                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ height: '32px', width: '48%' }}
                        disabled={bluebeamDone || !!uploadedFile}
                        onClick={() => setUploadDialogOpen(true)}
                        startIcon={<Publish fill="#2C69D6" />}
                      >
                        UPLOAD
                      </Button>
                    </>
                  ) : (
                    <Button fullWidth variant="outlined" color="primary" disableElevation disabled>
                      Upload in progress...
                    </Button>
                  )}
                </div>
              )}
              {!shouldShowFinalizeConsultantReview() ? (
                <>
                  <Typography style={{ fontWeight: 600, alignSelf: 'center' }}>OR</Typography>
                  <div
                    style={{
                      display: 'flex',
                      paddingTop: 8,
                      flexDirection: 'column',
                      paddingBottom: 16,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        (latestFile && !fileIsPdf(latestFile)) ||
                        bluebeamDone ||
                        !!uploadedFile ||
                        (!latestFile && !document.bluebeamStudioSessionId)
                      }
                      onClick={() => setBluebeamOpen(true)}
                      startIcon={<BluebeamIcon fill="white" width={16} height={16} />}
                    >
                      MARKUP IN BLUEBEAM
                    </Button>
                    {uploadedFile || flattenedFile ? (
                      <>
                        <Typography style={{ marginTop: '8px' }}>Pending file:</Typography>
                        <div
                          style={{
                            display: 'flex',
                            paddingTop: 16,
                            alignItems: 'center',
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              if (uploadedFile) setUploadedFile(undefined);
                              else {
                                setBluebeamDone(false);
                                setFlattenedFile(undefined);
                              }
                            }}
                            style={{ padding: 0, marginRight: 12 }}
                          >
                            <HighlightOffRounded htmlColor="#616061" />
                          </IconButton>
                          <PDFIcon />
                          <div style={{ paddingLeft: 8, width: '80%' }}>
                            {uploadedFile?.name || flattenedFile?.name}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setCompileDialogOpen(true)}
                  style={{ whiteSpace: 'nowrap', marginTop: 8, marginBottom: 16 }}
                >
                  Finalize Consultant Review
                </Button>
              )}
              <FormControl style={{ marginBottom: 12, marginTop: 8 }}>
                <RadioGroup
                  value={String(readyForReview)}
                  onChange={(event, value) => setReadyForReview(value === 'true')}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio style={{ padding: '0px 9px' }} color="primary" />}
                    label="Review in Progress"
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Review Complete"
                  />
                </RadioGroup>
              </FormControl>
              {!isRfi && !isWcpr && (
                <>
                  {!isPackage && (
                    <>
                      <Typography>Action Taken</Typography>

                      <FormControl fullWidth variant="outlined" className={classes.inputLabel}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // input={<BootstrapInput />}
                          value={actionTaken}
                          disabled={!readyForReview}
                          onChange={(event) =>
                            setActionTaken(event.target.value as ActionTakenType)
                          }
                          fullWidth
                          MenuProps={{ classes: { paper: classes.selectList } }}
                          style={{ height: 42 }}
                        >
                          <MenuItem value={ActionTakenType.ForInformationOnly}>
                            For Information Only
                          </MenuItem>
                          <MenuItem value={ActionTakenType.AmendAsNoted}>Amend As Noted</MenuItem>
                          <MenuItem value={ActionTakenType.NoExceptionsTaken}>
                            No Exceptions Taken
                          </MenuItem>
                          <MenuItem value={ActionTakenType.ReviseAndResubmit}>
                            Revise & Resubmit
                          </MenuItem>
                          <MenuItem value={ActionTakenType.SeeSubmittalComments}>
                            See Submittal Comments
                          </MenuItem>
                          <MenuItem value={ActionTakenType.SeeTransmittalComments}>
                            See Transmittal Comments
                          </MenuItem>
                          <MenuItem value={ActionTakenType.SubmitSpecifiedItem}>
                            Submit Specified Item
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  )}
                  {isPackage && (
                    <>
                      <Button
                        variant="contained"
                        disabled={!completedReviewDocument && !uploadedFile && !bluebeamDone}
                        onClick={() => setPackageDialogOpen(true)}
                        className={classes.submitButton}
                        style={{ marginBottom: '16px' }}
                      >
                        <RefuseReturnIcon fill="#FFF" style={{ marginRight: '6px' }} /> Review
                        Package
                      </Button>
                      <div style={{ marginBottom: '16px' }}>
                        {packageReview
                          .filter((p) => !!p.actionTaken)
                          .map((p) => {
                            const document = packageDocuments.find(
                              (doc) => doc.id === p.documentId,
                            );
                            if (!document) return null;
                            return (
                              <div
                                key={p.documentId}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  marginLeft: '1px',
                                }}
                              >
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {getSubmittalTitle(document)}
                                </span>
                                <IconButton
                                  disabled={
                                    !completedReviewDocument && !uploadedFile && !bluebeamDone
                                  }
                                  onClick={() => setPackageDialogOpen(true)}
                                  style={{ padding: 0 }}
                                >
                                  <EyeIcon fontSize="small" />
                                </IconButton>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  )}
                </>
              )}
              {!!acceptedConsultantReviews?.length && !isPackage && (
                <div style={{ marginBottom: '16px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Consultant Comments:</Typography>
                    <div style={{ display: 'inline-flex', flexGrow: 100 }} />
                    <IconButton
                      onClick={() => {
                        setCommentsOpen((prev) => !prev);
                      }}
                      style={{ padding: 0 }}
                    >
                      {commentsOpen ? <Remove htmlColor="#0947B9" /> : <Add htmlColor="#0947B9" />}
                    </IconButton>
                  </div>
                  {commentsOpen && (
                    <Collapse in={commentsOpen}>
                      <List>
                        {consultantComments
                          ?.filter(({ id }) => !excludedComments.includes(id))
                          .map((comment) => (
                            <Comment
                              key={comment.id}
                              comment={comment}
                              deleteComment={() =>
                                setExcludedComments((prev) => [...prev, comment.id])
                              }
                            />
                          ))}
                        {consultantComments?.filter(({ id }) => !excludedComments.includes(id))
                          .length === 0 && (
                          <Typography style={{ marginLeft: '8px', fontWeight: 600 }}>
                            No comments to display.
                          </Typography>
                        )}
                      </List>
                    </Collapse>
                  )}
                </div>
              )}

              {!isRfi && !isWcpr && !isPackage && (
                <TextField
                  multiline
                  rows={2}
                  placeholder="Comments..."
                  value={comment}
                  onChange={(event) => setComment(event.target.value)}
                  style={{ marginBottom: 32 }}
                  InputProps={{ disableUnderline: true, style: { padding: '6px' } }}
                  className={classes.textfieldLarge}
                />
              )}
            </FormControl>
          );
        }
        if (mode === 'STUDIO')
          return (
            <>
              <Box className={classes.fileBox} style={{ width: '100%', marginBottom: 16 }}>
                <PDFIcon />
                <Typography className={classes.fileName}>
                  {latestFile?.name || 'No Primary File'}
                </Typography>
                <div style={{ display: 'inline-flex', flexGrow: 100 }} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={() => openInNewTab(latestFile!.id)}
                    disabled={!latestFile?.isVerified}
                    style={{ padding: 0, marginRight: 16 }}
                  >
                    <EyeIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => downloadFileById(latestFile!.id, latestFile!.name)}
                    disabled={!latestFile?.isVerified}
                    style={{ padding: 0, marginRight: 8 }}
                  >
                    <DownloadIcon disabled={!latestFile?.isVerified} />
                  </IconButton>
                </div>
              </Box>

              <Typography>
                To setup and share a Bluebeam Studio Session for this document, download it from the
                link above, add to Bluebeam Studio, and record the Session ID here.
              </Typography>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  variant="outlined"
                  margin="none"
                  size="small"
                  fullWidth
                  label="Bluebeam Studio Session ID"
                  value={studioLink}
                  disabled={!editStudioLink}
                  InputProps={{
                    inputComponent: StudioLinkTextMask,
                  }}
                  onChange={(e) => {
                    setStudioLink(removeNewlines(e.target.value));
                  }}
                  style={{ marginTop: 16, marginBottom: 16 }}
                />
                {!editStudioLink ? (
                  <TooltipIfDisabledComponent
                    arrow
                    placement="top"
                    title="You must have REVIEW permission to edit the Bluebeam Studio Session ID"
                    disabled={!canEditStudioId()}
                    maxWidth={40}
                  >
                    <IconButton
                      disabled={!canEditStudioId()}
                      onClick={() => setEditStudioLink(true)}
                    >
                      <Edit />
                    </IconButton>
                  </TooltipIfDisabledComponent>
                ) : (
                  <IconButton
                    onClick={() => {
                      setEditStudioLink(false);
                      setStudioLink(document.bluebeamStudioSessionId || undefined);
                    }}
                  >
                    <Close />
                  </IconButton>
                )}
              </div>
            </>
          );
        return null;

      default:
        return <></>;
    }
  };

  const getActions = () => {
    if (isTask) return null;

    switch (document.workflowStatus) {
      case WorkflowStatusType.ArchitectUploaded:
      case WorkflowStatusType.UnderReview:
        if (!mode) {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {previousConsultant() && (
                  <Typography style={{ paddingBottom: 8 }}>{`Previously assigned consultant: ${
                    previousConsultant()!.name
                  }`}</Typography>
                )}
                {canAssignConsultant() && (
                  <SubmitButton
                    variant="contained"
                    className={classes.greenAndBlueButtons}
                    style={{ marginBottom: 16 }}
                    onClick={() => {
                      setMode('CONSULTANT');
                    }}
                  >
                    Assign to Consultant
                  </SubmitButton>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.greenAndBlueButtons}
                  onClick={() => setMode('REVIEW')}
                >
                  {`REVIEW & ${
                    docType === DocumentTemplateType.DesignPackages ? 'DISTRIBUTE' : 'MARKUP'
                  }`}
                </Button>
                {docType && docTypesEnableStudioLink.includes(docType) ? (
                  <BluebeamButton
                    onClick={() => setMode('STUDIO')}
                    className={classes.greenAndBlueButtons}
                    style={{ marginTop: 16 }}
                  >
                    Bluebeam Studio Setup
                  </BluebeamButton>
                ) : null}
              </div>
            </>
          );
        }
        if (mode === 'CONSULTANT')
          return (
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingTop: 32 }}
            >
              {!isLoading ? (
                <>
                  <CancelButton
                    variant="outlined"
                    onClick={() => setMode(null)}
                    className={classes.cancelButton}
                  />
                  <div style={{ display: 'inline-flex', flexGrow: 100 }} />
                  <SubmitButton
                    variant="contained"
                    disabled={!inputConsultant || !selectedDate}
                    className={classes.mobileFont}
                    style={{ minWidth: '100px' }}
                    onClick={handleSubmitConsultant}
                  >
                    ASSIGN TO CONSULTANT
                  </SubmitButton>
                </>
              ) : (
                <CircularLoader fullWidth />
              )}
            </div>
          );

        if (mode === 'REVIEW')
          return (
            <>
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography style={{ fontWeight: 500, marginBottom: 8 }}>
                    {loadingMessage}
                  </Typography>
                  {loadingMessage.includes('Uploading') ? (
                    <>
                      <Typography>{uploadProgress}%</Typography>
                      <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                        style={{ height: 6, width: '100%', marginTop: 4 }}
                      />
                    </>
                  ) : (
                    <CircularLoader />
                  )}
                </div>
              ) : (
                <>
                  <CancelButton
                    variant="outlined"
                    onClick={() => setMode(null)}
                    className={classes.cancelButton}
                    style={
                      docType === DocumentTemplateType.DesignPackages
                        ? { padding: '0 8px' }
                        : undefined
                    }
                  />
                  <div style={{ display: 'inline-flex', flexGrow: 100 }} />
                  <Button
                    variant="contained"
                    disabled={getCompleteReviewDisabled()}
                    onClick={completeReview}
                    className={
                      docType === DocumentTemplateType.DesignPackages
                        ? classes.submitButtonSmall
                        : classes.submitButton
                    }
                  >
                    {getCompleteButtonText()}
                  </Button>
                </>
              )}
            </>
          );
        if (mode === 'STUDIO')
          return (
            <>
              <CancelButton
                variant="outlined"
                onClick={() => {
                  setMode(null);
                  setStudioLink(document.bluebeamStudioSessionId || undefined);
                }}
                className={classes.cancelButton}
              />
              <div style={{ display: 'inline-flex', flexGrow: 100 }} />
              <Button color="primary" variant="contained" onClick={handleEditStudioId}>
                Save Studio ID
              </Button>
            </>
          );
        return null;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (isRfi || !isWcpr) {
      if (!mode) {
        if (canAssignConsultant()) setTitle('Assign Consultant or Review');
        else if (isTask) setTitle('Respond to');
        else setTitle('Review');
      } else if (mode === 'CONSULTANT') setTitle('Assign Consultant');
      else if (mode === 'STUDIO') setTitle('Bluebeam Studio');
      else
        setTitle(
          `Review & ${docType === DocumentTemplateType.DesignPackages ? 'Distribute' : 'Markup'}`,
        );
    } else if (document.workflowStatus === WorkflowStatusType.Initial)
      setTitle('Import from Procore');
    else setTitle('Review');
  }, [document.workflowStatus, mode]);

  useEffect(() => {
    if (document.workflowStatus === WorkflowStatusType.ArchitectUploaded || completedReviewDocument)
      setMode('REVIEW');
  }, [document.workflowStatus, completedReviewDocument]);

  useEffect(() => {
    setActionTaken(completedReviewDocument?.recommendedAction || undefined);
  }, [completedReviewDocument]);

  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>
          {documentTitle ? `${title} ${documentTitle}` : title}
        </Typography>
        <div style={{ display: 'inline-flex', flexGrow: 100 }} />
        <IconButton
          onClick={!isLoading ? handleClose : undefined}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </div>
      <div className={classes.content}>{getContent()}</div>
      <div className={classes.actions}>{getActions()}</div>
      <FileUploadDialog
        open={uploadDialogOpen}
        handleClose={() => setUploadDialogOpen(false)}
        title="File Upload"
        addFile={(f) => addFile(f as File)}
        removeFile={() => setUploadedFile(undefined)}
        file={uploadedFile}
        canSubmit={uploadedFile !== undefined}
        disableComments
        disableDesignUpload
      />
      {bluebeamOpen ? (
        <BluebeamFlattenDialog
          dialogOpen={bluebeamOpen}
          handleClose={() => setBluebeamOpen(false)}
          documentId={document.id}
          handleMarkup={handleBluebeam}
          finish={handleBluebeamFinish}
          file={latestFile ?? undefined}
          studioSessionId={document.bluebeamStudioSessionId}
          disableMarkup={!latestFile?.isVerified}
        />
      ) : null}
      <RefusePackageDialog
        isOpen={packageDialogOpen}
        handleClose={() => setPackageDialogOpen(false)}
        packageDocuments={packageDocuments}
        initialState={getInitialState()}
        submit={getPackageReview}
      />
      <DocumentCommentDialog
        open={commentDialogOpen}
        handleClose={() => setCommentDialogOpen(false)}
        comment={completedReviewDocument?.comments?.slice().reverse()[0]}
      />
      <ManagePermissionsDialog
        dialogOpen={associationsDialogOpen}
        closeDialog={() => setAssociationsDialogOpen(false)}
        type={ManagePermissionsDialogType.AssociatedUsers}
        associatedUsers={associatedUsers}
        associatedGroups={associatedGroups}
        submitAssociatedUsersAndGroups={addNewUsersToNotificationList}
      />
      <CompileConsultantReviewDialog
        open={compileDialogOpen}
        onClose={() => setCompileDialogOpen(false)}
      />
    </div>
  );
}
