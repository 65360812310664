import React, { useEffect, useState } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { RequestForChangeData } from './DocumentIndexData';
import { Order } from './DocumentIndexUtils';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { getDocumentsType } from '../../features/documents/selectors';
import { DocumentTemplateType } from '../../api-client/autogenerated';
import { PunchListLocationRow } from './DocumentIndex';
import { formatDays, formatMoney } from '../../scripts/utils';

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headerText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      // LEX LOOK HERE. PERHAPS CHANGE lineHeight IF FIRST ELEMENT OF columnTitles IS SUBMITTAL #
      lineHeight: '22px',
      textTransform: 'uppercase',
    },
    disableDefaultSortIcon: {
      display: 'none',
    },
    sortIcon: {
      display: 'flex',
      flexDirection: 'column',
      height: '22px',
      paddingTop: '1px',
      marginLeft: '8px',
    },
    sortIconEnabled: {
      color: '#616061',
    },
    sortIconDisabled: {
      color: '#B2B1B2',
    },
  }),
);

export interface HeadCell<T> {
  id: keyof T & (number | string);
  label: string;
  width?: string;
  noSort?: boolean;
}

interface DocumentIndexHeaderProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  filteredRows: any[];
  columnTitles: HeadCell<any>[];
  isPlanholderList?: boolean;
  isUserTable?: boolean;
  hideCheckboxes?: boolean;
}

const DocumentIndexHeader = (props: DocumentIndexHeaderProps) => {
  const classes = useStyles();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    filteredRows,
    onRequestSort,
    columnTitles,
    isPlanholderList = false,
    isUserTable = false,
    hideCheckboxes = false,
  } = props;

  const selectedProject = useSelector(getProjectState);
  const docType = useSelector(getDocumentsType);

  const [totalCost, setTotalCost] = useState(0);
  const [totalTime, setTotalTime] = useState(0);

  useEffect(() => {
    if (docType === DocumentTemplateType.PunchList) {
      const filteredItems = (filteredRows as Omit<PunchListLocationRow, 'locationObject'>[])
        .map((item) => item.children)
        .flat();
      const cost = filteredItems.reduce<number>((prev, cur) => prev + cur?.cost || 0, 0);
      setTotalCost(cost);
    } else if (
      docType === DocumentTemplateType.RequestsForChange ||
      docType === DocumentTemplateType.PotentialChangeOrders
    ) {
      const itemsCost = (filteredRows as RequestForChangeData[]).map((item) => item.cost ?? 0);
      const cost = itemsCost.reduce((prev, cur) => prev + cur, 0);
      setTotalCost(cost);

      const itemsTime = (filteredRows as RequestForChangeData[]).map((item) => item.time ?? 0);
      const time = itemsTime.reduce((prev, cur) => prev + cur, 0);
      setTotalTime(time);
    }
  }, [filteredRows]);

  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const getWidthConstraint = (index: number) => {
    if (index === 2) {
      return 140;
    }
    if (index === 3) {
      return 120;
    }
    if (index === 7) {
      return 100;
    }
    return undefined;
  };

  const showCostValue = (cell: HeadCell<any>) => {
    return (
      !!docType &&
      [
        DocumentTemplateType.PunchList,
        DocumentTemplateType.RequestsForChange,
        DocumentTemplateType.PotentialChangeOrders,
      ].includes(docType) &&
      cell.id === 'cost' &&
      filteredRows.length > 0
    );
  };

  const showTimeValue = (cell: HeadCell<any>) => {
    return (
      !!docType &&
      [DocumentTemplateType.RequestsForChange, DocumentTemplateType.PotentialChangeOrders].includes(
        docType,
      ) &&
      cell.id === 'time' &&
      filteredRows.length > 0
    );
  };

  const shouldNotWrap = (cell: HeadCell<any>) => {
    return cell.label.includes('#') || showCostValue(cell);
  };

  const isSubmittal = columnTitles[0] ? columnTitles[0].label === 'Submittal #' : false;
  const isPunchList = docType === DocumentTemplateType.PunchList;

  return (
    <TableHead>
      <TableRow style={{ borderTop: '1px solid #EDECEC', height: isSubmittal ? 70 : undefined }}>
        {hideCheckboxes || isPlanholderList ? null : (
          <TableCell padding="checkbox" style={{ border: 'none', width: '1%' }}>
            <GreenCheck
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={
                isUserTable
                  ? rowCount > 1 && numSelected === rowCount - 1
                  : rowCount > 0 && numSelected === rowCount
              }
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columnTitles.map((headCell, index) => {
          if (!selectedProject?.isProcoreIntegrationEnabled && headCell.id === 'sync') return <></>;
          if (headCell.label === 'HIDE') return <></>;
          return (
            <TableCell
              key={`${headCell.id}-${headCell.label}`}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                border: 'none',
                paddingLeft: isPunchList && index === 0 ? 64 : undefined,
                paddingRight: isSubmittal && (index === 0 || index === 1) ? 16 : undefined,
                width: headCell.width ?? '100%',
                whiteSpace: shouldNotWrap(headCell) ? 'nowrap' : 'normal',
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                classes={{
                  root: classes.headerText,
                  icon: classes.disableDefaultSortIcon,
                }}
                // Using inline style here because other approaches won't overwrite color
                style={{
                  color: '#616061',
                  minWidth: isSubmittal ? getWidthConstraint(index) : undefined,
                }}
              >
                {headCell.label}
                {showCostValue(headCell) && (
                  <strong style={{ color: '#464546' }}>: {formatMoney(totalCost)}</strong>
                )}
                {showTimeValue(headCell) && (
                  <strong style={{ color: '#464546' }}>: {formatDays(totalTime)}</strong>
                )}
                {headCell.label !== '' && !headCell.noSort ? (
                  <div className={classes.sortIcon}>
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      style={{ marginBottom: '-3px' }}
                      className={
                        orderBy === headCell.id ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ marginTop: '-3px' }}
                      className={
                        orderBy === headCell.id ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                  </div>
                ) : null}

                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default DocumentIndexHeader;
