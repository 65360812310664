import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { useHistory } from 'react-router-dom';
import {
  CancelOutlined,
  CheckCircleOutlined,
  CloudDownloadOutlined,
  LockOutlined,
  RateReviewOutlined,
  RestoreFromTrash,
  RotateLeft,
  ThumbDownOutlined,
  ThumbUpOutlined,
  Update,
  VisibilityOutlined,
} from '@material-ui/icons';
import DraftsIcon from '../icons/Drafts-icon';
import SubmitIcon from '../icons/Submit-icon';
import NameCompanyText from '../lists/NameCompanyText';
import SecondaryDateText from '../lists/SecondaryDateText';
import MessageText from '../lists/MessageText';
import {
  DocumentTemplateType,
  INotification,
  INumberedDocumentView,
  NotificationType,
} from '../../api-client/autogenerated';
import { getTemplateIds, getTemplateName } from '../../models/api/templates';
import {
  documentIsOverdue,
  documentTypeToUrl,
  getPageTitle,
  getSubmittalTitle,
  parseDate,
} from '../../scripts/utils';
import { Dictionary } from 'lodash';
import CalendarIcon from '../icons/Calendar-icon';
import { useDispatch } from 'react-redux';
import path from 'path';
import { addSnackbar } from '../../features/snackbar/actions';
import SubjectText from '../lists/SubjectText';
import dayjs from 'dayjs';
import DeleteIcon from '@material-ui/icons/Delete';

interface DashboardNotificationsItemProps {
  userNotificationId?: string;
  notification: INotification;
  handleDelete?: (id: string) => void;
  projectName?: string;
}

const primaryColor = '#0947B9'; // blue
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activityStatus: {
      fontWeight: 'bold',
      color: primaryColor,
    },
    message: {
      color: '#464546', // gray 700
    },
    shortDate: {
      marginRight: theme.spacing(2),
      marginLeft: 0,
    },
    iconText: {
      color: '#000', // black
      paddingLeft: 0,
    },
    blueIcon: {
      color: '#4380ED', // Blue 500
      fill: '#4380ED',
    },
    iconPadding: {
      padding: 0,
    },
  }),
);

const notificationTypesNoBy = [
  NotificationType.DocumentDueDateWarning,
  NotificationType.DocumentSubmittedForReviewReminder,
  NotificationType.DocumentCommentAdded,
  NotificationType.ProjectComment,
  NotificationType.ProjectMassDocumentPublish,
  NotificationType.ProjectUserInvitedExisting,
  NotificationType.ProjectUserInvited,
  NotificationType.UserInvited,
  NotificationType.ProjectSpecificationsParserCompleted,
  NotificationType.ProjectSpecificationsParserFailed,
  NotificationType.ProjectDrawingsParserCompleted,
  NotificationType.ProjectDrawingsParserFailed,
];

export default function DashboardNotificationsItem(props: DashboardNotificationsItemProps) {
  const classes = useStyles();
  const { notification, userNotificationId, handleDelete, projectName } = props;
  const designFile = notification.designFile || notification.designFileShare?.designFile;
  const history = useHistory();
  const dispatch = useDispatch();

  const [subject, setSubject] = useState<string>();
  const [message, setMessage] = useState<string>();

  const [templates, setTemplates] = useState<undefined | Dictionary<string>>(undefined);

  useEffect(() => {
    getTemplateIds().then((res) => {
      setTemplates(res);
    });
  }, []);

  const getUniqueDocumentIdentifier = async (
    doc: INumberedDocumentView,
    template: DocumentTemplateType,
  ): Promise<string> => {
    if (doc.additionalReviewForDocument) {
      return getPageTitle(
        await getTemplateName(doc.additionalReviewForDocument.documentTemplateId),
        doc.additionalReviewForDocument,
      );
    }
    return getPageTitle(template, doc);
  };

  const getIcon = () => {
    switch (notification?.format) {
      case NotificationType.AnnouncementLive:
        return <PlayCircleOutlineOutlinedIcon className={classes.blueIcon} />;

      case NotificationType.DocumentAddedInitialSubmittalDate:
      case NotificationType.DocumentAnticipatedSubmissionDateChanged:
      case NotificationType.DocumentDueDateChanged:
      case NotificationType.DocumentSubmittedForReviewReminder:
      case NotificationType.DocumentDueDateWarning:
        return <CalendarIcon fill="#4380ED" />;

      case NotificationType.DocumentCommentAdded:
      case NotificationType.ProjectComment:
        return <ChatOutlinedIcon className={classes.blueIcon} />;

      case NotificationType.DocumentSubmittedForInformationOnly:
      case NotificationType.DocumentComplete:
      case NotificationType.DocumentConsultantFinished:
        return <CheckBoxOutlinedIcon className={classes.blueIcon} />;

      case NotificationType.DocumentConsultantAdded:
      case NotificationType.DocumentSubcontractorChanged:
      case NotificationType.ProjectUserInvited:
      case NotificationType.ProjectUserInvitedExisting:
      case NotificationType.UserInvited:
      case NotificationType.DocumentFollowersUpdated:
        return <PersonAddOutlinedIcon className={classes.blueIcon} />;

      case NotificationType.DocumentConsultantUploaded:
      case NotificationType.DocumentCreated:
      case NotificationType.DocumentFileCreated:
      case NotificationType.DocumentPublished:
      case NotificationType.ProjectMassDocumentPublish:
      case NotificationType.ProjectBidSetupFileCreated:
      case NotificationType.ProjectFileCreated:
        return <AddBoxOutlinedIcon className={classes.blueIcon} />;

      case NotificationType.DocumentDeleted:
        return <DeleteIcon />;

      case NotificationType.DocumentRestored:
        return <RestoreFromTrash />;

      case NotificationType.DocumentDocumentRemovedFromPackage:
      case NotificationType.DocumentFileDownloaded:
      case NotificationType.DocumentFileOpenedBluebeam:
      case NotificationType.DocumentSeparatedFromPackage:
      case NotificationType.DocumentAddedToPackage:
        return <DraftsIcon />;

      case NotificationType.DocumentUpdated:
        return <Update className={classes.blueIcon} />;

      case NotificationType.DocumentSubcontractorUploadAccepted:
        return <ThumbUpOutlined className={classes.blueIcon} />;

      case NotificationType.DocumentSubcontractorUploaded:
      case NotificationType.ProjectCompletedBidTabulationAdded:
        return <SubmitIcon />;

      case NotificationType.DocumentSubcontractorUploadRejected:
        return <ThumbDownOutlined className={classes.blueIcon} />;

      case NotificationType.DocumentSubmittedForReview:
        return <RateReviewOutlined className={classes.blueIcon} />;

      case NotificationType.DocumentViewed:
      case NotificationType.ProjectBidSetupViewed:
        return <VisibilityOutlined className={classes.blueIcon} />;

      case NotificationType.ProjectBidSetupDownloaded:
        return <CloudDownloadOutlined className={classes.blueIcon} />;

      case NotificationType.DocumentFileLocked:
        return <LockOutlined className={classes.blueIcon} />;

      case NotificationType.ProjectSpecificationsParserCompleted:
      case NotificationType.ProjectDrawingsParserCompleted:
        return <CheckCircleOutlined className={classes.blueIcon} />;

      case NotificationType.ProjectSpecificationsParserFailed:
      case NotificationType.ProjectDrawingsParserFailed:
        return <CancelOutlined className={classes.blueIcon} />;

      case NotificationType.DocumentUndoCompleteReview:
        return <RotateLeft className={classes.blueIcon} />;
    }
  };

  const getSubject = async () => {
    const { format, document, project } = notification;
    const template = document?.documentTemplateId
      ? await getTemplateName(document.documentTemplateId)
      : '';

    if (format === NotificationType.ProjectCompletedBidTabulationAdded)
      setSubject('Completed Bid Tabulation');
    else if (
      format === NotificationType.ProjectBidSetupViewed ||
      format === NotificationType.ProjectBidSetupDownloaded
    )
      setSubject(project?.name);
    else if (document) {
      if (template) {
        setSubject(await getUniqueDocumentIdentifier(document, template));
      }
    } else if (designFile) setSubject(path.basename(designFile.name));
  };

  const getMessage = async () => {
    const { document, targetUser, targetDocument, file } = notification;
    const template = document?.documentTemplateId
      ? await getTemplateName(document.documentTemplateId)
      : '';
    let tempMessage = '';
    switch (notification.format) {
      case NotificationType.ProjectComment:
        tempMessage += 'New message from ';
        break;

      case NotificationType.ProjectMassDocumentPublish:
        tempMessage += `${
          JSON.parse(notification.genericText ?? '[]').length
        } Documents published `;
        break;

      case NotificationType.DocumentPublished:
        tempMessage += 'Published ';
        break;

      case NotificationType.DocumentCreated:
        tempMessage += 'Created ';
        break;

      case NotificationType.DocumentDeleted:
        tempMessage += 'Deleted ';
        break;

      case NotificationType.DocumentRestored:
        tempMessage += 'Restored ';
        break;

      case NotificationType.DocumentSubcontractorChanged:
        tempMessage += `assigned ${targetUser ? targetUser.name : 'a new subcontractor'} to `;
        break;

      case NotificationType.DocumentConsultantAdded:
        tempMessage += `Consultant Review initiated `;
        break;

      case NotificationType.DocumentConsultantUploaded:
        tempMessage += `Review file ${file ? `"${file.name}"` : ''} uploaded `;
        break;

      case NotificationType.DocumentCommentAdded:
        tempMessage += `New comment from `;
        break;

      case NotificationType.DocumentSubcontractorUploaded:
        tempMessage += `uploaded ${file ? `${file.name}` : 'a file'} to `;
        break;

      case NotificationType.DocumentSubmittedForReview:
        if (template !== DocumentTemplateType.AdditionalReview) tempMessage += `Submitted `;
        else if (!template) tempMessage += 'a document ';
        else tempMessage += 'Review requested ';
        break;

      case NotificationType.DocumentFileDownloaded:
        if (file) tempMessage += `"${file.name}" downloaded `;
        else tempMessage += 'File downloaded ';
        break;

      case NotificationType.DocumentFileCreated:
        if (file) tempMessage += `"${file.name}" uploaded `;
        else tempMessage += 'File uploaded ';
        break;

      case NotificationType.DocumentFileLocked:
        tempMessage += 'File uploads locked ';
        break;

      case NotificationType.DocumentSubcontractorUploadRejected:
        tempMessage += `rejected ${targetUser ? `${targetUser.name}'s` : 'an'} upload on `;
        break;

      case NotificationType.DocumentSubcontractorUploadAccepted:
        tempMessage += `accepted ${targetUser ? `${targetUser.name}'s` : 'an'} upload on `;
        break;

      case NotificationType.DocumentConsultantFinished:
        tempMessage += `Review finished `;
        break;

      case NotificationType.DocumentSubmittedForInformationOnly:
        tempMessage += `Submitted for information only `;
        break;

      case NotificationType.DocumentComplete:
        tempMessage += `Completed `;
        break;

      case NotificationType.DocumentViewed:
        tempMessage += 'Viewed ';
        break;

      case NotificationType.DocumentUpdated:
        tempMessage += 'Updated ';
        break;

      case NotificationType.AnnouncementLive:
        tempMessage += 'Announcement made ';
        break;

      case NotificationType.DocumentSeparatedFromPackage:
        tempMessage += `Removed from ${
          targetDocument ? `Submittal Package ${targetDocument.referenceNumber}` : 'package'
        } `;
        break;

      case NotificationType.DocumentDocumentRemovedFromPackage:
        tempMessage += `${
          targetDocument ? getSubmittalTitle(targetDocument) : 'a document'
        } removed `;
        break;

      case NotificationType.DocumentAddedToPackage:
        tempMessage += `Added to ${
          targetDocument ? `Submittal Package ${targetDocument.referenceNumber}` : 'package'
        } `;
        break;

      case NotificationType.ProjectBidSetupDownloaded:
        tempMessage += 'Bid Setup downloaded ';
        break;

      case NotificationType.ProjectBidSetupViewed:
        tempMessage += 'Bid Setup viewed ';
        break;

      case NotificationType.ProjectBidSubmitted:
        tempMessage += 'Bid submitted ';
        break;

      case NotificationType.ProjectBidRetracted:
        tempMessage += 'Bid retracted ';
        break;

      case NotificationType.ProjectUserUnsubscribed:
        tempMessage += 'Unsubscribed from the project bid ';
        break;

      case NotificationType.ProjectUserInvited:
      case NotificationType.UserInvited:
        tempMessage += ' invited a user';
        break;

      case NotificationType.ProjectCompletedBidTabulationAdded:
        tempMessage += 'Uploaded';
        break;

      case NotificationType.ProjectSpecificationsParserCompleted:
        tempMessage += 'Specifications parser completed ';
        break;

      case NotificationType.ProjectSpecificationsParserFailed:
        tempMessage += 'Specifications parser failed ';
        break;

      case NotificationType.ProjectDrawingsParserCompleted:
        tempMessage += 'Drawings parser completed ';
        break;

      case NotificationType.ProjectDrawingsParserFailed:
        tempMessage += 'Drawings parser failed ';
        break;

      case NotificationType.DocumentUndoCompleteReview:
        tempMessage += 'Reopened for revision ';
        break;

      case NotificationType.DocumentSubmittedForReviewReminder:
        tempMessage += 'Submitted for review and due soon ';
        break;

      case NotificationType.DocumentDueDateWarning:
        if (document) {
          const isSubmittal =
            template === DocumentTemplateType.Submittals ||
            template === DocumentTemplateType.SubmittalPackages;
          const isOverdue = documentIsOverdue(document, isSubmittal);
          tempMessage += isOverdue ? 'Overdue' : 'Due soon';
        } else tempMessage += 'Due soon';
        break;

      case NotificationType.DocumentFollowersUpdated:
        tempMessage += 'Notification list updated ';
        break;

      default:
        break;
    }

    switch (notification.format) {
      case NotificationType.DocumentSubmittedForReview:
        if (template !== DocumentTemplateType.AdditionalReview) tempMessage += ' for review';
        break;
      case NotificationType.DocumentDueDateChanged:
        tempMessage += ' Due date changed';
        if (notification.genericDate)
          tempMessage += ` to ${parseDate(notification.genericDate).format('M/DD/YYYY')}`;
        break;
      case NotificationType.DocumentAnticipatedSubmissionDateChanged:
        tempMessage += ' Anticipated submission date changed';
        if (notification.genericDate)
          tempMessage += ` to ${parseDate(notification.genericDate).format('M/DD/YYYY')}`;
        break;
      case NotificationType.DocumentAddedInitialSubmittalDate:
        tempMessage += ' Has new initial submittal date';
        if (notification.genericDate)
          tempMessage += ` ${parseDate(notification.genericDate).format('M/DD/YYYY')}`;
        break;
      default:
        break;
    }

    if (!notificationTypesNoBy.includes(notification.format)) tempMessage += ' by';

    setMessage(tempMessage);
  };

  const handleClick = () => {
    if (notification.document) {
      if (notification.document.isHidden) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            severity: 'warning',
            message: 'This document has been hidden',
          }),
        );
        return;
      }
      if (!notification.document.additionalReviewForDocumentId) {
        history.push(
          `/main/projects/${notification.document.projectId}/documents/${
            documentTypeToUrl[
              templates![notification.document!.documentTemplateId!] as DocumentTemplateType
            ]
          }/${notification.document.id}${
            notification.commentId ? `/comments/${notification.commentId}` : ''
          }`,
        );
      } else {
        history.push(
          `/main/projects/${notification.document.projectId}/documents/${
            documentTypeToUrl[
              templates![
                notification.document.additionalReviewForDocument!.documentTemplateId
              ] as DocumentTemplateType
            ]
          }/${notification.document.additionalReviewForDocumentId}`,
        );
      }
    } else if (designFile?.url) {
      history.push(
        `/main/projects/${designFile.projectId}/design?fullKey=${designFile.url
          .split('/fs')
          .pop()}`,
      );
    } else if (notification.projectId) history.push(`/main/projects/${notification.projectId}`);
  };

  useEffect(() => {
    getMessage();
    getSubject();
  }, []);

  if (
    message?.includes('undefined') ||
    message?.includes('null') ||
    subject?.includes('undefined') ||
    subject?.includes('null')
  )
    return <></>;

  return (
    <ListItem button onClick={handleClick} alignItems="flex-start">
      <ListItemIcon style={{ marginTop: 8 }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item xs={11}>
              {getIcon()}
            </Grid>
          </Grid>
        </div>
      </ListItemIcon>
      <ListItemText
        primary={
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid
              item
              xs={11}
              style={{ margin: '8px 0px 0px 0px', display: 'flex', flexDirection: 'column' }}
            >
              <SubjectText message={subject} />
              <MessageText message={message} />
              {notification.initiatorUser
                ? notification.initiatorUser.name &&
                  notification.initiatorUser.company && (
                    <NameCompanyText
                      name={notification.initiatorUser.name}
                      company={notification.initiatorUser.company.name}
                    />
                  )
                : null}
              {projectName && (
                <div style={{ display: 'flex' }}>
                  <MessageText
                    message={
                      notification.format === NotificationType.ProjectUserInvitedExisting ||
                      notification.format === NotificationType.ProjectUserInvited ||
                      notification.format === NotificationType.UserInvited
                        ? 'added you to'
                        : 'in'
                    }
                    style={{ whiteSpace: 'nowrap' }}
                  />
                  <span
                    style={{
                      color: '#0947B9',
                      fontWeight: 500,
                      whiteSpace: 'pre',
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {' '}
                    {projectName}
                  </span>
                </div>
              )}
            </Grid>
            <Grid item xs>
              {handleDelete && (
                <IconButton
                  edge="end"
                  aria-label="cancel"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (userNotificationId != undefined) {
                      handleDelete(userNotificationId);
                    }
                  }}
                  className={classes.iconPadding}
                >
                  <CancelOutlinedIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        }
        secondary={
          <Typography align="right">
            <SecondaryDateText
              date={notification.createdOn!}
              today={parseDate(notification.createdOn!).isSame(dayjs(), 'day')}
            />
          </Typography>
        }
        style={{ margin: 0 }}
      />
    </ListItem>
  );
}
