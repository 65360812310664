import React from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { ArrowForward, Close, HighlightOffRounded } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { isEdge, isFirefox } from 'react-device-detect';
import chromeHelp from '../../images/download-chrome.png';
import firefoxHelp from '../../images/download-firefox.png';
import edgeHelp from '../../images/download-edge.png';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '80px',
    padding: '16px 24px 24px 24px',
  },
  title: {
    maxWidth: '450px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
  },
  rootIconButton: {
    position: 'absolute',
    right: '22px',
    top: '19px',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonContainer: {
    padding: '0px 24px 24px 24px',
    flexDirection: 'column',
  },
  textfield: {
    height: '32px',

    /* Gray / Gray 50 */
    background: '#F9F9F9',

    /* Gray / Gray 400 Brand dark */
    border: '1px solid #949494',
    borderRadius: '5px',
  },
  button: {
    height: '32px',
    borderWidth: '2px',
    boxShadow: 'none',
  },
});

interface Props {
  open: boolean;
  handleContinue: () => void;
  handleCancel: () => void;
  filename?: string;
}

export default function BluebeamLaunchDialog(props: Props) {
  const classes = useStyles();
  const { open, handleContinue, handleCancel, filename } = props;

  const getInstructions = () => {
    if (isFirefox) {
      return firefoxHelp;
    }

    if (isEdge) {
      return edgeHelp;
    }

    return chromeHelp;
  };

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.titleContainer} style={{ height: 'auto', paddingBottom: 0 }}>
        <Typography className={classes.title}>Bluebeam Auto Launch?</Typography>
        <IconButton
          onClick={handleCancel}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography style={{ marginBottom: 16 }}>
          If Bluebeam does not automatically launch, double click{' '}
          <strong>
            {filename ? `${filename.substring(0, filename.indexOf('.'))}.bfx` : 'the BFX file'}
          </strong>{' '}
          in your downloads folder. For instructions to change your browser settings so Bluebeam
          automatically launches, see below.
        </Typography>
        <img src={getInstructions()} alt="download help" />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event, checked) => {
                if (checked) {
                  localStorage.setItem('skipBluebeamHelp', 'true');
                } else {
                  localStorage.removeItem('skipBluebeamHelp');
                }
              }}
            />
          }
          label="Don't show this again"
        />
      </DialogContent>
      <DialogActions
        className={classes.buttonContainer}
        style={{ justifyContent: 'space-between', flexDirection: 'row' }}
      >
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleCancel}
          startIcon={<Close htmlColor="#7A797A" fontSize="small" />}
          style={{ color: '#7A797A', borderColor: '#949494' }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForward />}
          onClick={handleContinue}
          className={classes.button}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
