import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  getFilteredUsersFromUserGroup,
  isPublicPage,
  sortLastNamesDesc,
} from '../../scripts/utils';
import Add from '@material-ui/icons/Add';
import CircularLoader from '../loader/CircularLoader';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import {
  IDocumentUser,
  IDocumentUserGroup,
  INumberedDocumentView,
  IUser,
} from '../../api-client/autogenerated';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentState } from '../../features/document/selectors';
import { modifyDocumentById } from '../../models/api/documents';
import { fetchDocument, setDocument } from '../../features/document/actions';
import { Remove } from '@material-ui/icons';
import { Collapse, Table, TableBody } from '@material-ui/core';
import ManagePermissionsDialog, {
  getUsersFromUserGroup,
  ManagePermissionsDialogType,
  PermissionActionType,
  PermissionsAndAction,
} from '../design/ManagePermissionsDialog';
import { SmallButton } from '../custom-components/CustomButtons';
import { getDocPermission } from '../../scripts/store-utils';
import PermissionsDialogRow from '../design/PermissionsDialogRow';
import { PermissionTagType } from '../design/PermissionTag';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import { getDocumentsType } from '../../features/documents/selectors';

type Props = {
  title?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      zIndex: -1,
    },
    content: {
      display: 'flex',
      // flexDirection: 'column',
      flexBasis: 'auto',
      width: 'auto',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    padding: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 14,
      paddingBottom: 14,
    },
    centerIcon: {
      display: 'flex',
      alignItems: 'center',
      flexBasis: 'auto',
      flexWrap: 'nowrap',
      height: '24px',
      justifyContent: 'flex-end',
    },
    icon: {
      color: '#7A797A',
      cursor: 'pointer',
    },
  }),
);

export default function Associations(props: Props) {
  const classes = useStyles();
  const { title } = props;
  const dispatch = useDispatch();
  const document = useSelector(getDocumentState);
  const docType = useSelector(getDocumentsType);
  const [collapseOpen, setCollapseOpen] = useState(true);
  const [addFollowerDialogOpen, setOpenAddFollowerDialog] = useState(false);
  const [loadingNotificationList, setLoadingNotificationList] = useState(false);

  const [associatedGroups, setAssociatedGroups] = useState<IDocumentUserGroup[] | undefined>([]);
  const [associatedUsers, setAssociatedUsers] = useState<IDocumentUser[] | undefined>([]);

  useEffect(() => {
    setAssociatedGroups(document?.documentUserGroupList);
    setAssociatedUsers(document?.documentUserList);
  }, [document]);

  const handleAddFollowerDialogOpen = () => {
    setOpenAddFollowerDialog(true);
  };

  const handleAddFollowerDialogClose = () => {
    setOpenAddFollowerDialog(false);
  };

  const handleDeletePermission = (permission: PermissionsAndAction) => {
    if (permission.group && document && document.documentUserGroupList) {
      const documentTemp = { ...document };
      const index = documentTemp.documentUserGroupList!.findIndex(
        (x) => x.userGroupId === permission.group?.id,
      );
      documentTemp.documentUserGroupList!.splice(index, 1);
      dispatch(setDocument(documentTemp));
      void modifyDocumentById(document.id, {
        followers: { removeUserGroupIds: [permission.group.id] },
      });
    } else if (permission.userId && document && document.documentUserList) {
      const documentTemp = { ...document };
      const index = documentTemp.documentUserList!.findIndex((x) => x.userId === permission.userId);
      documentTemp.documentUserList!.splice(index, 1);
      dispatch(setDocument(documentTemp));
      void modifyDocumentById(document.id, { followers: { removeUserIds: [permission.userId] } });
    }
  };

  const addNewUsersToNotificationList = async (
    userIds: string[],
    associatedGroupIds: string[],
    associatedDocumentIds: string[],
  ) => {
    try {
      setLoadingNotificationList(true);

      const addGroupIds = associatedGroupIds.filter(
        (id) => !document!.documentUserGroupList!.some(({ userGroupId }) => userGroupId === id),
      );
      const removeGroupIds = document!
        .documentUserGroupList!.filter(
          ({ userGroupId }) => !associatedGroupIds.some((id) => id === userGroupId),
        )
        .map(({ userGroupId }) => userGroupId);

      const removeUserIds = document!
        .documentUserList!.filter(({ userId }) => !userIds.some((id) => id === userId))
        .map(({ userId }) => userId);

      await modifyDocumentById(document!.id, {
        followers: {
          addUserIds: userIds,
          removeUserIds,
          addUserGroupIds: addGroupIds,
          removeUserGroupIds: removeGroupIds,
        },
      });

      dispatch(fetchDocument(document!.id));
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoadingNotificationList(false);
    }
  };

  const getUsersFromDocument = (document?: INumberedDocumentView | null) => {
    const users = [] as IUser[];
    if (!document) return users;
    document.documentUserList?.forEach(({ user }) => {
      if (user) users.push(user);
    });
    document.documentUserGroupList?.forEach(({ userGroup }) => {
      users.push(...getUsersFromUserGroup(userGroup));
    });
    return _.uniqBy(users, (user) => user.id);
  };

  const hasEditPermission = () => {
    if (document?.isHidden) return false;
    const permission = getDocPermission();
    return permission && permission >= 2;
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <h2
          style={{
            marginBottom: 10,
            lineHeight: '24px',
          }}
          className="h2"
        >
          {title
            ? `${title} Notification List`
            : `${document?.referenceNumber}${
                document?.revisionNumber ? `-R${document?.revisionNumber}` : ''
              } Notification List`}
        </h2>

        {!isPublicPage() && hasEditPermission() && (
          <SmallButton
            variant="outlined"
            color="primary"
            style={{ marginLeft: '36px', marginRight: '4px', width: 100 }}
            onClick={handleAddFollowerDialogOpen}
          >
            Edit
          </SmallButton>
        )}
        <div style={{ display: 'inline-flex', flexGrow: 100 }} />
        <IconButton style={{ padding: 0 }}>
          {collapseOpen ? (
            <Remove htmlColor="#0947B9" onClick={() => setCollapseOpen(false)} />
          ) : (
            <Add htmlColor="#0947B9" onClick={() => setCollapseOpen(true)} />
          )}
        </IconButton>
      </CardContent>
      <Collapse in={collapseOpen}>
        {loadingNotificationList ? (
          <CircularLoader />
        ) : (
          <Table size="small" padding="none" stickyHeader>
            <TableBody>
              {associatedGroups
                ?.sort((a, b) => ascendingComparator(a, b, 'userGroup', true))
                .map((group, index) => (
                  <PermissionsDialogRow
                    key={group.id}
                    permissionRow={{
                      action: PermissionActionType.None,
                      read: true,
                      write: true,
                      admin: true,
                      group: group.userGroup!,
                    }}
                    index={index}
                    users={getFilteredUsersFromUserGroup(
                      ManagePermissionsDialogType.AssociatedUsers,
                      group.userGroup!,
                    )}
                    type={PermissionTagType.Group}
                    label={group.userGroup?.name || ''}
                    defaultOpen
                    hidePermissions
                    dialogType={ManagePermissionsDialogType.AssociatedUsers}
                    handleEditPermission={() => {}}
                    handleDeletePermission={handleDeletePermission}
                    isDocumentDetail
                  />
                ))}
              {associatedUsers
                ?.sort((a, b) => sortLastNamesDesc(a.user?.name, b.user?.name))
                .map((user, index) => (
                  <PermissionsDialogRow
                    key={user.id}
                    permissionRow={{
                      action: PermissionActionType.None,
                      read: true,
                      write: true,
                      admin: true,
                      userId: user.user?.id,
                    }}
                    index={index}
                    users={[]}
                    type={PermissionTagType.Person}
                    label={user.user?.name || ''}
                    defaultOpen
                    hidePermissions
                    dialogType={ManagePermissionsDialogType.AssociatedUsers}
                    handleEditPermission={() => {}}
                    handleDeletePermission={handleDeletePermission}
                    isDocumentDetail
                  />
                ))}
            </TableBody>
          </Table>
        )}
      </Collapse>
      <ManagePermissionsDialog
        dialogOpen={addFollowerDialogOpen}
        closeDialog={handleAddFollowerDialogClose}
        type={ManagePermissionsDialogType.AssociatedUsers}
        associatedUsers={
          document?.documentUserList ? document.documentUserList.map((x) => x.user!.id) : []
        }
        associatedGroups={
          document?.documentUserGroupList?.map(({ userGroupId }) => userGroupId) || []
        }
        submitAssociatedUsersAndGroups={addNewUsersToNotificationList}
      />
    </Card>
  );
}
