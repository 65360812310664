import React, { useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import Typography from '@material-ui/core/Typography';
import { Button, MenuItem, Select, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {
  ActionTakenType,
  DocumentPriorityType,
  DocumentTemplateType,
  IFile,
  IUserNotification,
  PunchListStatusType,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import {
  ascendingComparator,
  descendingComparator,
  statusToGeneralString,
} from './DocumentIndexUtils';
import {
  downloadFileById,
  fileIsPdf,
  formatDate,
  formatDays,
  formatMoney,
  generateUniqueId,
  getActionTakenText,
  getUserFriendlyDocumentPriority,
  getUserFriendlyTaskStatusFromWorkflowStatus,
  openInNewTab,
  parseDate,
} from '../../scripts/utils';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSelector } from 'react-redux';
import {
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getPhysicalLocationsState,
  getProjectState,
} from '../../features/project/selectors';
import { Delete, Description, ExpandLess, ExpandMore, Info } from '@material-ui/icons';
import AssignedUserText from '../custom-components/AssignedUserText';
import { Assigned, Responsibility } from './DocumentIndexData';
import { getDocumentsState, getDocumentsType } from '../../features/documents/selectors';
import { useHistory } from 'react-router-dom';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Chip from '@material-ui/core/Chip';
import UserFilterListDialog from '../dialogs/UserFilterListDialog';
import { emitter, Events } from '../../scripts/events';
import DownloadIcon from '../icons/Download-icon';
import CircularLoader from '../loader/CircularLoader';
import pluralize from 'pluralize';
import AssociatedDocumentsDialog from '../dialogs/AssociatedDocumentsDialog';

const useStyles = makeStyles({
  cellCheckbox: {
    maxWidth: '30px',
    border: 'none',
  },
  cellNumber: {
    border: 'none',
    // minWidth: 200,
  },
  cellNumberNoWrap: {
    border: 'none',
    whiteSpace: 'nowrap',
    // minWidth: 200,
  },
  numberText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '22px',
    color: '#464546',
    cursor: 'pointer',
  },
  cellTitle: {
    // maxWidth: '20%',
    border: 'none',
    // whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
    color: '#464546',
  },
  cellDescription: {
    minWidth: 220,
    border: 'none',
    whiteSpace: 'normal',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
    color: '#464546',
  },
  cellIssueDate: {
    // maxWidth: '11%',
    border: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    color: '#464546',
  },
  cellStatus: {
    // maxWidth: '15%',
    border: 'none',
    // whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
    color: '#464546',
  },
  cellStatusBold: {
    // maxWidth: '15%',
    border: 'none',
    // whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
  },
  tableRow: {
    transition: '0.1s',
    '&:hover': {
      background: '#E5EEFE !important',
    },
  },
});

interface DocumentIndexRowProps {
  row: any;
  index: number;
  packageItems?: any[];
  isSubRow?: boolean;
  isPlanholderList?: boolean;
  handleClick?: (event: React.MouseEvent<unknown>, id: string) => void;
  isSelected?: (name: string) => boolean;
  isIndeterminate?: boolean;
  onSelect: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    id: string,
    file?: IFile,
    company?: string,
    name?: string,
  ) => void;
  isHidden?: boolean;
  isEditingCost: boolean;
  updateRowCost: (id: string, cost: number) => void;
  isAssociatedDocumentsList?: boolean;
  trueDocType?: DocumentTemplateType;
}

const truncateFactor = window.innerWidth / 16;

export default function DocumentIndexRow(props: DocumentIndexRowProps) {
  const {
    row,
    index,
    handleClick,
    isSelected,
    isIndeterminate,
    onSelect,
    packageItems,
    isSubRow = false,
    isPlanholderList = false,
    isHidden = false,
    isEditingCost,
    updateRowCost,
    isAssociatedDocumentsList,
    trueDocType,
  } = props;

  const history = useHistory();
  const docTypeState = useSelector(getDocumentsType);
  const project = useSelector(getProjectState);
  const documents = useSelector(getDocumentsState);
  const punchListLocations = useSelector(getPhysicalLocationsState);
  const punchListFloors = useSelector(getPhysicalFloorsState);
  const punchListBuildings = useSelector(getPhysicalBuildingsState);

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [truncated, setTruncated] = useState(true);
  const [costInput, setCostInput] = useState(row?.cost ? (row.cost as number) / 100 : 0);

  const [recipientDialogOpen, setRecipientDialogOpen] = useState(false);
  const [associatedDocumentsDialogOpen, setAssociatedDocumentsDialogOpen] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);

  const docType = isAssociatedDocumentsList ? trueDocType : docTypeState;

  const getStatusText = (status: string) => {
    if (!status) return '';
    if (status === ActionTakenType.Withdrawn) return 'Withdrawn';
    if (docType === DocumentTemplateType.AsBuilt) return status;
    if (row.priority === DocumentPriorityType.ForInformationOnly) return 'For Information Only';
    if (docType === DocumentTemplateType.Task) {
      return getUserFriendlyTaskStatusFromWorkflowStatus(status as WorkflowStatusType);
    }
    if (status in statusToGeneralString) {
      return statusToGeneralString[status as WorkflowStatusType | PunchListStatusType];
    }
    if (status === 'N/A') return status;
    return 'Unknown Status';
  };

  const isItemSelected = isSelected ? isSelected(row?.id) : false;
  const labelId = `enhanced-table-checkbox-${index}`;

  // if the row is selected, the background should be light green
  // If not, the background should alternate shades of gray
  let rowColor = '#EFFFF5';
  if (!isItemSelected) {
    if (index % 2 === 0) {
      rowColor = '#FFFFFF';
    } else {
      rowColor = '#F9F9F9';
    }
  }

  const background = rowColor;

  const classes = useStyles();

  const handleExpand = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setCollapseOpen(!collapseOpen);
  };

  const expandRow = () => setCollapseOpen(true);

  const collapseRow = () => setCollapseOpen(false);

  useEffect(() => {
    emitter.on(Events.ExpandRows, expandRow);

    emitter.on(Events.CloseRows, collapseRow);

    return () => {
      emitter.removeListener(Events.ExpandRows, expandRow);
      emitter.removeListener(Events.CloseRows, collapseRow);
    };
  }, []);

  const getCollapseToggle = () => {
    return (
      <Box
        onClick={(e) => handleExpand(e)}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          marginRight: 16,
        }}
      >
        <FontAwesomeIcon
          style={{
            width: 24,
            height: 24,
            marginRight: 8,
          }}
          color="#616061"
          icon={collapseOpen ? faCaretDown : faCaretRight}
        />
      </Box>
    );
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case WorkflowStatusType.Completed:
      case WorkflowStatusType.ReviewComplete:
      case 'Approved':
      case 'Rejected':
      case 'No Exceptions Taken':
      case 'Revise & Resubmit':
        return classes.cellStatusBold;
      default:
        return classes.cellStatus;
    }
  };

  const getLocationPackage = (value: any) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {packageItems && getCollapseToggle()}
        {isSubRow ? (
          <Typography className={classes.cellTitle}>{value as string}</Typography>
        ) : (
          <Typography className={classes.cellTitle}>
            <b>{value as string}</b>
          </Typography>
        )}
      </div>
    );
  };

  const getLocationName = (punchListLocationId: string) => {
    if (isSubRow && docType === DocumentTemplateType.PunchList) {
      const location = punchListLocations.find((itm) => itm.id === punchListLocationId);
      return location?.name || '';
    }
  };

  const getFloorName = (punchListLocationId: string) => {
    const location = punchListLocations.find((itm) => itm.id === punchListLocationId);
    if (location) {
      const floor = punchListFloors.find((f) => f.id === location.floorId);
      return floor?.name || '';
    }
  };

  const getBuildingName = (punchListLocationId: string) => {
    const location = punchListLocations.find((itm) => itm.id === punchListLocationId);
    if (location) {
      const floor = punchListFloors.find((f) => f.id === location.floorId);
      if (floor) {
        const building = punchListBuildings.find((b) => b.id === floor.buildingId);
        return building?.name || '';
      }
    }
  };

  const isPunchListLocationRow = docType === DocumentTemplateType.PunchList && !isSubRow;
  const isDraft =
    docType === DocumentTemplateType.PunchList && row?.hasOwnProperty('isDraft') && !!row?.isDraft;

  const handleTruncate = (text: string) => {
    return text.length > truncateFactor ? (
      <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
        {truncated ? `${text.substr(0, Math.round(truncateFactor)).trim()}...` : text}
        <IconButton
          onClick={(e) => {
            if (text.length > truncateFactor) {
              e.stopPropagation();
              setTruncated((prev) => !prev);
            }
          }}
          style={{ padding: 0 }}
        >
          {truncated ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
      </div>
    ) : (
      text
    );
  };

  const getStatusTextColor = (status: string) => {
    if (status === 'withdrawn') return '';
    if (docType === DocumentTemplateType.PunchList) {
      if (status === PunchListStatusType.Accepted) return '#2BB073';
      if (status === PunchListStatusType.ReadyForVerification) return '#0947B9';
      if (status === 'N/A') return '';
      return '#ED3F26';
    }
    if (
      [
        WorkflowStatusType.ReviewComplete,
        WorkflowStatusType.Completed,
        'Approved',
        'No Exceptions Taken',
      ].includes(status)
    )
      return '#2BB073';
    if (['Rejected', 'Revise & Resubmit'].includes(status)) return '#ED3F26';
    return '';
  };

  const getIconPaddingLeft = (key: string) => {
    if (docType !== DocumentTemplateType.AsBuilt && isSubRow) return 22;
    if (key === 'viewButton') return 0;
    return '30%';
  };

  const getSourceFileName = (id: string) => {
    if (id) {
      const file =
        project?.files?.find((f) => f.id === id) ||
        documents
          .find((d) => d.parsedFromFileId === id)
          ?.files?.sort((a, b) => descendingComparator(a, b, 'createdOn'))?.[0];
      if (file) {
        return file.name.length > truncateFactor
          ? `${file.name.substr(0, Math.round(truncateFactor)).trim()}...`
          : file.name;
      }
    }

    return 'N/A';
  };

  const handleDownload = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    file: IFile,
  ) => {
    e.stopPropagation();
    setIsDownloading(true);
    try {
      const filename =
        docType === DocumentTemplateType.Drawings
          ? `${row.page.toLocaleString(undefined, { minimumIntegerDigits: 3 })}_${row.number}${
              row.title && row.title !== 'N/A' ? `_${row.title}` : ''
            }.pdf`
          : file.name;
      await downloadFileById(file.id, filename);
    } finally {
      setIsDownloading(false);
    }
  };

  const getCells = () => {
    return [
      <>
        {isPlanholderList || !handleClick ? null : (
          <TableCell
            key={generateUniqueId()}
            padding="checkbox"
            className={classes.cellCheckbox}
            style={{ maxWidth: row.isHidden ? 80 : 30 }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GreenCheck
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(event, row.id);
                }}
                checked={isItemSelected}
                indeterminate={isIndeterminate}
                inputProps={{ 'aria-labelledby': labelId }}
              />
              {row.isHidden ? <Delete htmlColor="red" style={{ marginLeft: 6 }} /> : null}
            </div>
          </TableCell>
        )}
      </>,

      Object.entries(row).map(([key, value]) => {
        switch (key) {
          default:
            return null;

          case 'activities':
            return (
              <TableCell aria-label={key} align="left" className={classes.cellDescription}>
                {value as string}
              </TableCell>
            );

          case 'time':
            return (
              <TableCell aria-label={key} align="left" className={classes.cellDescription}>
                {formatDays(value as number)}
              </TableCell>
            );

          case 'description':
            return (
              <TableCell aria-label={key} align="left" className={classes.cellDescription}>
                {handleTruncate(value as string)}
              </TableCell>
            );

          case 'comments':
            return (
              <TableCell aria-label={key} align="left" className={classes.cellDescription}>
                {row.status === WorkflowStatusType.Completed
                  ? (value as string)
                  : 'Awaiting architect review.'}
              </TableCell>
            );
          case 'createdBy':
            return (
              <TableCell aria-label={key} align="left" className={classes.cellDescription}>
                {value as string}
              </TableCell>
            );
          case 'number':
            if (
              isSubRow &&
              (docType === DocumentTemplateType.Addenda ||
                docType === DocumentTemplateType.InformationalItems)
            )
              return (
                <TableCell aria-label={key} className={classes.cellCheckbox}>
                  {row.file && fileIsPdf(row.file) && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        openInNewTab(row.file.id);
                      }}
                      style={{ padding: 0, paddingLeft: getIconPaddingLeft(key) }}
                    >
                      <VisibilityIcon htmlColor="#464546" />
                    </IconButton>
                  )}
                </TableCell>
              );
            return (
              <TableCell
                aria-label={key}
                component="th"
                id={labelId}
                scope="row"
                className={classes.cellNumberNoWrap}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {packageItems && getCollapseToggle()}
                  <Typography
                    className={classes.numberText}
                    style={{ marginLeft: isSubRow ? 48 : 0 }}
                  >
                    {value as string}
                  </Typography>
                </div>
              </TableCell>
            );

          case 'locationId':
            return (
              <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <strong>
                      {getFloorName(isPunchListLocationRow ? row.id : row.locationId)}
                    </strong>
                    {isPunchListLocationRow ? (value as string) : getLocationName(value as string)}
                  </div>
                  {isDraft && (
                    <Chip size="small" label="Draft" color="secondary" style={{ marginLeft: 16 }} />
                  )}
                </div>
              </TableCell>
            );

          case 'building':
            let paddingLeft = 16;
            if (isSubRow) paddingLeft = 64;
            return (
              <TableCell
                aria-label={key}
                align="left"
                className={classes.cellTitle}
                style={{
                  whiteSpace: isPunchListLocationRow ? 'nowrap' : 'initial',
                  overflowX: isPunchListLocationRow ? 'visible' : 'initial',
                  paddingLeft,
                }}
              >
                {getLocationPackage(
                  isSubRow || isAssociatedDocumentsList ? getBuildingName(row.locationId) : value,
                )}
              </TableCell>
            );

          case 'location':
          case 'simplePackage':
          case 'email':
          case 'size':
          case 'referenceNumber':
          case 'download':
          case 'view':
          case 'pages':
          case 'company':
          case 'license':
          case 'contact':
          case 'section':
          case 'action':
          case 'filename':
          case 'agency':
          case 'uploader':
          case 'acceptance':
            return (
              <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                {key === 'action' ? getActionTakenText(value as string) : (value as string)}
              </TableCell>
            );

          case 'cost':
            if (isEditingCost && !isPunchListLocationRow) {
              return (
                <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                  <CurrencyTextField
                    variant="outlined"
                    margin="none"
                    fullWidth
                    value={costInput}
                    onChange={(event: any, value: number) => {
                      console.log('on change');
                      setCostInput(value);
                      updateRowCost(row.id, Math.round(value * 100));
                    }}
                    currencySymbol="$"
                    minimumValue="0"
                    outputFormat="number"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    InputProps={{ style: { height: 40 } }}
                  />
                </TableCell>
              );
            }
            return (
              <TableCell
                aria-label={key}
                align="left"
                className={classes.cellTitle}
                style={{
                  fontWeight:
                    docType === DocumentTemplateType.PunchList && !isSubRow ? 700 : 'initial',
                }}
              >
                {value === null ? 'TBD' : formatMoney(value as number)}
              </TableCell>
            );

          case 'title':
            return (
              <TableCell
                aria-label={key}
                align="left"
                colSpan={1}
                className={classes.cellTitle}
                style={{
                  paddingLeft: 16,
                }}
              >
                {value as string}
                {docType === DocumentTemplateType.FieldReports && row.isDraft ? (
                  <Chip size="small" label="Draft" color="secondary" style={{ marginLeft: 8 }} />
                ) : null}
              </TableCell>
            );
          case 'submittalDescription':
            const { sectionTitle, title } = value as { sectionTitle: string; title: string };
            return (
              <>
                <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                  <div>
                    {sectionTitle !== 'N/A' && (
                      <Typography style={{ fontWeight: 700, fontSize: 16 }}>
                        {sectionTitle}
                      </Typography>
                    )}
                    <Typography style={{ fontWeight: 'normal', fontSize: 15 }}>{title}</Typography>
                  </div>
                </TableCell>
              </>
            );
          case 'impact':
            const { cost, time } = value as any;
            return (
              <>
                <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                  <div style={{ flexDirection: 'column' }}>
                    <Typography>
                      {time.toString()} / {cost.toString()}
                    </Typography>
                  </div>
                </TableCell>
              </>
            );
          case 'submittedBy':
          case 'responsibility': {
            if (typeof value === 'object') {
              const { names, company } = value as Responsibility;
              return (
                <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                  {!names || !company || names.includes('Not Assigned') ? (
                    <>{names}</>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <AssignedUserText name={names.join(', ')} company={company} />
                      <Tooltip
                        arrow
                        placement="top"
                        title={`Anyone at ${company} with the same permissions as ${names} may act on their behalf`}
                      >
                        <Info />
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
              );
            }

            return (
              <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                {value as string}
              </TableCell>
            );
          }
          case 'responsibleParty':
            return (
              <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                {value as string}
              </TableCell>
            );

          case 'assignedTo':
          case 'assignedBy': {
            if (Array.isArray(value)) {
              const assignees = value as Assigned[];
              return (
                <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                  <Select
                    variant="outlined"
                    defaultValue="Empty"
                    displayEmpty
                    fullWidth
                    onClick={(e) => e.stopPropagation()}
                    style={{ height: '48px' }}
                  >
                    <MenuItem value="Empty">{`${assignees.length} ${pluralize(
                      'User',
                      assignees.length,
                    )}`}</MenuItem>
                    {assignees.map(({ name, company }) => (
                      <MenuItem key={name + company} disabled>
                        <AssignedUserText name={name} company={company} />
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              );
            }
            const { name, company } = value as Assigned;
            return (
              <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                <AssignedUserText name={name} company={company} />
              </TableCell>
            );
          }

          case 'priority':
            if (docType !== DocumentTemplateType.Task) return null;
            return (
              <TableCell aria-label={key} align="left" className={classes.cellTitle}>
                {getUserFriendlyDocumentPriority(value as DocumentPriorityType)}
              </TableCell>
            );

          case 'expectedCompletionDate':
          case 'dateOfLastUpdate':
          case 'executedDate':
          case 'dateOfObservation':
          case 'unsubscribed':
          case 'completedDate':
          case 'initialDate':
          case 'actualDate':
          case 'approvedDate':
          case 'returnedDate':
          case 'submittedDate':
          case 'dateAdded':
          case 'dueDate':
          case 'issueDate': {
            const dateElement =
              value !== 'N/A' ? parseDate(value as string).format('MM/DD/YYYY') : 'N/A';
            return (
              <TableCell
                aria-label={key}
                align="left"
                className={classes.cellIssueDate}
                style={{ fontWeight: key === 'dateOfObservation' ? 700 : 'initial' }}
              >
                {dateElement}
              </TableCell>
            );
          }
          case 'lastModified':
          case 'postedDate':
            const dateElement =
              value !== 'N/A' ? parseDate(value as string).format('MM/DD/YYYY') : 'N/A';
            const timeElement =
              value !== 'N/A' ? parseDate(value as string).format('h:mm A') : 'N/A';
            return (
              <TableCell aria-label={key} align="left" className={classes.cellIssueDate}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography style={{ fontWeight: 500 }}>{dateElement}</Typography>
                  <Typography color="textSecondary">@{timeElement}</Typography>
                </div>
              </TableCell>
            );
          case 'scheduleDate':
          case 'meetingDate':
          case 'logDate':
            const date = parseDate(value as string).format('MM/DD/YYYY');
            return (
              <TableCell aria-label={key} align="left" className={classes.cellIssueDate}>
                {date ? date : ''}
              </TableCell>
            );

          case 'submittedDateTime': {
            const dateElement = value !== 'N/A' ? formatDate(value as string) : 'N/A';
            return (
              <TableCell aria-label={key} align="left" className={classes.cellIssueDate}>
                {dateElement}
              </TableCell>
            );
          }

          case 'openedOn': {
            const opened = !!value ? 'Opened' : 'Sealed';
            return (
              <TableCell
                aria-label={key}
                align="left"
                className={classes.cellTitle}
                style={{ whiteSpace: 'nowrap' }}
              >
                {opened}
              </TableCell>
            );
          }

          case 'status':
            return (
              <TableCell
                aria-label={key}
                align="left"
                className={getStatusClass(value as string)}
                style={{
                  color: getStatusTextColor(value as string),
                }}
              >
                {getStatusText(value as WorkflowStatusType | PunchListStatusType | 'withdrawn')}
              </TableCell>
            );

          case 'sync':
            if (project?.isProcoreIntegrationEnabled)
              return (
                <TableCell aria-label={key} padding="checkbox" className={classes.cellCheckbox}>
                  <GreenCheck disabled checked={value as boolean} />
                </TableCell>
              );
            else return null;

          case 'attachment':
          case 'viewButton':
            if (
              packageItems &&
              (docType === DocumentTemplateType.Addenda ||
                docType === DocumentTemplateType.InformationalItems)
            )
              return (
                <TableCell aria-label={key} className={classes.cellTitle}>
                  {getCollapseToggle()}
                </TableCell>
              );
            if (!value && docType !== DocumentTemplateType.FieldReports)
              return <TableCell aria-label={key} />;
            const file = value as IFile;
            return (
              <TableCell aria-label={key} className={classes.cellCheckbox}>
                {((file && fileIsPdf(file)) || docType === DocumentTemplateType.FieldReports) && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        docType === DocumentTemplateType.FieldReports &&
                        row.isCreatedInCenterline
                      )
                        onSelect(e, row.id, row.file, row.company, row.contact);
                      else openInNewTab(file.id);
                    }}
                    style={{ padding: 0, paddingLeft: getIconPaddingLeft(key) }}
                  >
                    <VisibilityIcon htmlColor="#464546" />
                  </IconButton>
                )}
              </TableCell>
            );

          case 'downloadButton': {
            const file = value as IFile | undefined;
            return (
              <TableCell aria-label={key} className={classes.cellCheckbox}>
                {file && (
                  <div style={{ display: 'flex', justifyContent: 'center', paddingRight: 16 }}>
                    {!isDownloading ? (
                      <IconButton onClick={(e) => handleDownload(e, file)} style={{ padding: 0 }}>
                        <DownloadIcon fill="#464546" />
                      </IconButton>
                    ) : (
                      <CircularLoader size={25} />
                    )}
                  </div>
                )}
              </TableCell>
            );
          }

          case 'viewRecipients':
            return (
              <TableCell aria-label={key} className={classes.cellTitle}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRecipientDialogOpen(true);
                  }}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  View Recipients
                </Button>
              </TableCell>
            );

          case 'parsedFromFileId':
            return (
              <TableCell aria-label={key} className={classes.cellTitle}>
                <Typography style={{ whiteSpace: 'nowrap' }}>
                  {getSourceFileName(value as string)}
                </Typography>
              </TableCell>
            );

          case 'mostRecentFile': {
            const file = value as IFile | null;
            return (
              <TableCell aria-label={key} className={classes.cellTitle}>
                <Typography style={{ whiteSpace: 'nowrap' }}>{file?.name || 'N/A'}</Typography>
              </TableCell>
            );
          }

          case 'associatedDocuments':
            return (
              <TableCell align="center" aria-label={key} className={classes.cellTitle}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setAssociatedDocumentsDialogOpen(true);
                  }}
                  style={{ padding: 0 }}
                >
                  <Description />
                </IconButton>
              </TableCell>
            );
        }
      }),
    ];
  };

  if (isPunchListLocationRow && packageItems?.length === 0) return null;

  if (!row) return null;

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        onClick={(event: any) => {
          if (isEditingCost) return;
          event.stopPropagation();
          if (docType !== DocumentTemplateType.PunchList || isSubRow) {
            onSelect(event, row.id, row.file, row.company, row.contact);
          } else {
            history.push(`punch-list/${row.id}/edit-location`);
          }
        }}
        style={{ height: '56px', background, display: isHidden ? 'none' : 'table-row' }}
        className={classes.tableRow}
      >
        {getCells()}
      </TableRow>
      {packageItems ? (
        <>
          {packageItems
            .sort((a, b) => ascendingComparator(a, b, 'number'))
            .map((row) => {
              if (!row) return null;
              return (
                <DocumentIndexRow
                  key={row.id}
                  row={row}
                  index={index}
                  handleClick={handleClick}
                  isSubRow
                  onSelect={onSelect}
                  isSelected={isSelected}
                  isHidden={!collapseOpen}
                  isEditingCost={isEditingCost}
                  updateRowCost={updateRowCost}
                />
              );
            })}
        </>
      ) : null}
      {row.viewRecipients && (
        <UserFilterListDialog
          title="Recipients"
          users={
            (row.viewRecipients.userNotificationList as IUserNotification[])
              ?.map((notif) => notif.user!)
              .sort((a, b) => ascendingComparator(a, b, 'name')) || []
          }
          dialogOpen={recipientDialogOpen}
          handleClose={() => setRecipientDialogOpen(false)}
          emptyText="No recipients of this Addendum."
        />
      )}
      {row.associatedDocuments ? (
        <AssociatedDocumentsDialog
          open={associatedDocumentsDialogOpen}
          close={() => setAssociatedDocumentsDialogOpen(false)}
          documentId={row.id}
          title={row.documentTitle}
        />
      ) : null}
    </>
  );
}
