import logoSvg from '../../images/wordmark-blue.svg';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState, getProjectUsersState } from '../../features/project/selectors';
import {
  DocumentTemplateType,
  IFile,
  INumberedDocumentView,
  IProjectUser,
  IUser,
  IUserGroup,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import { getUsersFromUserGroup } from '../design/ManagePermissionsDialog';
import {
  createQRCode,
  DocumentMatchParams,
  downloadFileById,
  fileIsImage,
  formatDate,
  getDocumentIdentifier,
  getFileBlob,
  getRelevantFieldsByDocumentType,
  getSubmittalTitle,
  getUserFacingDocumentStatus,
  getUserFriendlyConsultantResponse,
  getUserFriendlyDocumentFields,
  sortLastNamesDesc,
  urlToDocumentType,
  urlToDocumentTypeReadable,
} from '../../scripts/utils';
import { getImageRows, getTableRows } from './printing-utils';
import { getDocumentState } from '../../features/document/selectors';
import { getGroupsState } from '../../features/groups/selector';
import { getDocumentLoadingState } from '../../features/loading/selectors';
import { fetchDocument } from '../../features/document/actions';
import { useParams } from 'react-router';
import { getDocumentPublicLink } from '../../models/api/users';
import { getUserState } from '../../features/user/selectors';
import Button from '@material-ui/core/Button';
import FullscreenLoader from '../loader/FullscreenLoader';
import { getNotificationsByDocumentId } from '../../models/api/notifications';
import _ from 'lodash';
import AssignedUserText from '../custom-components/AssignedUserText';
import ActivityItem from '../activity/ActivityItem';
import { ascendingComparator, descendingComparator } from '../document-index/DocumentIndexUtils';
import { CancelButton } from '../custom-components/CustomButtons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Print } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import DownloadIcon from '../icons/Download-icon';
import CircularLoader from '../loader/CircularLoader';

const getRowsFromDocument = async ({
  document,
  documentType,
  projectUsers,
  groups,
}: {
  document: INumberedDocumentView;
  documentType: DocumentTemplateType;
  projectUsers: IProjectUser[];
  groups: IUserGroup[];
}) => {
  const getValueElementFromKey = (key: string, value: string | null) => {
    if (key.toLowerCase().includes('date') || key.toLowerCase() === 'created on') {
      const omitTime = [
        'date observed',
        'expected completion date',
        'log date',
        'date of observation',
      ].includes(key.toLowerCase());
      return formatDate(value, omitTime);
    }
    switch (key) {
      case 'Currently Responsible':
      case 'Responsible Architect':
      case 'Responsible General Contractor': {
        const user = projectUsers.find((pU) => pU.userId === value)?.user;
        if (user) {
          return (
            <>
              <strong>{user.company?.name}, </strong>
              {user.name}
            </>
          );
        }
        break;
      }

      case 'Current Status':
        return getUserFacingDocumentStatus(document);

      default:
        return value;
    }
  };

  let users: IUser[] = [];
  document.documentUserList?.forEach(({ userId }) => {
    const newUser = projectUsers.find((pUser) => pUser.userId === userId)?.user;
    if (newUser) users.push(newUser);
  });
  document.documentUserGroupList?.forEach(({ userGroupId }) => {
    const group = groups.find((group) => group.id === userGroupId);
    if (group) users.push(...getUsersFromUserGroup(group));
  });
  // get unique users by id
  users = Array.from(new Set(users.map((u) => u.id)))
    .map((id) => users.find((u) => u.id === id)!)
    .sort((a, b) => sortLastNamesDesc(a.name, b.name));

  const { results: notifications } = await getNotificationsByDocumentId(document.id, 0, 10);

  const images = [DocumentTemplateType.FieldReports, DocumentTemplateType.WarrantyItems].includes(
    documentType,
  )
    ? await Promise.all(
        (document.files || [])
          .filter((f) => fileIsImage(f))
          .sort((a, b) => ascendingComparator(a, b, 'createdOn'))
          .map(async (f) => {
            const blob = await getFileBlob(f.id);
            return {
              image: blob ? URL.createObjectURL(blob) : '',
              description: f.description,
            };
          }),
      )
    : [];

  const files = document.files
    ?.filter((f) => !fileIsImage(f))
    .sort((a, b) => descendingComparator(a, b, 'createdOn'));

  const fields = getRelevantFieldsByDocumentType(documentType);
  if (!fields.includes('createdOn') && !fields.includes('dateSubmitted')) {
    fields.push('createdOn');
  }
  const documentWithRelevantProperties = _.pick(document, fields);

  return (
    <>
      <div className="divider" />
      <TableContainer className="nobreak mid-section">
        <Table className="no-padding-table">
          {getUserFriendlyDocumentFields(documentWithRelevantProperties, documentType).map(
            ({ key, value }) => {
              return (
                <TableRow key={key}>
                  <TableCell>
                    <Typography variant="body1">{key}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{getValueElementFromKey(key, value)}</Typography>
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </Table>
      </TableContainer>
      <br />
      {document.lockedNotificationJson?.userNotificationList ? (
        <>
          <div className="mid-section">
            <Typography variant="body1" className="row-subtitle">
              Recipients
            </Typography>
            <TableContainer>
              <Table className="table-with-lines">
                {document.lockedNotificationJson.userNotificationList.map(({ user }) => (
                  <TableRow key={user?.id}>
                    <TableCell>
                      <Typography>
                        <strong>{user?.company?.name}, </strong>
                        {user?.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </div>
          <br />
        </>
      ) : null}
      {document.additionalReviewDocuments?.length ? (
        <>
          <div className="mid-section">
            <Typography variant="body1" className="row-subtitle">
              Consultant reviews
            </Typography>
            <TableContainer>
              <Table className="fixed-width-table table-with-lines">
                <TableHead>
                  <TableCell>
                    <Typography>Reviewer</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>File</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Response</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Dates</Typography>
                  </TableCell>
                </TableHead>
                {document.additionalReviewDocuments.map((doc) => {
                  const file: IFile | undefined = doc.files?.sort((a, b) =>
                    descendingComparator(a, b, 'createdOn'),
                  )[0];

                  const getStatus = () => {
                    if (
                      !!doc.workflowStatus &&
                      [
                        WorkflowStatusType.ReviewComplete,
                        WorkflowStatusType.Accepted,
                        WorkflowStatusType.Canceled,
                        WorkflowStatusType.Resubmitted,
                      ].includes(doc.workflowStatus)
                    ) {
                      return 'N/A';
                    }

                    return doc.workflowStatus === WorkflowStatusType.UnderReview
                      ? 'Under Review'
                      : 'Awaiting Review';
                  };

                  return (
                    <>
                      <TableRow key={doc.id}>
                        <TableCell style={{ verticalAlign: 'middle' }}>
                          <AssignedUserText
                            name={doc.assignedToUser?.name}
                            company={doc.assignedToUser?.company?.name}
                          />
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'middle' }}>
                          {file ? (
                            <>
                              <Typography>{file.name}</Typography>
                              <Typography>{formatDate(file.createdOn!)}</Typography>
                            </>
                          ) : (
                            <Typography>{getStatus()}</Typography>
                          )}
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'middle' }}>
                          <Typography>
                            {getUserFriendlyConsultantResponse(doc.recommendedAction)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <div style={{ display: 'flex' }}>
                            <Typography style={{ width: 120 }}>Assigned</Typography>
                            <Typography>{formatDate(doc.createdOn!, true)}</Typography>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <Typography style={{ width: 120 }}>Due Internal</Typography>
                            <Typography>{formatDate(doc.dueDate!, true)}</Typography>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <Typography style={{ width: 120 }}>Returned</Typography>
                            <Typography>
                              {doc.reviewCompleteOn
                                ? formatDate(doc.reviewCompleteOn, true)
                                : 'N/A'}
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                      {doc.reviewChildDocuments?.map((doc) => (
                        <TableRow key={doc.id}>
                          <TableCell colSpan={2}>
                            <Typography>
                              {getSubmittalTitle(doc.additionalReviewForDocument!)}
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={2}>
                            <Typography>
                              {getUserFriendlyConsultantResponse(doc.recommendedAction)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  );
                })}
              </Table>
            </TableContainer>
            <br />
          </div>
        </>
      ) : null}
      {files?.length ? (
        <>
          <div className="mid-section">
            <Typography variant="body1" className="row-subtitle">
              Supporting items
            </Typography>
            <TableContainer>
              <Table className="fixed-width-table table-with-lines">
                <TableHead>
                  <TableCell width={150} />
                  <TableCell>
                    <Typography>Filename</Typography>
                  </TableCell>
                  <TableCell width={250}>
                    <Typography>Posted Date</Typography>
                  </TableCell>
                </TableHead>
                <TableRow key={files[0].id}>
                  <TableCell>
                    <Typography>
                      <strong>Current File</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{files[0].name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{formatDate(files[0].createdOn!)}</Typography>
                  </TableCell>
                </TableRow>
                {files.length > 1 ? (
                  <TableRow key={files[1].id}>
                    <TableCell>
                      <Typography>
                        <strong>Previous Files</strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{files[1].name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{formatDate(files[1].createdOn!)}</Typography>
                    </TableCell>
                  </TableRow>
                ) : null}
                {files.length > 2
                  ? files.slice(2).map((file) => (
                      <TableRow key={file.id}>
                        <TableCell></TableCell>
                        <TableCell>
                          <Typography>{file.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{formatDate(file.createdOn!)}</Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </Table>
            </TableContainer>
          </div>
          <br />
        </>
      ) : null}
      {document.comments?.length ? (
        <>
          <div className="mid-section">
            <Typography variant="body1" className="row-subtitle">
              Comments
            </Typography>
            <TableContainer>
              <Table className="fixed-width-table table-with-lines">
                {document.comments.map((c) => (
                  <TableRow key={c.id}>
                    <TableCell>
                      <Typography>
                        <strong>{c.creatorUser?.company?.name},</strong>
                        <br />
                        {c.creatorUser?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{c.text}</Typography>
                    </TableCell>
                    <TableCell width={250}>
                      <Typography>{formatDate(c.createdOn!)}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </div>
          <br />
        </>
      ) : null}
      {document.submittalChildDocuments?.length ? (
        <>
          <div className="mid-section">
            <Typography variant="body1" className="row-subtitle">
              Submittals in this package
            </Typography>
            <TableContainer>
              <Table className="table-with-lines fixed-width-table">
                {document.submittalChildDocuments.map((doc) => (
                  <TableRow key={doc.id}>
                    <TableCell>
                      <Typography>{getSubmittalTitle(doc)}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </div>
          <br />
        </>
      ) : null}
      <div className="mid-section">
        <Typography variant="body1" className="row-subtitle">
          Notification group for this report
        </Typography>
        <TableContainer>
          <Table className="table-with-lines white-background">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">Company</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">Email</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Typography variant="body1">{user.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {user.company?.name || 'Unknown Company'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{user.email}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <br />
      {documentType === DocumentTemplateType.FieldReports && document.partiesPresent?.length ? (
        <>
          <div className="mid-section">
            <Typography variant="body1" className="row-subtitle">
              Parties Present
            </Typography>
            <TableContainer>
              <Table className="table-with-lines white-background">
                {Array.from(getTableRows(document.partiesPresent))}
              </Table>
            </TableContainer>
          </div>
          <br />
        </>
      ) : null}
      {documentType === DocumentTemplateType.FieldReports ? (
        <>
          <div className="mid-section">
            <TableContainer className="nobreak mid-section">
              <Table
                className="no-padding-table"
                style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
              >
                <TableRow key="description">
                  <TableCell>
                    <Typography variant="body1">General Observations</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                      {document.description}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key="currentWorkInProgress">
                  <TableCell>
                    <Typography variant="body1">Work In Progress</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                      {document.currentWorkInProgress}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </div>
          <br />
        </>
      ) : null}
      {images?.length ? (
        <>
          <div className="mid-section">
            <Typography variant={'body1'} className="row-subtitle">
              Photo documentation
            </Typography>
            {Array.from(getImageRows(images))}
          </div>
          <br />
        </>
      ) : null}
      {notifications.length ? (
        <div className="nobreak">
          <Typography variant={'body1'} className="row-subtitle">
            Most recent activity items
          </Typography>
          {notifications.map((n) => (
            <ActivityItem
              key={n.id}
              notification={n}
              rootStyle={{ border: '1px solid lightgrey' }}
            />
          ))}
        </div>
      ) : null}
      <br />
    </>
  );
};

export default function DocumentPrintView() {
  const { documentId, type } = useParams<DocumentMatchParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const currentDocument = useSelector(getDocumentState);
  const documentLoading = useSelector(getDocumentLoadingState);
  const selectedProject = useSelector(getProjectState);
  const projectUsers = useSelector(getProjectUsersState);
  const groups = useSelector(getGroupsState);

  const qrcodeRef = useRef(null);
  const [qrcodeCreated, setQrcodeCreated] = useState(false);
  const [tableRows, setTableRows] = useState<JSX.Element | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const documentType = urlToDocumentType[type] as DocumentTemplateType;
  const isPackage =
    currentDocument?.documentTemplate?.name === DocumentTemplateType.SubmittalPackages ||
    currentDocument?.documentTemplate?.name === DocumentTemplateType.CloseoutSubmittalPackages ||
    currentDocument?.documentTemplate?.name === DocumentTemplateType.AsBuiltPackages;

  const getDocumentType = () => {
    if (isPackage) {
      if (documentType === DocumentTemplateType.Submittals) return 'Submittal Package';
      if (documentType === DocumentTemplateType.CloseoutSubmittals)
        return 'Closeout Submittal Package';
      if (documentType === DocumentTemplateType.AsBuiltPackages) return 'As-Built Package';
    }

    return urlToDocumentTypeReadable(type);
  };

  const printFileName = `${selectedProject?.name} ${getDocumentType()} ${getDocumentIdentifier(
    currentDocument,
    documentType,
    {
      isPackage,
    },
  )}`;

  const handleGoBack = () => {
    history.push(
      history.location.pathname.substring(0, history.location.pathname.lastIndexOf('/')),
    );
  };

  const downloadAllFiles = async () => {
    if (!currentDocument?.files?.length) return;

    try {
      setIsDownloading(true);
      for (let i = 0; i < currentDocument.files.length; i += 1) {
        const file = currentDocument.files[i];
        await downloadFileById(file.id, `${printFileName} ${file.name}`);
      }
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (currentDocument) {
      getRowsFromDocument({ document: currentDocument, documentType, projectUsers, groups }).then(
        setTableRows,
      );
    }
  }, [currentDocument, projectUsers, groups]);

  useEffect(() => {
    if (!currentDocument && !documentLoading) {
      dispatch(fetchDocument(documentId));
    }
  }, [currentDocument, documentLoading]);

  useEffect(() => {
    if (currentDocument && qrcodeRef.current && !qrcodeCreated) {
      getDocumentPublicLink(currentDocument?.id).then((link) => {
        createQRCode({
          qrcodeRef: qrcodeRef.current!,
          link,
          width: 133,
          height: 133,
          drawer: 'svg',
        });
        setQrcodeCreated(true);
      });
    }
  }, [currentDocument]);

  const handlePrint = () => {
    const originalTitle = document.title;
    document.title = `${printFileName} Summary`;
    window.print();
    document.title = originalTitle;
  };

  return (
    <div>
      <main id="print-this" className="punch-list-print-view">
        <CancelButton
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          style={{ margin: '8px 16px 8px 0px' }}
        >
          Cancel
        </CancelButton>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Print />}
          onClick={handlePrint}
          style={{ width: 160, marginRight: 16, whiteSpace: 'nowrap' }}
        >
          Save as PDF
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={!isDownloading ? <DownloadIcon fill="white" /> : <CircularLoader size={20} />}
          disabled={isDownloading || !currentDocument?.files?.length}
          onClick={downloadAllFiles}
        >
          Download Supporting Items
        </Button>
        <br />
        <img src={logoSvg} id="centerline-logo" alt="centerline-logo" className="logo" />
        <div className="qrcode" ref={qrcodeRef} />
        <Typography style={{ whiteSpace: 'nowrap' }}>
          Printed on {new Date().toLocaleString()}
        </Typography>
        <Typography style={{ whiteSpace: 'nowrap' }}>
          Printed by <strong>{user?.company?.name},</strong> {user?.name}
        </Typography>
        <Table className="no-padding-table header-table white-background">
          <TableRow>
            <TableCell>
              <Typography
                variant="h2"
                style={{
                  fontSize: 26,
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  color: '#464546',
                  textTransform: 'none',
                }}
              >
                Created By:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="h2"
                style={{
                  fontSize: 26,
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  color: '#464546',
                  textTransform: 'none',
                }}
              >
                {currentDocument?.creatorUser?.company?.name}
              </Typography>
              <Typography
                variant="h2"
                style={{
                  fontSize: 26,
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  color: '#464546',
                  textTransform: 'none',
                }}
              >
                {currentDocument?.creatorUser?.name}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                variant="h2"
                style={{
                  fontSize: 26,
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  color: '#464546',
                  textTransform: 'none',
                }}
              >
                Project:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="h2"
                style={{
                  fontSize: 26,
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  color: '#464546',
                  textTransform: 'none',
                }}
              >
                {selectedProject?.name}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                variant="h2"
                style={{
                  fontSize: 26,
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  color: '#464546',
                  textTransform: 'none',
                }}
              >
                {getDocumentType()}:
              </Typography>
            </TableCell>
            <TableCell>
              {currentDocument ? (
                <Typography
                  variant="h2"
                  style={{
                    fontSize: 26,
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    color: '#464546',
                    textTransform: 'none',
                  }}
                >
                  {getDocumentIdentifier(currentDocument, documentType, { isPackage })}
                </Typography>
              ) : null}
            </TableCell>
          </TableRow>
        </Table>
        {tableRows || <FullscreenLoader />}
      </main>
    </div>
  );
}
