import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  createMuiTheme,
  Dialog,
  Hidden,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import '../../css/main-style.css';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  ActionTakenType,
  GetUserDocumentPermissionResponse,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import StepStatus from './StepStatus';
import './accordion/Accordion.scss';
import { getUserState } from '../../features/user/selectors';
import {
  getDefaultArchitect,
  getDefaultContractor,
  getDisableProcoreIntegrationFeatures,
} from '../../features/project/selectors';
import { getDocumentState } from '../../features/document/selectors';
import backdrop from '../../assets/images/action_center.jpg';
import TaskStep from './TaskStep';
import { formatDate, generateUniqueId } from '../../scripts/utils';
import Button from '@material-ui/core/Button';
import { RemoveCircleOutlined, RotateLeft } from '@material-ui/icons';
import { getDocPermission } from '../../scripts/store-utils';
import { undoCompleteReviewByDocumentId } from '../../models/api/documents';
import { updateDocument } from '../../features/document/actions';
import { useHistory } from 'react-router-dom';
import MobileTaskStep from './MobileTaskStep';
import { TooltipIfDisabledComponent } from '../custom-components/CustomButtons';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      overflow: 'visible',
      backgroundImage: `url(${backdrop})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      zIndex: -100,
    },
    backgroundOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.7,
      background:
        'radial-gradient(494.01% 4071.48% at 28.5% -149.12%, #0947B9 0%, rgba(204, 222, 255, 0) 100%)',
      mixBlendMode: 'normal',
      filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1))',
      zIndex: -90,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      width: 'auto',
      paddingLeft: '7px',
      paddingBottom: '0px',
    },
    accordionContainer: {
      display: 'flex',
    },
    accordion: {
      display: 'flex',
      maxWidth: 80,
      margin: 0,
      transition: 'max-width 440ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    },
    accordionExpanded: {
      maxWidth: 600,
      margin: 0,
    },
    accordionSummary: {
      width: '80px',
      height: '100%',
      backgroundColor: '#2BB073',
      color: 'white',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    accordionSummaryContent: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    textDirection: {
      writingMode: 'vertical-rl',
      textOrientation: 'mixed',
      transform: 'rotate(-180deg)',
      fontWeight: 500,
      fontSize: '15px',
    },
  }),
);

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1500,
      xl: 1700,
    },
  },
});

const titles = ['Submit for Review', 'Architect Review', 'Complete'];

interface SubmittalActionCenterProps {
  docPermissions: GetUserDocumentPermissionResponse | undefined;
}

export default function SubmittalActionCenter(props: SubmittalActionCenterProps) {
  const classes = useStyles();
  const { docPermissions } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const architect = useSelector(getDefaultArchitect);
  const generalContractor = useSelector(getDefaultContractor);
  const document = useSelector(getDocumentState)!;
  const isProcoreEnabled = useSelector(getDisableProcoreIntegrationFeatures);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [activeStep, setActiveStep] = useState(-1);
  const [stepStatuses, setStepStatues] = useState<StepStatus[]>([
    StepStatus.ACTIVE,
    StepStatus.DISABLED,
    StepStatus.DISABLED,
  ]);

  const isOver760px = useMediaQuery('(min-width:760px)', { noSsr: true });

  const handleClose = () => {
    setActiveStep(-1);
  };

  const getDate = (num: number) => {
    switch (num) {
      case 0:
        if (stepStatuses[0] === StepStatus.COMPLETED) return document.actualSubmissionDate;
        return document.dueDate;
      case 1:
        if (stepStatuses[1] === StepStatus.COMPLETED) return document.dateReturnedToContractor;
        return document.dueDate;
      case 2:
        if (stepStatuses[2] === StepStatus.COMPLETED) return document.dateReturnedToContractor;
        return document.dueDate;
      default:
        return 'N/A';
    }
  };

  const getIsComplete = () => {
    return (
      stepStatuses[0] === StepStatus.COMPLETED &&
      stepStatuses[1] === StepStatus.COMPLETED &&
      stepStatuses[2] === StepStatus.COMPLETED
    );
  };

  const getOnClick = (index: number) => {
    if (
      document.submittalPackageDocumentId ||
      document.actionTaken === ActionTakenType.Withdrawn ||
      document.isHidden
    ) {
      return () => {};
    }
    switch (index) {
      case 0:
        if (stepStatuses[0] === StepStatus.COMPLETED) return undefined;
        if (document.workflowStatus === WorkflowStatusType.Initial) {
          if (!document.assignedToUserId)
            return () => {
              if (activeStep === index) {
                setActiveStep(-1);
              } else setActiveStep(index);
            };
        }
        if (document.assignedToUserId === user.id || docPermissions?.isAssignableToDocument)
          return () => {
            if (activeStep === index) {
              setActiveStep(-1);
            } else setActiveStep(index);
          };
        return undefined;
      case 1:
        if (stepStatuses[1] === StepStatus.DISABLED) return undefined;
        if (document.assignedToUserId === user.id || docPermissions?.isAssignableToDocument)
          return () => {
            if (activeStep === index) {
              setActiveStep(-1);
            } else setActiveStep(index);
          };
        return undefined;
      default:
      case 2:
        return undefined;
    }
  };

  useEffect(() => {
    //There is a glitch on production where this use effect is called a second time a few seconds after the page loads.
    //So make sure SAC isn't already opened.
    if (
      activeStep === -1 ||
      document.workflowStatus === WorkflowStatusType.SubmittedForReview ||
      document.workflowStatus === WorkflowStatusType.Completed
    ) {
      switch (document.workflowStatus) {
        default:
        case WorkflowStatusType.Initial:
          setStepStatues([StepStatus.ACTIVE, StepStatus.DISABLED, StepStatus.DISABLED]);
          if (document.assignedToUserId) {
            if (document.assignedToUserId === user.id || docPermissions?.isAssignableToDocument)
              setActiveStep(0);
            else setActiveStep(-1);
            break;
          }
          setActiveStep(0);
          break;

        case WorkflowStatusType.SubcontractorUploaded:
        case WorkflowStatusType.ReadyForSubmissionToArchitect:
        case WorkflowStatusType.GeneralContractorUploaded:
          setStepStatues([StepStatus.ACTIVE, StepStatus.DISABLED, StepStatus.DISABLED]);
          if (document.assignedToUserId === user.id || docPermissions?.isAssignableToDocument)
            setActiveStep(0);
          else setActiveStep(-1);
          break;

        case WorkflowStatusType.SubmittedForReview:
          setStepStatues([StepStatus.COMPLETED, StepStatus.DISABLED, StepStatus.DISABLED]);
          setActiveStep(-1);
          break;

        case WorkflowStatusType.UnderReview:
        case WorkflowStatusType.ArchitectUploaded:
          setStepStatues([StepStatus.COMPLETED, StepStatus.ACTIVE, StepStatus.DISABLED]);
          if (document.assignedToUserId === user.id || docPermissions?.isAssignableToDocument)
            setActiveStep(1);
          else setActiveStep(-1);
          break;

        case WorkflowStatusType.Completed:
          setStepStatues([StepStatus.COMPLETED, StepStatus.COMPLETED, StepStatus.COMPLETED]);
          setActiveStep(-1);
          break;
      }
    }
  }, [document.workflowStatus, document.assignedToUserId, docPermissions]);

  const hasRollbackPermission = () => {
    if (document.workflowStatus !== WorkflowStatusType.Completed) return false;
    const permission = getDocPermission();
    return permission && permission >= 3;
  };

  const handleRollback = async () => {
    const proceed = window.confirm('Are you sure you want to revise this document?');
    if (proceed) {
      const doc = await undoCompleteReviewByDocumentId(document.id);
      dispatch(updateDocument(doc));
    }
  };

  function getMobileContent() {
    let index = stepStatuses.findIndex((item) => item !== StepStatus.COMPLETED);
    return index;
  }

  const getIsOpen = (index?: number) => {
    if (
      document.submittalPackageDocumentId ||
      document.isHidden ||
      document.actionTaken === ActionTakenType.Withdrawn
    ) {
      return false;
    }

    if (index === undefined) {
      return getMobileContent() === activeStep;
    }

    return index === activeStep;
  };

  return (
    <Card className={classes.root}>
      <div className={classes.backgroundOverlay} />
      {isOver760px ? (
        <Grid container alignItems="stretch" wrap="nowrap" style={{ minHeight: 284, padding: 16 }}>
          <ThemeProvider theme={theme}>
            <Hidden mdDown>
              <Grid
                item
                style={{
                  maxWidth: 500,
                  paddingRight: 48,
                  paddingTop: 16,
                  paddingLeft: 16,
                  paddingBottom: 16,
                }}
              >
                <Typography
                  variant="h2"
                  style={{
                    color: 'white',
                    fontSize: 40,
                    fontWeight: 900,
                    paddingBottom: 8,
                    paddingTop: 16,
                  }}
                >
                  Submittal Action Center
                </Typography>
                {getIsComplete() ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 60,
                        height: 60,
                        backgroundColor: '#ff9c17',
                        borderRadius: '50%',
                        marginRight: 15,
                      }}
                    >
                      {document.actionTaken === ActionTakenType.Withdrawn ? (
                        <RemoveCircleOutlined style={{ color: 'white', fontSize: 60 }} />
                      ) : (
                        <CheckCircleOutlineIcon style={{ color: 'white', fontSize: 60 }} />
                      )}
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        style={{
                          color: 'white',
                          fontSize: 21,
                          fontWeight: 'bold',
                          textTransform: 'none',
                          lineHeight: '24px',
                          maxWidth: 410,
                        }}
                      >
                        {document.actionTaken === ActionTakenType.Withdrawn
                          ? 'WITHDRAWN'
                          : 'COMPLETED'}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color: 'white',
                          fontSize: 18,
                          textTransform: 'none',
                          lineHeight: '24px',
                          maxWidth: 410,
                        }}
                      >
                        {formatDate(
                          document.actualSubmissionDate || document.dateReturnedToContractor,
                          true,
                        )}
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <>
                    <Typography
                      variant="body2"
                      style={{
                        color: 'white',
                        fontSize: 19,
                        textTransform: 'none',
                        lineHeight: '24px',
                        maxWidth: 410,
                      }}
                    >
                      Complete all your uploads and actions here. Track each step of the process and
                      keep an eye on progress.
                    </Typography>
                    {document.submittalPackageDocument && (
                      <Typography
                        variant="body2"
                        style={{
                          color: 'white',
                          fontSize: 19,
                          textTransform: 'none',
                          lineHeight: '24px',
                          maxWidth: 410,
                          marginTop: 24,
                        }}
                      >
                        This submittal is a part of{' '}
                        <span
                          onClick={() => history.push(document.submittalPackageDocument!.id)}
                          style={{ cursor: 'pointer', fontWeight: 600 }}
                        >
                          Submittal Package {document.submittalPackageDocument.referenceNumber}.
                        </span>{' '}
                        Please go to the package to review and comment.
                      </Typography>
                    )}
                  </>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: getIsComplete() ? 'space-between' : 'flex-end',
                    paddingTop: 16,
                  }}
                >
                  {hasRollbackPermission() && (
                    <TooltipIfDisabledComponent
                      title="This is disabled for projects synced with Procore"
                      disabled={isProcoreEnabled}
                    >
                      <Button
                        variant="contained"
                        startIcon={<RotateLeft />}
                        disabled={isProcoreEnabled}
                        onClick={handleRollback}
                        style={{ backgroundColor: '#D02C14', color: 'white' }}
                      >
                        Revise Submission
                      </Button>
                    </TooltipIfDisabledComponent>
                  )}
                  <ArrowForwardIcon style={{ color: 'white', width: 48, height: 48 }} />
                </div>
              </Grid>
            </Hidden>
          </ThemeProvider>

          <Grid
            item
            className={classes.accordionContainer}
            style={{ minHeight: '100%', padding: 16 }}
          >
            {titles!.map((title, index) => {
              return (
                <TaskStep
                  key={generateUniqueId()}
                  label={title}
                  status={stepStatuses[index]}
                  onClick={getOnClick(index)}
                  isOpen={getIsOpen(index)}
                  index={index}
                  defaultContractor={generalContractor || undefined}
                  defaultArchitect={architect || undefined}
                  handleClose={handleClose}
                  date={getDate(index) || 'N/A'}
                />
              );
            })}
            {/*{document.submittalPackageDocument && (*/}
            {/*  <Typography*/}
            {/*    variant="body2"*/}
            {/*    style={{*/}
            {/*      color: 'white',*/}
            {/*      fontSize: 19,*/}
            {/*      textTransform: 'none',*/}
            {/*      lineHeight: '24px',*/}
            {/*      maxWidth: 410,*/}
            {/*      marginTop: 24,*/}
            {/*      marginLeft: 32,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    This submittal is a part of{' '}*/}
            {/*    <span*/}
            {/*      onClick={() => history.push(document.submittalPackageDocument!.id)}*/}
            {/*      style={{ cursor: 'pointer', fontWeight: 600 }}*/}
            {/*    >*/}
            {/*      Submittal Package {document.submittalPackageDocument.referenceNumber}.*/}
            {/*    </span>{' '}*/}
            {/*    Please go to the package to review and comment.*/}
            {/*  </Typography>*/}
            {/*)}*/}
          </Grid>
          <Grid item>
            <ThemeProvider theme={theme}>
              <Hidden lgUp>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {document.submittalPackageDocument && (
                    <Typography
                      variant="body2"
                      style={{
                        color: 'white',
                        fontSize: 19,
                        textTransform: 'none',
                        lineHeight: '24px',
                        maxWidth: 410,
                        marginTop: 24,
                      }}
                    >
                      This submittal is a part of{' '}
                      <span
                        onClick={() => history.push(document.submittalPackageDocument!.id)}
                        style={{ cursor: 'pointer', fontWeight: 600 }}
                      >
                        Submittal Package {document.submittalPackageDocument.referenceNumber}.
                      </span>{' '}
                      Please go to the package to review and comment.
                    </Typography>
                  )}
                  {hasRollbackPermission() && (
                    <TooltipIfDisabledComponent
                      title="This is disabled for projects synced with Procore"
                      disabled={isProcoreEnabled}
                    >
                      <Button
                        variant="contained"
                        startIcon={<RotateLeft />}
                        disabled={isProcoreEnabled}
                        onClick={handleRollback}
                        style={{ backgroundColor: '#D02C14', color: 'white', marginTop: '50%' }}
                      >
                        Revise Submission
                      </Button>
                    </TooltipIfDisabledComponent>
                  )}
                </div>
              </Hidden>
            </ThemeProvider>
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="center" justify="center">
          {hasRollbackPermission() ? (
            <TooltipIfDisabledComponent
              title="This is disabled for projects synced with Procore"
              disabled={isProcoreEnabled}
            >
              <Button
                variant="contained"
                startIcon={<RotateLeft />}
                disabled={isProcoreEnabled}
                onClick={handleRollback}
                style={{
                  backgroundColor: '#D02C14',
                  color: 'white',
                  margin: '20px 0px',
                  width: '85%',
                }}
              >
                Revise Submission
              </Button>
            </TooltipIfDisabledComponent>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={getIsComplete()}
              style={{ width: '85%', margin: '20px 0px' }}
              onClick={() => setDialogOpen(true)}
            >
              Open Submittal Action Center
            </Button>
          )}
          <Dialog maxWidth="xs" fullWidth open={dialogOpen}>
            <MobileTaskStep
              key={generateUniqueId()}
              label="title"
              status={stepStatuses[getMobileContent()]}
              isOpen={getIsOpen()}
              index={getMobileContent()}
              defaultContractor={generalContractor || undefined}
              defaultArchitect={architect || undefined}
              handleClose={() => setDialogOpen(false)}
              date={getDate(getMobileContent()) || 'N/A'}
              currentIndex={getMobileContent()}
            />
          </Dialog>
        </Grid>
      )}
    </Card>
  );
}
