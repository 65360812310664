import { EmailLogType } from './CorrespondenceLog';
import {
  DocumentTemplateType,
  INotification,
  NotificationType,
} from '../../api-client/autogenerated';
import dayjs from 'dayjs';
import { getUserFriendlyDocumentTemplateNameSingular } from '../../scripts/utils';
import _, { Dictionary } from 'lodash';

export const getNoEmailText = (type: EmailLogType) =>
  `<div style="display: flex; width: 100%; height: 100%; background-color: #E5EEFE; "><p style="width: 100%; text-align: center; margin-top: 25%; font-size: 20px">${
    type === EmailLogType.CORRESPONDENCE_LOG ? 'Select' : 'Double-click'
  } an email to view it here.</p></div>`;

export const notificationToUserFriendlySubjectString = (
  notification: INotification | null | undefined,
  templateIdToName: Dictionary<string>,
) => {
  if (!notification) return null;
  const fnc = notificationFormatToUserFriendlyStringFunction[notification.format];
  return fnc(notification, templateIdToName);
};

const notificationFormatToUserFriendlyStringFunction: Record<
  NotificationType,
  (notification: INotification, templateIdToName: Dictionary<string>) => string
> = {
  [NotificationType.BidFileViewed]: () => 'Bid File Viewed',
  [NotificationType.BidFileDownloaded]: () => 'Bid File Downloaded',
  [NotificationType.DocumentAssociationsModified]: () => 'Document Notification List Modified',
  [NotificationType.DocumentCreated]: () => 'Document Created',
  [NotificationType.DocumentDeleted]: () => 'Document Deleted',
  [NotificationType.DocumentRestored]: () => 'Document Restored',
  [NotificationType.DocumentViewed]: () => 'Document Viewed',
  [NotificationType.DocumentSubcontractorChanged]: () => 'Document Subcontractor Changed',
  [NotificationType.DocumentSubcontractorUploaded]: () => 'Document Subcontractor Uploaded',
  [NotificationType.DocumentSubcontractorUploadAccepted]: () =>
    'Document Subcontractor Upload Accepted',
  [NotificationType.DocumentSubcontractorUploadRejected]: () =>
    'Document Subcontractor Upload Rejected',
  [NotificationType.DocumentSubmittedForReview]: () => 'Document Submitted for Review',
  [NotificationType.DocumentConsultantAdded]: () => 'Document Consultant Added',
  [NotificationType.DocumentConsultantBluebeamStarted]: () => 'Consultant Started Bluebeam Review',
  [NotificationType.DocumentConsultantDownloaded]: () => 'Consultant Downloaded File',
  [NotificationType.DocumentConsultantFinished]: () => 'Consultant Finished Review',
  [NotificationType.DocumentConsultantUploaded]: () => 'Consultant Uploaded File',
  [NotificationType.DocumentComplete]: () => 'Document Completed',
  [NotificationType.DocumentUndoCompleteReview]: () => 'Document Reopened for Revision',
  [NotificationType.DocumentFieldsUpdated]: () => 'Document Updated',
  [NotificationType.DocumentFollowersUpdated]: () => 'Document Notification List Updated',
  [NotificationType.DocumentFileCreated]: () => 'Document File Uploaded',
  [NotificationType.DocumentFileDeleted]: () => 'Document File Deleted',
  [NotificationType.DocumentFileViewed]: () => 'Document File Viewed',
  [NotificationType.DocumentFileDownloaded]: () => 'Document File Downloaded',
  [NotificationType.DocumentFileOpenedBluebeam]: () => 'Document File Opened in Bluebeam',
  [NotificationType.DocumentAddedInitialSubmittalDate]: () =>
    'Document Initial Submission Date Added', // not used
  [NotificationType.DocumentCommentAdded]: () => 'Document Comment Added',
  [NotificationType.DocumentDueDateChanged]: () => 'Document Due Date Changed',
  [NotificationType.DocumentAnticipatedSubmissionDateChanged]: () =>
    'Document Anticipated Submission Date Changed',
  [NotificationType.DocumentAddedToPackage]: () => 'Document Added to Package',
  [NotificationType.DocumentSeparatedFromPackage]: () => 'Document Separated from Package',
  [NotificationType.DocumentDocumentRemovedFromPackage]: () => 'Document Separated from Package',
  [NotificationType.DocumentPublished]: () => 'Document Published',
  [NotificationType.DocumentFileLocked]: () => 'New Addendum Uploaded',
  [NotificationType.DocumentDueDateWarning]: () => 'Document Due Date Warning',
  [NotificationType.DocumentSubmittedForReviewReminder]: () =>
    'Document Submitted for Review Reminder',
  [NotificationType.DocumentSubmittedForInformationOnly]: () =>
    'Document Submitted for Information Only',
  [NotificationType.DocumentReviewUpdated]: () => 'Task Updated',
  [NotificationType.DocumentUpdated]: () => 'Document Updated',
  [NotificationType.DocumentProcoreSyncMismatch]: () => 'Procore Sync Mismatch',
  [NotificationType.DocumentWithdrawn]: () => 'Document Withdrawn',
  [NotificationType.DocumentRespondedToReview]: () => 'Document Review Response',
  [NotificationType.AnnouncementLive]: () => 'New Announcement',
  [NotificationType.ProjectFileCreated]: () => 'Project File Uploaded',
  [NotificationType.ProjectUserInvited]: () => 'User Invited to Project',
  [NotificationType.ProjectUserInvitedExisting]: () => 'User Invited to Project',
  [NotificationType.ProjectUserUnsubscribed]: () => 'Planholder Unsubscribed',
  [NotificationType.ProjectComment]: () => 'Project Comment Added',
  [NotificationType.ProjectMassDocumentPublish]: (notification, templateIdToName) => {
    if (notification.documentTemplateId && !_.isEmpty(templateIdToName)) {
      const documentTemplateType = templateIdToName[
        notification.documentTemplateId
      ] as DocumentTemplateType;
      const userFriendlyNameSingular = getUserFriendlyDocumentTemplateNameSingular(
        documentTemplateType,
      );
      return `Mass ${userFriendlyNameSingular} Publication`;
    }
    return 'Mass Document Publication';
  },
  [NotificationType.ProjectAddendaDueDateWarning]: () => 'Addenda Due Date Warning',
  [NotificationType.ProjectBidDatePassed]: () => 'Project Bid Date Passed',
  [NotificationType.ProjectBidSetupFileCreated]: () => 'BidConnect File Uploaded',
  [NotificationType.ProjectBidSetupViewed]: () => 'BidConnect Viewed',
  [NotificationType.ProjectBidSetupDownloaded]: () => 'BidConnect File Downloaded',
  [NotificationType.ProjectBidRetracted]: () => 'Bid Retracted',
  [NotificationType.ProjectBidSubmitted]: () => 'Bid Submitted',
  [NotificationType.ProjectCompletedBidTabulationAdded]: () => 'Completed Bid Tabulation Uploaded',
  [NotificationType.ProjectSpecificationsParserCompleted]: () => 'Specifications Parser Completed',
  [NotificationType.ProjectSpecificationsParserFailed]: () => 'Specifications Parser Failed',
  [NotificationType.ProjectDrawingsParserCompleted]: () => 'Drawings Parser Completed',
  [NotificationType.ProjectDrawingsParserFailed]: () => 'Drawings Parser Failed',
  [NotificationType.UserInvited]: () => 'User Invited',
  [NotificationType.UserRegistrationReminder]: () => 'User Registration Reminder',
  [NotificationType.DesignFileAccess]: () => 'Design File Access',
};

export const formatEmailDate = (type: EmailLogType, date?: string | null) => {
  const today = dayjs();
  const emailDate = dayjs(date);
  const isSameDay = today.isSame(emailDate, 'day');
  if (isSameDay) {
    return `Today ${emailDate.format('h:mm A')}`;
  }

  if (today.diff(emailDate, 'day') > 6) {
    if (type === EmailLogType.MY_NOTIFICATIONS) {
      return emailDate.format('M/D/YYYY h:mm A');
    }

    return emailDate.format('ddd M/D');
  }

  return emailDate.format('ddd h:mm A');
};
